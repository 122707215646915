html {
  /*
  * This is very important: A value set here may mess up all your *-to-rem/*-to-em calculations.
  * This has to be reset to here, because materialize's default is line-height: 1.5.
  * Please, define line-height in body tag.
  */
  line-height: normal;
}

html,
body {
  // you may adjust the following to your needs, though.
  @media only screen and (min-width: 0) {
    font-size: $font-size-small;
  }
  @media only screen and (min-width: $screen-md-min) {
    font-size: $font-size-base;
  }
  @media only screen and (min-width: $screen-lg-min) {
    font-size: $font-size-large;
  }
  font-weight: $normal;
  line-height: rem(25);
}

strong,
b {
  font-weight: $bolder;
}

h1 {
  font-size: rem(30);
  line-height: rem(36);
  color: $blue_dark;
  @include vbf-margin-trailer(1.5);
  font-weight: $bold;
}

#{headings(1,6)} {
  margin-top: 0;
}

h3 {
  margin: 0;
  @include vbf-padding-trailer(0.75);
  @include vbf-margin-trailer(1);
  color: $blue-dark;
}

h5 {
  margin: 0;
  font-weight: $bold;
}
a {
  color: $blue-dark;
  outline-width: 0;
  -moz-outline-width: 0;
}

a:hover,
a:active,
a:focus {
  color: $blue;
  outline-width: 0;
  -moz-outline-width: 0;
}

@mixin placeholder-style {
  //styles go here to ensure same style across platforms
  color: #000 !important;
  opacity: 1 !important;
}

::-webkit-input-placeholder {
  @include placeholder-style;
}
:-moz-placeholder {
  opacity: 1;
  @include placeholder-style;
}

::-moz-placeholder {
  opacity: 1;
  @include placeholder-style;
}

:-ms-input-placeholder {
  @include placeholder-style;
}

.alert {
  margin-top: 20px;
}
.view-display-id-events {
  span,
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li {
    font-style: normal !important;
  }
  .submitted,
  .submitted span {
    font-weight: 300 !important;
  }
}
