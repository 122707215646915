.community_register_step1 {

}

.community_register_step1__form {
  background-color: $gray-lighter;
  padding: 40px;

  .field-name-field-profile-image,
  .help-block {
    display: none;
  }

  .form-actions {
    float: none;
  }

  .btn.form-submit {
    width: 100%;
    background-color: $blue-darker;

    &:hover {
      background-color: $blue-dark;
    }
  }
}

.community_register_step1__headline {
  h1[data-hasmobile] {
    @media (max-width: $screen-sm) {
      display: none;
    }
  }

  .mobileh1 {
    display: none;

    @media (max-width: $screen-sm) {
      display: block;
      font-size: 3rem;
      color: #275f91;
      margin-bottom: 3.75rem;
      font-weight: 300;
    }
  }
}

.community_register_step1__desc {
  padding: 40px;
}

.community_register_step1__thx__desc {

}

.community_register_step2__form {
  @extend .community_register_step1__form;

  div.horizontal-tabs ul.horizontal-tabs-list {
    display: none;
  }

  .has-feedback label ~ .form-control-feedback {
    display: none;
  }

  .form-item-pass-pass1 {
    margin-bottom: 0;
  }

  div.horizontal-tabs .horizontal-tabs-panes {
    background-color: transparent;
    border:none;
    padding: 0;
  }

  div.horizontal-tabs fieldset.horizontal-tabs-pane {
    padding: 0;
  }

  .form-item {

  }
}

.community_register_step2__desc {
  padding: 40px;
}

.community_register_step3 {
  margin-bottom: 0;

  @media (min-width: $screen-md) {
    margin-bottom: 80px;
  }
}

#block-vbf-community-register-vbf-register-step3-welcome {
  @media (min-width: $screen-md) {
    background-image: url('../images/Swirl_white25.svg');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

.community_register_step3__desc {
  float: left;
  width: calc(100% - 130px);

  @media (min-width: $screen-md) {
    width: 50%;
  }
}

.community_register_step3 + .community_register_step3 {
  margin-top: 25px;

  @media (min-width: $screen-md) {
    margin-top: 0;
    display: flex;
    align-items: center;
  }
}

.community_register_step3__image {
  width: calc(100px + 30px);
  height: 100px;
  float: left;

  @media (min-width: $screen-md) {
    display: block;
    width: 165px;
    height: 135px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.community_register_step1_iconbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  @media (min-width: $screen-md) {
    margin-left: 12.5%;
    margin-top: 85px;
  }

  span {
    width: 96px;
    height: 2px;
    background-color: $blue-dark;
  }

  img {
    max-width: 28%;

    @media (min-width: $screen-md) {
      max-width: 33%;
    }
  }
}

.community_register_step2_iconbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  @media (min-width: $screen-md) {
    margin-left: 25%;
    margin-top: 85px;
  }

  span {
    width: 96px;
    height: 2px;
    background-color: $blue-dark;
  }

  img {
    max-width: 28%;

    @media (min-width: $screen-md) {
      max-width: 33%;
    }
  }
}

body.page-user-register-thx {
  .messages.status {
    display: none;
  }
}

[data-target="#modalRegister"] {
  position: relative;
}

li[data-target="#modalNewsletter"] {
  > a {
    padding: 1.33333rem 0 0.5rem 15px;
    text-transform: uppercase;
  }
}

.modalRegisterComment2,
.modalRegisterComment,
.modalRegisterNew,
#page-header #modalRegister {
  position: absolute;
  left:0;
  right: 0;
  bottom: -2px;
  display: none;

  &.open {
    display: block;
  }

  ul {
    padding: 0 15px 12px 15px;
  }

  li {
    background-color: $blue;

    a {
      color: $white !important;

      body.blue &,
      body.violet & {
        color: $white !important;
      }
    }

    &:hover {
      a {
        text-decoration: underline;
      }
    }

    &.head {
      background-color: $blue-darker;

      span {
        padding: 15px 15px 15px 0;
        font-size: 1rem;
        line-height: 1.2;
        display: block;
        text-shadow: none;
      }
    }

    & +li {
      margin-top: 10px;
    }
  }
}

.modalRegisterComment2 {
  max-width: 300px;
  left:auto;
  right: 0;
}

.modalregister__container {
  position: relative;
}

.form-item-accept-netiquette {
  .form-required {
    display: none;
  }
}

#block-vbf-community-register-vbf-register-cancel {
  .form-actions {
    a {
      margin-top: 20px;
      display: block;
    }
  }
}

#block-vbf-community-register-vbf-register-login {
  .community_register_step1__form {
    margin-bottom: 70px;

    &:after {
      @media (min-width: $screen-md) {
        display: block;
        content: "";
        width: 500px;
        height:400px;
        position: absolute;
        right: -310px;
        top: 10px;

        background-image: url('../images/Swirl_white25.svg');
        background-position: right top;
        background-repeat: no-repeat;
        background-size: auto 100%;
      }
    }
  }
}

.comment_anonymous {
  margin-bottom: 26px;
}