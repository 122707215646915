// Theme Customizations to Views //

.view-vbf-og-recent-comments.view-display-id-default {
  @include vbf-margin-leader(1);
  @include vbf-margin-trailer(2);

  .views-row {
    @include vbf-margin-trailer(1.5);
    clear: both;
  }
  .views-field-realname,
  .comment-who-where span a:first-child {
    @include vbf-padding-leader(.5);
    @include make-bold;
    text-transform: uppercase;
  }
  .comment-who-where {
    line-height: 1.3em;
  }
  .views-field-created {
    font-size: rem(12);
    color: $gray-light;
  }
  .field-comment-body {
    clear: both;
    @include vbf-padding-leader(.5);
    padding-left: rem($grid-gutter-width/2);
  }
}

// ----------------------------------------------------
// Communities Overview
// ----------------------------------------------------
.view-communities {
  @include vbf-margin-trailer(3);
  .view-content {
    @include flex-grid-simple-container(15);
  }
  .views-row {
    @include vbf-margin-trailer(1);
    @include flex-grid-simple-col(15);
  }


  .views-row.hidden-on-frontpage {
    display: none;
  }
}


// ----------------------------------------------------
// Page References
// ----------------------------------------------------
.view-vbf-og-page-references {
  .views-row {
    width: 100%;
  }
}

// ----------------------------------------------------
// Members Overview
// ----------------------------------------------------
.view-vbf-og-group-members  {
  .views-exposed-form .views-exposed-widgets{
    display: flex;
    align-items: flex-end;
  }
  .views-exposed-widget {
    padding: 0;
    margin-right: $grid-gutter-width /2;
    .form-submit {
      margin: 0;
    }
  }
}

