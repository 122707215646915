#block-vbf-community-vbf-community-stats {
  .block-title {
    text-align: center;
    font-size: rem(45px);
    color: $blue;
    font-weight: $normal;
    @include vbf-margin-trailer(2);
  }
  p.intro {
    font-size: rem(30px);
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    line-height: 1.25;
    @include vbf-margin-trailer(3);
  }
  .community-stats {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    @include vbf-margin-trailer(5);

    li {
      display: flex;
      flex-flow: column;
      align-content: center;
      justify-content: center;
      background: $blue_dark;
      text-align: center;
      align-items: center;
      color: $white;
      font-size: rem(20);
      border-radius: 100%;
      line-height: 1;
      width: 180px;
      height: 180px;
      margin: 0 auto;
      @include vbf-margin-trailer(1);
      &:before {
        margin-bottom: 1rem;
        width: 40%;
      }
    }
    .community-no{
      &:before {
        content: url('../images/001-social-care.svg') ;
      }
    }

    .member-no{
      &:before {
        content: url('../images/002-team.svg') ;

      }

    }
    .post-no{
      &:before {
        content: url('../images/003-advertising.svg') ;

      }
    }
  }
  @media (min-width: $screen-md) {
    .community-stats {
      li{
        margin-bottom: 0;
        width: 262px;
        height: 262px;
      }
    }
  }
}
