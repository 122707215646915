@mixin make-image-round {
   @include box-shadow(0 -4px 4px 1px rgba(0,0,0,.1));
  border-radius: 50%;
  border: 4px solid white;
  width: rem(180);
  max-width: 75%;
}
////////////////////////
// Text: Wrapping
////////////////////////

@mixin wordwrap() {
  overflow-wrap: break-word;
  /*-ms-word-break: break-all;
  -ms-word-wrap: break-all;
  -webkit-word-break: break-word;
  -webkit-word-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;*/
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
@mixin un-wordwrap() {
  -ms-word-break: normal;
  -ms-word-wrap: normal;
  -webkit-word-break: normal;
  -webkit-word-wrap: normal;
  word-break: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
}


@mixin hide-visually($toggle: "hide") {
  @if not index("hide" "unhide", $toggle) {
    @error "`#{$toggle}` is not a valid value for the `$toggle` argument in " +
      "the `hide-visually` mixin. Must be either `hide` or `unhide`.";
  } @else if $toggle == "hide" {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  } @else if $toggle == "unhide" {
    clip: auto;
    clip-path: none;
    height: auto;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
  }
}

//
// Cross Browser Select Styles
// --------------------------------------------------
@mixin selectStyle {
  display: block;
  line-height: 1.3;
  padding: .6em 1.4em .5em .6em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('../images/icon-chevron-down.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 0.1em top 60%, 0 0;
  background-size: 1.4em auto, 100%;

  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: #888;
  }
  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }
  option {
    font-weight:normal;
  }
}

@mixin expanded() {
	height: auto;
	overflow: visible;
	opacity: 1;
	transition: all .3s;
}
@mixin closed() {
	height: 0;
	overflow: hidden;
	opacity: 0;
	transition: all .3s;
}
