#sidebar-wrapper {
  #sidebar-content {
    .block-vbf-community-vbf-community-user.login-block {
      .dropdown-menu {
        a {
          padding: 1.5rem 15px;
          position: relative;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
}