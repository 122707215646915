#footer {
  @include vbf-padding-leader(1.5);
  @include vbf-padding-trailer(1.5);
  background: $footer-bg-color;
  .footer-container {
    display: flex;
    flex-flow: row wrap;
  }
  .region-footer {
    display: flex;
    flex-flow: column;
    flex: 0 0 66%;
  }
  .footer-logo {
    display: block;
    height: 37px;
    width: 267px;
    background: url('../images/vbf-footer-logo@2x.png');
    background-repeat: no-repeat;
    color: transparent;
    background-size: contain;
    @include vbf-margin-leader(2);
    margin-left: auto;
  }
  @media (max-width: $screen-sm) {
    .footer-logo {
      margin-left: 0;
    }
  }
  @media (min-width: $screen-sm-min) {
    .footer-logo {
      @include vbf-margin-leader(0);
      height: 35px;
      width: 260px;
    }
  }
}

#block-vbf-community-vbf-community-footer-menu {
  @include vbf-margin-trailer(1);
}

#block-vbf-community-vbf-community-footer-text {
  .footer-signature-r1,
  .footer-signature-r2 {
    color: #696969;
    @media (max-width: $screen-xs) {
      font-weight: 600;
    }
  }
  .footer-signature-r1 {
    text-transform: uppercase;
  }
}
