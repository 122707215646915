// ----------------------------------------------------
// VBF: Search Blocks
// ----------------------------------------------------
#block-vbf-search-vbf-search-overview-block {
  @include vbf-margin-trailer(1);
  .panorama-title-prefix,
  .panorama-title-suffix {
    clear: both;
    color: $gray-light;
  }
  h1 {
    color: $blue_dark;
    margin: 0;
  }
}

#block-vbf-search-vbf-search-filters-with-counts {
  @include vbf-margin-trailer(2);
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    position: relative;
    background: white;
    border-top: 1px solid $gray-lighter;
    @include vbf-rhythm-padding(0.5);
    &:first-child {
      border-top: 0;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        text-decoration: none;
      }
    }
    .search-count {
      background: $white;
      font-size: rem(14);
      border-radius: 100%;
      height: 25px;
      width: 25px;
      color: $blue-dark;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover,
    &.active {
      a {
        color: $blue-darker;
      }
      .search-count {
        background: $blue-dark;
        color: $white;
      }
    }
  }
}

#block-views-exp-main-search-page {
  @include vbf-margin-trailer(2);
  .form-item-search-api-multi-fulltext {
    > label {
      display: none;
    }
  }
  .views-exposed-widgets {
    margin-bottom: 0;
  }

  .views-submit-button {
    .form-item {
      margin-bottom: 0;
    }
  }

  .views-exposed-form .views-exposed-widget {
    float: none;
    padding: 0;
    display: flex;
    .form-item.form-item-query.form-type-textfield {
      float: left;
    }
    label {
      display: none;
    }
  }
  input[type="text"],
  input[type="search"] {
    color: $gray-dark;
    width: 100%;
    float: left;
    padding: 0.5rem;
    padding-left: 15px;
    padding-right: 15px;
  }
  button {
    background: $blue-dark;
    font-size: 0;
    @include glyphicon-after($glyphicon-search, $white, 15px);
    &:after {
      margin-left: -4px;
    }
    &:hover {
      background: $blue-darker;
      color: white;
    }
  }
}
// ----------------------------------------------------
// search page
// ----------------------------------------------------
.view-main-search {
  .views-row {
    @include vbf-padding-trailer(1);
    @include vbf-margin-trailer(2);
    border-bottom: 1px solid $gray-light;
    overflow: hidden;
    h3 {
      border: 0;
      padding: 0;
      font-size: rem(30);
      font-weight: $normal;
      @include vbf-margin-trailer(1);
      @include vbf-margin-leader(0.25);
    }
    .type {
      color: $blue-dark;
      text-transform: uppercase;
    }
    h3.event {
      margin-bottom: 0;
    }
    .event-date {
      @include vbf-margin-trailer(1);
      display: block;
    }
    .image-wrapper {
      @include vbf-padding-leader(1);
      @media (min-width: $screen-md) {
        position: relative;
        height: 8rem;
        float: left;
        width: auto;
        max-width: 25%;
        padding: 0;
        margin-right: $grid-gutter-width;
        overflow: hidden;
      }
    }
  }
}

#block-vbf-community-vbf-community-search {
  @media (min-width: $screen-sm) {
    position: absolute;
    z-index: 200;
    max-height: 0;
    overflow: hidden;
    right: $grid-gutter-width / 2;
    top: 55px;
    &.open {
      max-height: 200px;
      overflow: visible;
    }
  }

  form {
    padding: rem(15);
    @media (min-width: $screen-sm) {
      background: $blue-darker;
    }
    box-shadow: 0 0px 4px -2px rgba($black, 0.5);
    > div {
      display: flex;
    }
  }
  .control-label {
    display: none;
  }
  .form-item {
    margin: 0 !important;
  }
  .btn {
    background: $blue-darker;
    font-size: 0;
    @include glyphicon-after($glyphicon-search, $white, 15px);
    transition: all 0.2s ease-out;
    &:after {
      margin-left: -4px;
    }
    &:hover {
    }
  }
}
#block-vbf-community-vbf-community-by-menu {
  @media (min-width: $screen-lg-desktop) {
    margin-right: 80px;
  }
}

#block-vbf-community-vbf-community-by-menu a[href*="search"] {
  @media (max-width: $screen-sm) {
    display: none;
  }
}

#views-exposed-form-main-search-page {
  .form-item {
    margin: 0 !important;
  }
}
