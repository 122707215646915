// ----------------------------------------------------
// Community Header
// ----------------------------------------------------
#block-vbf-og-vbf-og-community-header {
  position: relative;
  color: $white;

  @media (max-width: $screen-sm) {
    img.img-responsive{
      //height: rem(220);
      //object-fit: cover;
    }
  }

  h1 {
    margin: 0;
    font-weight: $normal;
    color: $white;
    text-shadow: 1px 1px 3px rgba(0,0,0,.5);
    font-size: rem(30);
    line-height: rem(36);
    @media (max-width: $screen-sm) {
      font-size: 7.3vw;
    }
  }
  .community-info-wrapper {
    position: absolute;
    bottom: rem(20);
    padding: 0 rem(20);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    span:not(.group) {
      font-size: rem(18);
      text-shadow: 1px 1px 3px rgba(0,0,0,.5);
    }

    span.no {
      font-size: rem(20);
    }
    @media (max-width: $screen-sm) {
      bottom: auto;
      top: 1em;
      flex-flow: column;
      align-items: flex-start;
      justify-content: space-around;
      height: calc(100% - 1em);

      .group {
        margin-top: 1rem;

        &.subscribe {

        }

        &.unsubscribe {

        }
      }
    }
  }
}
