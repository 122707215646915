////////////////////////
// Mixins Partial
//
// All of your global mixins should go in this file.
////////////////////////

// ----------------------
// Mixins for Glyph Icons
// ----------------------

@mixin glyphicon-base($icon, $color: inherit, $size: $font-size-base){
  &:before,
  &:after {
    color: $color;
    font-size: $size;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Glyphicons Halflings';
  }
}

// glyphicon - inline/before
@mixin glyphicon($icon, $color: inherit, $size: $font-size-base) {
  @include glyphicon-base($icon, $color, $size);

  &:before {
    content: $icon + "\2005";
  }
}

// glyphicon - for use other than inline
@mixin glyphicon-standalone($icon, $color: inherit, $size: $font-size-base) {
  @include glyphicon-base($icon, $color, $size);

  &:before {
    content: $icon;
  }
}

// glyphicon - inline/before
@mixin glyphicon-before ($icon, $color: inherit, $size: $font-size-base) {
  @include glyphicon($icon, $color, $size);
}

// glyphicon - for use other than inline / using before
@mixin glyphicon-before-standalone ($icon, $color: inherit, $size: $font-size-base) {
  @include glyphicon-standalone($icon, $color, $size);
}

// glyphicon - inline/after
@mixin glyphicon-after ($icon, $color: inherit, $size: $font-size-base) {
  @include glyphicon-base($icon, $color, $size);

  &:after {
    content: "\2005" + $icon;
  }
}

// glyphicon - for use other than inline / using before
@mixin glyphicon-after-standalone ($icon, $color: inherit, $size: $font-size-base) {
  @include glyphicon-base($icon, $color, $size);

  &:after {
    content: $icon;
  }
}

// position glyphicon absolute-left-centered
@mixin before-absolute-left-centered ($spacing: 0) {
  position: relative;
  &:before {
    position: absolute;
    left: $spacing;
    top: 50%;
    @include translate(0, -50%);
  }
}

// position glyphicon absolute-right-centered
@mixin after-absolute-right-centered ($spacing: 0) {
  position: relative;
  &:after {
    position: absolute;
    right: $spacing;
    top: 50%;
    @include translate(0, -50%);
  }
}

// --------------------------
// END Mixins for Glyph Icons
// --------------------------

// --------------------------
// Mixins for typography
// --------------------------

@mixin make-bold () {
  font-weight: 500;
}

// --------------------------
// END Mixins for typography
// --------------------------

// ----------------------
// Mixins for spacing
// ----------------------

@mixin padding-gutter ($times:0.5) {
  padding-left: $grid-gutter-width * $times;
  padding-right: $grid-gutter-width * $times;
}

@mixin padding-gutter-vertical ($times:0.5) {
  @include padding-gutter($times);
}

@mixin padding-gutter-horizontal ($times:0.5) {
  padding-top: $grid-gutter-width * $times;
  padding-bottom: $grid-gutter-width * $times;
}

@mixin margins-gutter ($times:0.5) {
  margin-left: $grid-gutter-width * $times;
  margin-right: $grid-gutter-width * $times;
}

@mixin vbf-rhythm-padding($lines:1) {
  padding-top: $lines * 1.5rem;
  padding-bottom: $lines * 1.5rem;
}

@mixin vbf-rhythm-margins($lines:1) {
  margin-top: $lines * 1.5rem;
  margin-bottom: $lines * 1.5rem;
}

@mixin vbf-margin-leader($lines:1) {
  margin-top: $lines * 1.5rem;
}

@mixin vbf-margin-trailer($lines:1) {
  margin-bottom: $lines * 1.5rem;
}

@mixin vbf-padding-leader($lines:1) {
  padding-top: $lines * 1.5rem;
}

@mixin vbf-padding-trailer($lines:1) {
  padding-bottom: $lines * 1.5rem;
}

// ----------------------
// END Mixins for spacing
// ----------------------


// -------------------------------
// Additional Mixins for grid
// -------------------------------

@mixin pull-left {
  float: left !important;
}
@mixin pull-right {
  float: right !important;
}
@mixin pull-xs-left {
  @media (min-width: 0) {
    @include pull-left;
  }
}
@mixin pull-xs-right {
  @media (min-width: 0) {
    @include pull-right;
  }
}
@mixin pull-sm-left {
  @media (min-width: $screen-sm) {
    @include pull-left;
  }
}
@mixin pull-sm-right {
  @media (min-width: $screen-sm) {
    @include pull-right;
  }
}
@mixin pull-md-left {
  @media (min-width: $screen-md) {
    @include pull-left;
  }
}
@mixin pull-md-right {
  @media (min-width: $screen-md) {
    @include pull-right;
  }
}
@mixin pull-lg-left {
  @media (min-width: $screen-lg) {
    @include pull-left;
  }
}
@mixin pull-lg-right {
  @media (min-width: $screen-lg) {
    @include pull-right;
  }
}

@mixin pull-xl-left {
  @media (min-width: $screen-xl) {
    @include pull-left;
  }
}
@mixin pull-xl-right {
  @media (min-width: $screen-xl) {
    @include pull-right;
  }
}

// -------------------------------
// End Additional Mixins for grid
// -------------------------------

// ----------------------
// Mixins for aligns
// ----------------------

@mixin vertical-align {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

// ----------------------
// END Mixins for aligns
// ----------------------

// ----------------------
// Mixins for sprites
// ----------------------

@mixin sprite-base {
  text-indent: -999em;
  text-transform: capitalize;
  display: block;
  background-color: transparent;
  background-repeat: no-repeat;
}

@mixin sprite($url) {
  @include sprite-base;
  background-image: url($url);
}
@mixin border-radius($val) {
  @include border-top-radius($val);
  @include border-bottom-radius($val);
  @include border-left-radius($val);
  @include border-right-radius($val);
}

// ----------------------
// END Mixins for sprites
// ----------------------

// ----------------------
// Mixins: utilities
// ----------------------

@mixin px-to-rem($property, $to-size) {
  #{$property}: $to-size;
  #{$property}: rem($to-size);
}

// ----------------------
// END Mixins: utilities
// ----------------------

// ----------------------
// Mixins for prefixing
// ----------------------

@mixin placeholder-color ($color) {
  &::-webkit-input-placeholder {
     color: $color;
  }
  &:-moz-placeholder {
     color: $color;
  }
  &::-moz-placeholder {
     color: $color;
  }
  &:-ms-input-placeholder {
     color: $color;
  }
}

// ----------------------
// Mixins for throbber
// ----------------------

@mixin animated-throbber($speed:1s) {
  -webkit-animation: animatedThrobber $speed infinite linear;
  -moz-animation: animatedThrobber $speed infinite linear;
  -o-animation: animatedThrobber $speed infinite linear;
  animation: animatedThrobber $speed infinite linear;
}

@-webkit-keyframes animatedThrobber {
  from { -webkit-transform: rotate(0deg) }
  to { -webkit-transform: rotate(360deg) }
}
@-moz-keyframes animatedThrobber {
  from { -moz-transform: rotate(0deg) }
  to { -moz-transform: rotate(360deg) }
}
@-o-keyframes animatedThrobber {
  from { -o-transform: rotate(0deg) }
  to { -o-transform: rotate(360deg) }
}
@keyframes animatedThrobber {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}
// -----------------------
// END Mixins for throbber
// -----------------------

// ----------------------
// Flexbox
// ----------------------
  @mixin flexbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }

  @mixin justify-content($value: flex-start) {
    @if $value == flex-start {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
    } @else if $value == flex-end {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
    } @else if $value == space-between {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
    } @else if $value == space-around {
      -ms-flex-pack: distribute;
    } @else {
      -webkit-box-pack: $value;
      -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    -moz-justify-content: $value;
    justify-content: $value;
  }

// ----------------------
// End Flexbox
// ----------------------

// ----------------------
// Box Sizing
// ----------------------
@mixin box-sizing($value) {
  -moz-box-sizing: $value;
  -webkit-box-sizing: $value;
  box-sizing: $value;
}
// ----------------------
// END Box Sizing
// ----------------------
// ------------------------
// END Mixins for prefixing
// ------------------------
@mixin flex-grid-simple-container($margin) {
  $marginpx: $margin * -1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 $marginpx;
}
@mixin flex-grid-simple-col($margin) {
  $marginpx: $margin * 1px;
  $doublemargin: $margin * 2px;
  margin-right: $marginpx;
  margin-left: $marginpx;
  width: calc( 100% - 30px );
  @media (min-width: $screen-xs) {
    width: calc( 50% - #{$doublemargin} );
  }
  @media (min-width: $screen-sm) {
    width: calc( 33.3333% - #{$doublemargin} );
  }
  @media (min-width: $screen-md) {
    width: calc( 25% - #{$doublemargin} );
  }
}
