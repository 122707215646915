.field-profile-image,
.field-sc-profile-image {
  img {
    border-radius: 50%;
  }
}

#block-vbf-community-vbf-community-user-header {
  position: relative;
  padding-top: rem(60);

  @media (max-width: $screen-sm) {
    display: flex;
  }

  @media (max-width: $screen-xs-min - 1) {
    padding: rem(4) 0px rem(8) 0px;
  }
  .user-info {
    display: flex;
    flex-flow: column;
    @include vbf-margin-trailer(1);

    @media (max-width: $screen-sm) {
      margin-right: 12px;
    }
  }

  h1 {
    color: $blue-dark;
    font-weight: 500;
    @include vbf-margin-trailer(0);

    @media only screen and (min-width: $screen-md-min) {
      @include vbf-margin-trailer(0.5);
    }
  }

  .avatar {
    img {
      @include box-shadow(0 -4px 4px 1px rgba(0, 0, 0, 0.1));
      border-radius: 50%;
      border: 4px solid white;
      width: rem(120);
      @media (max-width: $screen-sm) {
        border-width: 2px;
        margin-left: auto;
      }
    }

    @media (min-width: $screen-sm) {
      position: absolute;
      bottom: -1rem;
      right: 0;
      height: 100%;
      img {
        width: rem(180);
      }
    }

    @media (max-width: $screen-sm) {
    }
  }
}

.page-user,
.page-user-me {
  .main-container-wrapper {
    background: $gray-lighter;
  }
  .main-container {
    @include vbf-padding-leader(1.5);
    @include vbf-padding-trailer(2.5);
    @media (min-width: $screen-sm) {
      @include vbf-padding-leader(4.5);
      @include vbf-padding-trailer(4.5);
    }
    > .row > section {
      @include pull-md-right;
    }
  }
  .profile {
    h2 {
      color: $blue-dark;
      @include vbf-margin-trailer(1);
    }
    dl {
      margin-bottom: 0;
    }
  }
}

.page-user.not-logged-in {
  .tabs--primary {
    margin-bottom: 0;
    li a {
      border-color: transparent;
      padding-left: $grid-gutter-width / 2;
      padding-right: $grid-gutter-width / 2;
      @include vbf-rhythm-padding(0.25);
      &.active {
        background: $gray-lighter;
        border-color: $gray-lighter;
      }
    }
  }
  .main-container form {
    background: $gray-lighter;
    .form-fields-wrapper {
      background: transparent;
      padding: 0;
    }
    > div {
      padding: $grid-gutter-width;
      .form-item-pass {
        margin-bottom: 0;
      }
      .form-actions {
        @include vbf-margin-leader(2);
      }
    }
  }
}

#block-vbf-og-vbf-og-user-memberships {
  @media (min-width: $screen-md) {
    margin-bottom: 80px;
  }

  .block-title {
    font-weight: 400;

    &:after {
      display: block;
      content: "";
      border-bottom: 1px solid $gray-light;
      margin-top: 14px;
      margin-bottom: 10px;

      @media (min-width: $screen-md) {
        margin-top: 34px;
        margin-bottom: 5px;
      }
    }
  }
}

.region.region-sidebar-second {
  > #block-vbf-og-vbf-og-user-memberships {
    &:first-child {
      h2 {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}

.page-user .region-content-wrapper {
  @include vbf-margin-trailer(1);
}

.page-user-me {
  .region-content-wrapper {
    @media (max-width: $screen-sm) {
      max-width: 100%;
    }
  }

  .main-container {
    padding-top: 2rem;
  }
}

#header + .main-container-wrapper {
  padding-top: 24px;
  margin-top: -5rem;
  @media (min-width: 992px) {
    margin-top: 0;
    padding-top: 63px;
  }
}

#header + .main-container-wrapper {
  > .main-container {
    margin-top: -1.2rem;
  }
}

#profile2 {
  .profile {
    img {
      display: none;
    }
  }
}

.block-system > .profile #profile2 {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  max-width: 890px;

  .profile {
    float: left;

    img {
      border-radius: 50%;
      width: 140px;
      height: auto;
      max-width: none;
      display: block;
    }
  }

  .content {
    margin-left: rem(90 + $grid-gutter-width/2);
  }
}

body.page-welcome {
  #block-blockify-blockify-page-title {
    display: none;
  }
}
