#footer-prefix {
	background: $blue_dark;
	color: $white;

	.region-footer-prefix {
		position: relative;
		overflow: hidden;

		&:after {
			content: "";
			display: block;
			position: absolute;
			background-image: url('../images/Swirl_white25.svg');
			background-position: 128% 10%;
			background-repeat: no-repeat;
			background-size: auto 250%;
			opacity: 0.1;
			width: 100%;
			height: 100%;
			z-index: 1;
			top: 0;
			left: 0;
		}
	}

	#block-vbf-community-vbf-contact-block {
		display: grid;
		grid-template-rows: 15%;
		padding-top:4%;
		padding-bottom:4%;
		position: relative;
		z-index: 2;

		.block-title{
			grid-row:1;
		}
		.contact-form-intro{
			grid-row:2;
			padding-right: 13px;
		}
		#contact-site-form{
			grid-row:1/4;
			padding-top:20px;
			display: grid;

			> div  {
				display: grid;
				.form-item-mail{
					grid-row:1;
					grid-column:2;
					margin-left: 5%;
				}
				.form-item-feed-me {
					display: none;
				}
				.form-item.form-item-message{
					grid-row:3;
					grid-column:1/3;
				}

			 .form-actions {
					grid-row:5;
					grid-column:1/3;
					background:#062352;
					text-align: center;
					.form-submit{
						width: 100%;
						background: $blue_darker;
						cursor: pointer !important;
						transition: all 0.5s ease;
						&:hover {
							background: lighten($blue_darker, 5%);
							box-shadow: 0px 0px 4px -2px rgba($blue_darker, 0.5);
						}
					}
				}
				.form-item-data-protection.form-type-checkbox.checkbox{
					grid-row:4;
					grid-column:1/3;

					label {
						color: $white;
						font-weight: $normal;
						a {
							color: $white;
							text-decoration: underline;
						}
					}
				}
				.form-item.form-item-subject.form-type-textfield.form-group{
					grid-row:2;
					grid-column:1/3;
				}
			}
		}
	}
}

@media (max-width: 667px) {
	#contact-site-form{
		grid-row:3 !important;
	}
	#footer-prefix{
		margin-top: 25px;
	}

}