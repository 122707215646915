#backToTopBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 100%; /* Rounded corners */
    font-size: 0;
    width: 70px;
    height: 70px;
    line-height: 1;
    transition: all 0.2s ease;
    background: url(../images/ic_arrow_up_white_48px.svg) no-repeat center #296192;
    background-size: 40px auto;
}

#backToTopBtn:hover {
    background-color: #4182be;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.75);
}
