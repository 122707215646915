@charset "UTF-8";
/**
 * Variable Overrides.
 *
 * Modify this file to override the Bootstrap Framework variables. You can copy
 * these variables directly from ../bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss to this file.
 */
@font-face {
  font-family: "Glyphicons Halflings";
  src: url("../fonts/bootstrap/glyphicons-halflings-regular.eot");
  src: url("../fonts/bootstrap/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/bootstrap/glyphicons-halflings-regular.woff2") format("woff2"), url("../fonts/bootstrap/glyphicons-halflings-regular.woff") format("woff"), url("../fonts/bootstrap/glyphicons-halflings-regular.ttf") format("truetype"), url("../fonts/bootstrap/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-asterisk:before {
  content: "\002a";
}

.glyphicon-plus:before {
  content: "\002b";
}

.glyphicon-euro:before,
.glyphicon-eur:before {
  content: "\20ac";
}

.glyphicon-minus:before {
  content: "\2212";
}

.glyphicon-cloud:before {
  content: "\2601";
}

.glyphicon-envelope:before {
  content: "\2709";
}

.glyphicon-pencil:before {
  content: "\270f";
}

.glyphicon-glass:before {
  content: "\e001";
}

.glyphicon-music:before {
  content: "\e002";
}

.glyphicon-search:before {
  content: "\e003";
}

.glyphicon-heart:before {
  content: "\e005";
}

.glyphicon-star:before {
  content: "\e006";
}

.glyphicon-star-empty:before {
  content: "\e007";
}

.glyphicon-user:before {
  content: "\e008";
}

.glyphicon-film:before {
  content: "\e009";
}

.glyphicon-th-large:before {
  content: "\e010";
}

.glyphicon-th:before {
  content: "\e011";
}

.glyphicon-th-list:before {
  content: "\e012";
}

.glyphicon-ok:before {
  content: "\e013";
}

.glyphicon-remove:before {
  content: "\e014";
}

.glyphicon-zoom-in:before {
  content: "\e015";
}

.glyphicon-zoom-out:before {
  content: "\e016";
}

.glyphicon-off:before {
  content: "\e017";
}

.glyphicon-signal:before {
  content: "\e018";
}

.glyphicon-cog:before {
  content: "\e019";
}

.glyphicon-trash:before {
  content: "\e020";
}

.glyphicon-home:before {
  content: "\e021";
}

.glyphicon-file:before {
  content: "\e022";
}

.glyphicon-time:before {
  content: "\e023";
}

.glyphicon-road:before {
  content: "\e024";
}

.glyphicon-download-alt:before {
  content: "\e025";
}

.glyphicon-download:before {
  content: "\e026";
}

.glyphicon-upload:before {
  content: "\e027";
}

.glyphicon-inbox:before {
  content: "\e028";
}

.glyphicon-play-circle:before {
  content: "\e029";
}

.glyphicon-repeat:before {
  content: "\e030";
}

.glyphicon-refresh:before {
  content: "\e031";
}

.glyphicon-list-alt:before {
  content: "\e032";
}

.glyphicon-lock:before {
  content: "\e033";
}

.glyphicon-flag:before {
  content: "\e034";
}

.glyphicon-headphones:before {
  content: "\e035";
}

.glyphicon-volume-off:before {
  content: "\e036";
}

.glyphicon-volume-down:before {
  content: "\e037";
}

.glyphicon-volume-up:before {
  content: "\e038";
}

.glyphicon-qrcode:before {
  content: "\e039";
}

.glyphicon-barcode:before {
  content: "\e040";
}

.glyphicon-tag:before {
  content: "\e041";
}

.glyphicon-tags:before {
  content: "\e042";
}

.glyphicon-book:before {
  content: "\e043";
}

.glyphicon-bookmark:before {
  content: "\e044";
}

.glyphicon-print:before {
  content: "\e045";
}

.glyphicon-camera:before {
  content: "\e046";
}

.glyphicon-font:before {
  content: "\e047";
}

.glyphicon-bold:before {
  content: "\e048";
}

.glyphicon-italic:before {
  content: "\e049";
}

.glyphicon-text-height:before {
  content: "\e050";
}

.glyphicon-text-width:before {
  content: "\e051";
}

.glyphicon-align-left:before {
  content: "\e052";
}

.glyphicon-align-center:before {
  content: "\e053";
}

.glyphicon-align-right:before {
  content: "\e054";
}

.glyphicon-align-justify:before {
  content: "\e055";
}

.glyphicon-list:before {
  content: "\e056";
}

.glyphicon-indent-left:before {
  content: "\e057";
}

.glyphicon-indent-right:before {
  content: "\e058";
}

.glyphicon-facetime-video:before {
  content: "\e059";
}

.glyphicon-picture:before {
  content: "\e060";
}

.glyphicon-map-marker:before {
  content: "\e062";
}

.glyphicon-adjust:before {
  content: "\e063";
}

.glyphicon-tint:before {
  content: "\e064";
}

.glyphicon-edit:before {
  content: "\e065";
}

.glyphicon-share:before {
  content: "\e066";
}

.glyphicon-check:before {
  content: "\e067";
}

.glyphicon-move:before {
  content: "\e068";
}

.glyphicon-step-backward:before {
  content: "\e069";
}

.glyphicon-fast-backward:before {
  content: "\e070";
}

.glyphicon-backward:before {
  content: "\e071";
}

.glyphicon-play:before {
  content: "\e072";
}

.glyphicon-pause:before {
  content: "\e073";
}

.glyphicon-stop:before {
  content: "\e074";
}

.glyphicon-forward:before {
  content: "\e075";
}

.glyphicon-fast-forward:before {
  content: "\e076";
}

.glyphicon-step-forward:before {
  content: "\e077";
}

.glyphicon-eject:before {
  content: "\e078";
}

.glyphicon-chevron-left:before {
  content: "\e079";
}

.glyphicon-chevron-right:before {
  content: "\e080";
}

.glyphicon-plus-sign:before {
  content: "\e081";
}

.glyphicon-minus-sign:before {
  content: "\e082";
}

.glyphicon-remove-sign:before {
  content: "\e083";
}

.glyphicon-ok-sign:before {
  content: "\e084";
}

.glyphicon-question-sign:before {
  content: "\e085";
}

.glyphicon-info-sign:before {
  content: "\e086";
}

.glyphicon-screenshot:before {
  content: "\e087";
}

.glyphicon-remove-circle:before {
  content: "\e088";
}

.glyphicon-ok-circle:before {
  content: "\e089";
}

.glyphicon-ban-circle:before {
  content: "\e090";
}

.glyphicon-arrow-left:before {
  content: "\e091";
}

.glyphicon-arrow-right:before {
  content: "\e092";
}

.glyphicon-arrow-up:before {
  content: "\e093";
}

.glyphicon-arrow-down:before {
  content: "\e094";
}

.glyphicon-share-alt:before {
  content: "\e095";
}

.glyphicon-resize-full:before {
  content: "\e096";
}

.glyphicon-resize-small:before {
  content: "\e097";
}

.glyphicon-exclamation-sign:before {
  content: "\e101";
}

.glyphicon-gift:before {
  content: "\e102";
}

.glyphicon-leaf:before {
  content: "\e103";
}

.glyphicon-fire:before {
  content: "\e104";
}

.glyphicon-eye-open:before {
  content: "\e105";
}

.glyphicon-eye-close:before {
  content: "\e106";
}

.glyphicon-warning-sign:before {
  content: "\e107";
}

.glyphicon-plane:before {
  content: "\e108";
}

.glyphicon-calendar:before {
  content: "\e109";
}

.glyphicon-random:before {
  content: "\e110";
}

.glyphicon-comment:before {
  content: "\e111";
}

.glyphicon-magnet:before {
  content: "\e112";
}

.glyphicon-chevron-up:before {
  content: "\e113";
}

.glyphicon-chevron-down:before {
  content: "\e114";
}

.glyphicon-retweet:before {
  content: "\e115";
}

.glyphicon-shopping-cart:before {
  content: "\e116";
}

.glyphicon-folder-close:before {
  content: "\e117";
}

.glyphicon-folder-open:before {
  content: "\e118";
}

.glyphicon-resize-vertical:before {
  content: "\e119";
}

.glyphicon-resize-horizontal:before {
  content: "\e120";
}

.glyphicon-hdd:before {
  content: "\e121";
}

.glyphicon-bullhorn:before {
  content: "\e122";
}

.glyphicon-bell:before {
  content: "\e123";
}

.glyphicon-certificate:before {
  content: "\e124";
}

.glyphicon-thumbs-up:before {
  content: "\e125";
}

.glyphicon-thumbs-down:before {
  content: "\e126";
}

.glyphicon-hand-right:before {
  content: "\e127";
}

.glyphicon-hand-left:before {
  content: "\e128";
}

.glyphicon-hand-up:before {
  content: "\e129";
}

.glyphicon-hand-down:before {
  content: "\e130";
}

.glyphicon-circle-arrow-right:before {
  content: "\e131";
}

.glyphicon-circle-arrow-left:before {
  content: "\e132";
}

.glyphicon-circle-arrow-up:before {
  content: "\e133";
}

.glyphicon-circle-arrow-down:before {
  content: "\e134";
}

.glyphicon-globe:before {
  content: "\e135";
}

.glyphicon-wrench:before {
  content: "\e136";
}

.glyphicon-tasks:before {
  content: "\e137";
}

.glyphicon-filter:before {
  content: "\e138";
}

.glyphicon-briefcase:before {
  content: "\e139";
}

.glyphicon-fullscreen:before {
  content: "\e140";
}

.glyphicon-dashboard:before {
  content: "\e141";
}

.glyphicon-paperclip:before {
  content: "\e142";
}

.glyphicon-heart-empty:before {
  content: "\e143";
}

.glyphicon-link:before {
  content: "\e144";
}

.glyphicon-phone:before {
  content: "\e145";
}

.glyphicon-pushpin:before {
  content: "\e146";
}

.glyphicon-usd:before {
  content: "\e148";
}

.glyphicon-gbp:before {
  content: "\e149";
}

.glyphicon-sort:before {
  content: "\e150";
}

.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}

.glyphicon-sort-by-order:before {
  content: "\e153";
}

.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}

.glyphicon-sort-by-attributes:before {
  content: "\e155";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}

.glyphicon-unchecked:before {
  content: "\e157";
}

.glyphicon-expand:before {
  content: "\e158";
}

.glyphicon-collapse-down:before {
  content: "\e159";
}

.glyphicon-collapse-up:before {
  content: "\e160";
}

.glyphicon-log-in:before {
  content: "\e161";
}

.glyphicon-flash:before {
  content: "\e162";
}

.glyphicon-log-out:before {
  content: "\e163";
}

.glyphicon-new-window:before {
  content: "\e164";
}

.glyphicon-record:before {
  content: "\e165";
}

.glyphicon-save:before {
  content: "\e166";
}

.glyphicon-open:before {
  content: "\e167";
}

.glyphicon-saved:before {
  content: "\e168";
}

.glyphicon-import:before {
  content: "\e169";
}

.glyphicon-export:before {
  content: "\e170";
}

.glyphicon-send:before {
  content: "\e171";
}

.glyphicon-floppy-disk:before {
  content: "\e172";
}

.glyphicon-floppy-saved:before {
  content: "\e173";
}

.glyphicon-floppy-remove:before {
  content: "\e174";
}

.glyphicon-floppy-save:before {
  content: "\e175";
}

.glyphicon-floppy-open:before {
  content: "\e176";
}

.glyphicon-credit-card:before {
  content: "\e177";
}

.glyphicon-transfer:before {
  content: "\e178";
}

.glyphicon-cutlery:before {
  content: "\e179";
}

.glyphicon-header:before {
  content: "\e180";
}

.glyphicon-compressed:before {
  content: "\e181";
}

.glyphicon-earphone:before {
  content: "\e182";
}

.glyphicon-phone-alt:before {
  content: "\e183";
}

.glyphicon-tower:before {
  content: "\e184";
}

.glyphicon-stats:before {
  content: "\e185";
}

.glyphicon-sd-video:before {
  content: "\e186";
}

.glyphicon-hd-video:before {
  content: "\e187";
}

.glyphicon-subtitles:before {
  content: "\e188";
}

.glyphicon-sound-stereo:before {
  content: "\e189";
}

.glyphicon-sound-dolby:before {
  content: "\e190";
}

.glyphicon-sound-5-1:before {
  content: "\e191";
}

.glyphicon-sound-6-1:before {
  content: "\e192";
}

.glyphicon-sound-7-1:before {
  content: "\e193";
}

.glyphicon-copyright-mark:before {
  content: "\e194";
}

.glyphicon-registration-mark:before {
  content: "\e195";
}

.glyphicon-cloud-download:before {
  content: "\e197";
}

.glyphicon-cloud-upload:before {
  content: "\e198";
}

.glyphicon-tree-conifer:before {
  content: "\e199";
}

.glyphicon-tree-deciduous:before {
  content: "\e200";
}

.glyphicon-cd:before {
  content: "\e201";
}

.glyphicon-save-file:before {
  content: "\e202";
}

.glyphicon-open-file:before {
  content: "\e203";
}

.glyphicon-level-up:before {
  content: "\e204";
}

.glyphicon-copy:before {
  content: "\e205";
}

.glyphicon-paste:before {
  content: "\e206";
}

.glyphicon-alert:before {
  content: "\e209";
}

.glyphicon-equalizer:before {
  content: "\e210";
}

.glyphicon-king:before {
  content: "\e211";
}

.glyphicon-queen:before {
  content: "\e212";
}

.glyphicon-pawn:before {
  content: "\e213";
}

.glyphicon-bishop:before {
  content: "\e214";
}

.glyphicon-knight:before {
  content: "\e215";
}

.glyphicon-baby-formula:before {
  content: "\e216";
}

.glyphicon-tent:before {
  content: "\26fa";
}

.glyphicon-blackboard:before {
  content: "\e218";
}

.glyphicon-bed:before {
  content: "\e219";
}

.glyphicon-apple:before {
  content: "\f8ff";
}

.glyphicon-erase:before {
  content: "\e221";
}

.glyphicon-hourglass:before {
  content: "\231b";
}

.glyphicon-lamp:before {
  content: "\e223";
}

.glyphicon-duplicate:before {
  content: "\e224";
}

.glyphicon-piggy-bank:before {
  content: "\e225";
}

.glyphicon-scissors:before {
  content: "\e226";
}

.glyphicon-bitcoin:before {
  content: "\e227";
}

.glyphicon-btc:before {
  content: "\e227";
}

.glyphicon-xbt:before {
  content: "\e227";
}

.glyphicon-yen:before {
  content: "\00a5";
}

.glyphicon-jpy:before {
  content: "\00a5";
}

.glyphicon-ruble:before {
  content: "\20bd";
}

.glyphicon-rub:before {
  content: "\20bd";
}

.glyphicon-scale:before {
  content: "\e230";
}

.glyphicon-ice-lolly:before {
  content: "\e231";
}

.glyphicon-ice-lolly-tasted:before {
  content: "\e232";
}

.glyphicon-education:before {
  content: "\e233";
}

.glyphicon-option-horizontal:before {
  content: "\e234";
}

.glyphicon-option-vertical:before {
  content: "\e235";
}

.glyphicon-menu-hamburger:before {
  content: "\e236";
}

.glyphicon-modal-window:before {
  content: "\e237";
}

.glyphicon-oil:before {
  content: "\e238";
}

.glyphicon-grain:before {
  content: "\e239";
}

.glyphicon-sunglasses:before {
  content: "\e240";
}

.glyphicon-text-size:before {
  content: "\e241";
}

.glyphicon-text-color:before {
  content: "\e242";
}

.glyphicon-text-background:before {
  content: "\e243";
}

.glyphicon-object-align-top:before {
  content: "\e244";
}

.glyphicon-object-align-bottom:before {
  content: "\e245";
}

.glyphicon-object-align-horizontal:before {
  content: "\e246";
}

.glyphicon-object-align-left:before {
  content: "\e247";
}

.glyphicon-object-align-vertical:before {
  content: "\e248";
}

.glyphicon-object-align-right:before {
  content: "\e249";
}

.glyphicon-triangle-right:before {
  content: "\e250";
}

.glyphicon-triangle-left:before {
  content: "\e251";
}

.glyphicon-triangle-bottom:before {
  content: "\e252";
}

.glyphicon-triangle-top:before {
  content: "\e253";
}

.glyphicon-console:before {
  content: "\e254";
}

.glyphicon-superscript:before {
  content: "\e255";
}

.glyphicon-subscript:before {
  content: "\e256";
}

.glyphicon-menu-left:before {
  content: "\e257";
}

.glyphicon-menu-right:before {
  content: "\e258";
}

.glyphicon-menu-down:before {
  content: "\e259";
}

.glyphicon-menu-up:before {
  content: "\e260";
}

/*
// Reset
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";

// Core CSS
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";

@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";

@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";
*/
@font-face {
  font-family: 'museo';
  src: url("../fonts/Museo-300/Museo-300.eot");
  src: url("../fonts/Museo-300/Museo-300.eot?#iefix") format("embedded-opentype"), url("../fonts/Museo-300/Museo-300.otf") format("opentype"), url("../fonts/Museo-300/Museo-300.woff") format("woff"), url("../fonts/Museo-300/Museo-300.ttf") format("truetype"), url("../fonts/Museo-300/Museo-300.svg#Museo-300") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'museo';
  src: url("../fonts/Museo-500/Museo-500.eot");
  src: url("../fonts/Museo-500/Museo-500.eot?#iefix") format("embedded-opentype"), url("../fonts/Museo-500/Museo-500.otf") format("opentype"), url("../fonts/Museo-500/Museo-500.woff") format("woff"), url("../fonts/Museo-500/Museo-500.ttf") format("truetype"), url("../fonts/Museo-500/Museo-500.svg#Museo-500") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'museo';
  src: url("../fonts/Museo-700/Museo-700.eot");
  src: url("../fonts/Museo-700/Museo-700.eot?#iefix") format("embedded-opentype"), url("../fonts/Museo-700/Museo-700.otf") format("opentype"), url("../fonts/Museo-700/Museo-700.woff") format("woff"), url("../fonts/Museo-700/Museo-700.ttf") format("truetype"), url("../fonts/Museo-700/Museo-700.svg#Museo-700") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'museo-sans';
  src: url("../fonts/MuseoSans-300/MuseoSans-300.eot");
  src: url("../fonts/MuseoSans-300/MuseoSans-300.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSans-300/MuseoSans-300.otf") format("opentype"), url("../fonts/MuseoSans-300/MuseoSans-300.woff") format("woff"), url("../fonts/MuseoSans-300/MuseoSans-300.ttf") format("truetype"), url("../fonts/MuseoSans-300/MuseoSans-300.svg#MuseoSans-300") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'museo-sans';
  src: url("../fonts/MuseoSans-500/museosans_500-webfont.eot");
  src: url("../fonts/MuseoSans-500/museosans_500-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSans-500/museosans_500-webfont.woff") format("woff"), url("../fonts/MuseoSans-500/museosans_500-webfont.ttf") format("truetype"), url("../fonts/MuseoSans-500/museosans_500-webfont.svg#museo_sans_500regular") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'museo-sans';
  src: url("../fonts/MuseoSans-700/MuseoSans-700.eot");
  src: url("../fonts/MuseoSans-700/MuseoSans-700.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSans-700/MuseoSans-700.otf") format("opentype"), url("../fonts/MuseoSans-700/MuseoSans-700.woff") format("woff"), url("../fonts/MuseoSans-700/MuseoSans-700.ttf") format("truetype"), url("../fonts/MuseoSans-700/MuseoSans-700.svg#MuseoSans-700") format("svg");
  font-weight: 700;
  font-style: normal;
}

@-webkit-keyframes animatedThrobber {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes animatedThrobber {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes animatedThrobber {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg);
  }
}

@keyframes animatedThrobber {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#comments .comment .new,
.page-comment .comment .new {
  display: inline-block;
  min-width: 22px;
  min-height: 22px;
  padding: 3px 4px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: 1.25;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #C3C3C3;
  border-radius: 10px;
}

#comments .comment .new:empty,
.page-comment .comment .new:empty {
  display: none;
}

.btn #comments .comment .new, #comments .comment .btn .new,
.btn .page-comment .comment .new, .page-comment .comment .btn .new {
  position: relative;
  top: -1px;
}

.btn-xs #comments .comment .new, #comments .comment .btn-xs .new,
.btn-xs .page-comment .comment .new, .page-comment .comment .btn-xs .new,
.btn-group-xs > .btn #comments .comment .new,
#comments .comment .btn-group-xs > .btn .new,
.btn-group-xs > .btn .page-comment .comment .new,
.page-comment .comment .btn-group-xs > .btn .new {
  top: 0;
  padding: 1px 5px;
}

#comments .comment .list-group-item.active > .new,
.page-comment .comment .list-group-item.active > .new,
#comments .comment .nav-pills > .active > a > .new,
.page-comment .comment .nav-pills > .active > a > .new {
  color: #005c8c;
  background-color: #fff;
}

#comments .comment .list-group-item > .new,
.page-comment .comment .list-group-item > .new {
  float: right;
}

#comments .comment .list-group-item > .new + .new,
.page-comment .comment .list-group-item > .new + .new {
  margin-right: 5px;
}

#comments .comment .nav-pills > li > a > .new,
.page-comment .comment .nav-pills > li > a > .new {
  margin-left: 3px;
}

.contextual-links-region-active.node, .contextual-links-region-active.block {
  outline-width: 0;
}

.node .contextual-links-wrapper, .block .contextual-links-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}

.node .contextual-links-wrapper ul.contextual-links, .block .contextual-links-wrapper ul.contextual-links {
  top: 35px;
  border-radius: 0;
  padding: 0;
}

.node .contextual-links-wrapper ul.contextual-links li a, .block .contextual-links-wrapper ul.contextual-links li a {
  margin: 0;
  padding-top: 0.4995rem;
  padding-bottom: 0.4995rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.node .contextual-links-wrapper ul.contextual-links li a:hover, .block .contextual-links-wrapper ul.contextual-links li a:hover {
  background-color: #c9e5ff;
}

@media (max-width: 992px) {
  .node .contextual-links-wrapper ul.contextual-links li a, .block .contextual-links-wrapper ul.contextual-links li a {
    padding: 1rem;
    font-size: 1.11765rem;
  }
}

.node .contextual-links-wrapper.contextual-links-active ul, .block .contextual-links-wrapper.contextual-links-active ul,
.node .contextual-links-wrapper.contextual-links-active .contextual-links-trigger,
.block .contextual-links-wrapper.contextual-links-active .contextual-links-trigger {
  -webkit-box-shadow: 0 -4px 4px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -4px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.node .contextual-links-trigger, .block .contextual-links-trigger {
  display: block;
  background-image: none;
  color: #4182be;
  text-indent: 0;
  height: 25px;
  outline-width: 0;
  display: block;
}

.node .contextual-links-trigger:before, .block .contextual-links-trigger:before, .node .contextual-links-trigger:after, .block .contextual-links-trigger:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.node .contextual-links-trigger:before, .block .contextual-links-trigger:before {
  content: "";
}

.node .contextual-links-trigger:before, .block .contextual-links-trigger:before {
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

html {
  /*
  * This is very important: A value set here may mess up all your *-to-rem/*-to-em calculations.
  * This has to be reset to here, because materialize's default is line-height: 1.5.
  * Please, define line-height in body tag.
  */
  line-height: normal;
}

html,
body {
  font-weight: 500;
  line-height: 1.47059rem;
}

@media only screen and (min-width: 0) {
  html,
  body {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) {
  html,
  body {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 17px;
  }
}

strong,
b {
  font-weight: 900;
}

h1 {
  font-size: 1.76471rem;
  line-height: 2.11765rem;
  color: #005c8c;
  margin-bottom: 2.25rem;
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

h3 {
  margin: 0;
  padding-bottom: 1.125rem;
  margin-bottom: 1.5rem;
  color: #005c8c;
}

h5 {
  margin: 0;
  font-weight: 700;
}

a {
  color: #005c8c;
  outline-width: 0;
  -moz-outline-width: 0;
}

a:hover,
a:active,
a:focus {
  color: #4182be;
  outline-width: 0;
  -moz-outline-width: 0;
}

::-webkit-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}

:-moz-placeholder {
  opacity: 1;
  color: #000 !important;
  opacity: 1 !important;
}

::-moz-placeholder {
  opacity: 1;
  color: #000 !important;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}

.alert {
  margin-top: 20px;
}

.view-display-id-events span,
.view-display-id-events p,
.view-display-id-events a,
.view-display-id-events h1,
.view-display-id-events h2,
.view-display-id-events h3,
.view-display-id-events h4,
.view-display-id-events h5,
.view-display-id-events h6,
.view-display-id-events li {
  font-style: normal !important;
}

.view-display-id-events .submitted,
.view-display-id-events .submitted span {
  font-weight: 300 !important;
}

.progress-disabled .ajax-progress-throbber {
  margin-left: 0.58824rem;
}

.progress-disabled .ajax-progress-throbber i.glyphicon {
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.progress-disabled .ajax-progress-throbber i.glyphicon:before {
  content: "";
}

.progress-disabled .ajax-progress-throbber i.glyphicon.glyphicon-spin {
  -webkit-animation: animatedThrobber 1s infinite linear;
  -moz-animation: animatedThrobber 1s infinite linear;
  -o-animation: animatedThrobber 1s infinite linear;
  animation: animatedThrobber 1s infinite linear;
}

.field .field-label {
  font-weight: 500;
  line-height: 1.4705;
}

.field-body a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  vertical-align: top;
}

.field-title-prefix {
  font-size: 1.17647rem;
}

.front .field-title-prefix {
  font-size: 1.76471rem;
}

.entity-profile2 .content {
  display: flex;
}

.entity-profile2 .content .field-profile-image {
  margin-right: 15px;
  flex: 0 0 62px;
}

.entity-profile2 .content .field-first-name {
  text-transform: uppercase;
  max-width: 60%;
  line-height: 1.25;
}

.field-contact .entity-profile2 .content {
  align-items: center;
  margin-bottom: 1.5rem;
}

.file-field-download-link {
  display: flex;
  margin-bottom: 0.75rem;
  justify-content: space-between;
  align-items: baseline;
  line-height: 1.2;
}

.file-field-download-link:before, .file-field-download-link:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.file-field-download-link:before {
  content: "";
}

.file-field-download-link .file-size {
  flex: 1 0 auto;
  margin-left: 1em;
}

.file-field-download-link .file-name {
  flex: 0 1 auto;
  word-break: break-word;
}

.file-field-download-link:before {
  order: 2;
  margin-left: 1em;
}

.field-first-paragraph {
  font-size: 1.17647rem;
  margin-bottom: 1.5rem;
}

.main-container,
#page-header {
  position: relative;
}

@media only screen and (min-width: 992px) {
  .main-container,
  #page-header {
    margin-top: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

#page-header {
  margin-top: -6rem;
  padding-top: 24px;
}

@media only screen and (min-width: 992px) {
  #page-header {
    margin-top: -1.2rem;
    padding-top: 63px;
  }
}

.main-container {
  padding-top: 0;
}

.region-1-mobile-bottom-wrapper {
  clear: left;
}

@media (max-width: 479px) {
  .page-welcome .main-container,
  .page-welcome #page-header {
    margin-top: -2rem;
    margin-bottom: 3rem;
  }
  .page-welcome #block-vbf-og-vbf-og-user-memberships {
    margin-top: 3rem;
  }
}

@media (max-width: 768px) {
  body.node-type-community #page-header {
    margin-bottom: 25px;
  }
}

.content-top {
  position: relative;
  border-bottom: 1px solid #b9b4af;
}

.content-top:before, .content-top:after {
  display: table;
  content: " ";
}

.content-top:after {
  clear: both;
}

.region-wrapper.region-content-wrapper.with-region-sidebar-first-mobile-bottom {
  float: right;
}

@media (max-width: 768px) {
  .region-wrapper.region-content-wrapper.with-region-sidebar-first-mobile-bottom {
    float: none;
  }
}

.region-wrapper.region-content-wrapper.with-region-sidebar-first-mobile-bottom.with-region-sidebar-second {
  margin-right: 25%;
}

@media (max-width: 768px) {
  .region-wrapper.region-content-wrapper.with-region-sidebar-first-mobile-bottom.with-region-sidebar-second {
    margin-right: 0;
  }
}

.region-wrapper.region-2-wrapper.with-region-sidebar-first-mobile-bottom {
  position: static;
}

.region-wrapper.region-2-wrapper.with-region-sidebar-first-mobile-bottom .region-sidebar-second {
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 768px) {
  .region-wrapper.region-2-wrapper.with-region-sidebar-first-mobile-bottom .region-sidebar-second {
    position: static;
  }
}

.region.region-content {
  position: relative;
}

.region.region-sidebar-first > section, .region.region-sidebar-first-mobile-bottom > section, .region.region-sidebar-second > section {
  margin-bottom: 2.25rem;
}

@media (max-width: 768px) {
  .region.region-sidebar-first > section, .region.region-sidebar-first-mobile-bottom > section, .region.region-sidebar-second > section {
    margin-bottom: 0;
  }
}

.region.region-sidebar-first h2,
.region.region-sidebar-first h3, .region.region-sidebar-first-mobile-bottom h2,
.region.region-sidebar-first-mobile-bottom h3, .region.region-sidebar-second h2,
.region.region-sidebar-second h3 {
  font-size: 1.17647rem;
  border-bottom: 1px solid #F2F0F0;
  padding: 15px 0;
}

.region-main-bottom {
  clear: both;
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 992px) {
  .region-main-bottom {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .region-main-bottom {
    margin-left: 25%;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .region-main-bottom {
    float: left;
    width: 75%;
  }
}

.page-403 .main-container,
.page-404 .main-container {
  font-size: 1.41176rem;
  margin-bottom: 6rem;
}

.page-node-members .region-content,
.page-node-projektraum .region-content,
.page-node-events .region-content,
.page-node-polls .region-content,
.page-node-posts .region-content,
.page-node-stream .region-content {
  margin-right: -15px;
  margin-left: -15px;
}

.page-node-members .region-content:before, .page-node-members .region-content:after,
.page-node-projektraum .region-content:before,
.page-node-projektraum .region-content:after,
.page-node-events .region-content:before,
.page-node-events .region-content:after,
.page-node-polls .region-content:before,
.page-node-polls .region-content:after,
.page-node-posts .region-content:before,
.page-node-posts .region-content:after,
.page-node-stream .region-content:before,
.page-node-stream .region-content:after {
  display: table;
  content: " ";
}

.page-node-members .region-content:after,
.page-node-projektraum .region-content:after,
.page-node-events .region-content:after,
.page-node-polls .region-content:after,
.page-node-posts .region-content:after,
.page-node-stream .region-content:after {
  clear: both;
}

.page-node-members #block-blockify-blockify-page-title,
.page-node-projektraum #block-blockify-blockify-page-title,
.page-node-events #block-blockify-blockify-page-title,
.page-node-polls #block-blockify-blockify-page-title,
.page-node-posts #block-blockify-blockify-page-title,
.page-node-stream #block-blockify-blockify-page-title {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .page-node-members #block-blockify-blockify-page-title,
  .page-node-projektraum #block-blockify-blockify-page-title,
  .page-node-events #block-blockify-blockify-page-title,
  .page-node-polls #block-blockify-blockify-page-title,
  .page-node-posts #block-blockify-blockify-page-title,
  .page-node-stream #block-blockify-blockify-page-title {
    float: left;
    width: 66.66667%;
  }
}

.page-node-members #block-blockify-blockify-actions,
.page-node-projektraum #block-blockify-blockify-actions,
.page-node-events #block-blockify-blockify-actions,
.page-node-polls #block-blockify-blockify-actions,
.page-node-posts #block-blockify-blockify-actions,
.page-node-stream #block-blockify-blockify-actions {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: right !important;
}

@media (min-width: 992px) {
  .page-node-members #block-blockify-blockify-actions,
  .page-node-projektraum #block-blockify-blockify-actions,
  .page-node-events #block-blockify-blockify-actions,
  .page-node-polls #block-blockify-blockify-actions,
  .page-node-posts #block-blockify-blockify-actions,
  .page-node-stream #block-blockify-blockify-actions {
    float: left;
    width: 33.33333%;
  }
}

@media (max-width: 992px) {
  .page-node-members #block-blockify-blockify-actions,
  .page-node-projektraum #block-blockify-blockify-actions,
  .page-node-events #block-blockify-blockify-actions,
  .page-node-polls #block-blockify-blockify-actions,
  .page-node-posts #block-blockify-blockify-actions,
  .page-node-stream #block-blockify-blockify-actions {
    float: none !important;
  }
}

.page-node-members #block-system-main,
.page-node-projektraum #block-system-main,
.page-node-events #block-system-main,
.page-node-polls #block-system-main,
.page-node-posts #block-system-main,
.page-node-stream #block-system-main {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .page-node-members #block-system-main,
  .page-node-projektraum #block-system-main,
  .page-node-events #block-system-main,
  .page-node-polls #block-system-main,
  .page-node-posts #block-system-main,
  .page-node-stream #block-system-main {
    float: left;
    width: 100%;
  }
}

.page-node-members #block-blockify-blockify-page-title #page-title,
.page-node-projektraum #block-blockify-blockify-page-title #page-title,
.page-node-events #block-blockify-blockify-page-title #page-title,
.page-node-polls #block-blockify-blockify-page-title #page-title,
.page-node-posts #block-blockify-blockify-page-title #page-title,
.page-node-stream #block-blockify-blockify-page-title #page-title {
  color: #333333;
  font-size: 1.76471rem;
  line-height: 3.82353rem;
  margin-bottom: 1.47059rem;
}

.page-group-node-unsubscribe #block-blockify-blockify-page-title #page-title {
  font-size: 1.17647rem;
  line-height: 2.05882rem;
  margin-bottom: 1.47059rem;
}

@media (min-width: 992px) {
  .page-group-node-unsubscribe #block-blockify-blockify-page-title #page-title {
    font-size: 1.76471rem;
    line-height: 3.82353rem;
  }
}

.page-group-node-subscribe-og-user-node #block-blockify-blockify-page-title #page-title {
  font-size: 1.17647rem;
  line-height: 2.05882rem;
  margin-bottom: 1.47059rem;
}

@media (min-width: 992px) {
  .page-group-node-subscribe-og-user-node #block-blockify-blockify-page-title #page-title {
    font-size: 1.76471rem;
    line-height: 1.3;
  }
}

#header {
  margin-bottom: 6em;
  background-image: linear-gradient(to right, #9fb3c9 50%, white 50%, white);
}

@media (min-width: 992px) {
  #header {
    margin-bottom: 0;
  }
}

#branding {
  height: 168px;
  background-color: #9fb3c9;
  background: url("../images/NEU_Blau_AmkA_Website_VBF_HeaderGrafik_2400px_dy150623.png") no-repeat;
  background-size: auto 168px;
  background-position: calc(50% - 190px) top;
  position: relative;
}

@media (max-width: 768px) {
  #branding {
    background: url("../images/vbf_HeaderBackground_mobile.png") no-repeat;
    background-size: 100% auto;
    background-position: center top;
    height: 80px;
  }
}

#branding .container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 20px;
  height: 100%;
  position: relative;
}

#branding .logo {
  display: block;
  position: absolute;
  left: .9rem;
  top: -0.6rem;
  width: 138px;
  height: 138px;
  z-index: 2;
}

#branding .logo img {
  position: relative;
  border-radius: 50%;
  box-shadow: 2px 10px 20px 0px rgba(0, 0, 0, 0.3);
  color: transparent;
}

@media (min-width: 769px) {
  #branding .logo {
    width: 200px;
    height: 200px;
    left: 0rem;
    top: -12px;
  }
}

@media (min-width: 992px) {
  #branding .logo {
    left: -4.82353rem;
    width: 223px;
    height: 223px;
  }
}

@media (min-width: 1200px) {
  #branding .logo {
    left: -3.82353rem;
  }
}

#branding #block-vbf-community-vbf-frankfurt-logo {
  order: 1;
  background: url("../images/Logo_StadtFFM_farbig.svg") no-repeat;
  background-size: 100% auto;
  height: 38.5833px;
  width: 270px;
  margin-left: auto;
  margin-bottom: 10px;
}

#branding #block-vbf-community-vbf-frankfurt-logo > div {
  text-indent: -9999rem;
  overflow: hidden;
}

@media (max-width: 768px) {
  #branding #block-vbf-community-vbf-frankfurt-logo {
    display: none;
  }
}

#branding .block-vbf-community-vbf-community-user {
  position: relative;
  height: 100%;
  order: 5;
  display: flex;
  align-items: flex-end;
  padding-left: 15px;
  margin-left: 0;
}

@media (max-width: 768px) {
  #branding .block-vbf-community-vbf-community-user {
    display: none;
  }
}

#branding .block-vbf-community-vbf-community-user .user-nav {
  position: relative;
}

#branding .block-vbf-community-vbf-community-user .user-nav .field-profile-image img {
  float: left;
  min-width: 3.52941rem;
}

#branding .block-vbf-community-vbf-community-user .user-nav .realname {
  display: none;
}

#branding .block-vbf-community-vbf-community-user .open a {
  background: transparent;
}

#branding .block-vbf-community-vbf-community-user .dropdown-menu {
  top: 5.29412rem;
  right: -5px;
  padding: 36px 15px;
  -webkit-box-shadow: 0 -4px 4px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -4px 4px 1px rgba(0, 0, 0, 0.1);
}

#branding .block-vbf-community-vbf-community-user .dropdown-menu > li {
  background-color: #4182be;
}

#branding .block-vbf-community-vbf-community-user .dropdown-menu > li > a {
  padding: 10px 40px;
  text-align: center;
}

#branding .block-vbf-community-vbf-community-user .dropdown-menu > li.active > a, #branding .block-vbf-community-vbf-community-user .dropdown-menu > li:hover > a {
  text-decoration: underline;
}

#branding .block-vbf-community-vbf-community-user .dropdown-menu > li + li {
  margin-top: 20px;
}

@media (max-width: 992px) {
  #branding .block-vbf-community-vbf-community-user .dropdown-menu {
    top: 5.88235rem;
  }
}

#branding .block-vbf-community-vbf-community-user .dropdown-arrow {
  top: 4.82353rem;
  left: 40%;
}

@media (max-width: 992px) {
  #branding .block-vbf-community-vbf-community-user .dropdown-arrow {
    top: 5.41176rem;
  }
}

#branding .block-vbf-community-vbf-community-user.login-block {
  min-width: 7.94118rem;
}

#branding .block-vbf-community-vbf-community-user.login-block .menu > li > a {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

#branding .block-vbf-community-vbf-community-user.login-block .menu > li > a:before {
  content: url("../images/login.svg");
  display: block;
  transition: all 0.2s ease-out;
  width: 1.76471rem;
  height: 1.76471rem;
  margin-left: -10px;
  margin-top: 10px;
  transform-origin: center center;
}

#branding .block-vbf-community-vbf-community-user.login-block .menu > li > a:hover:before {
  transform: scale(1.1);
}

@media (min-width: 992px) {
  #branding .block-vbf-community-vbf-community-user.login-block {
    min-width: 7.05882rem;
  }
}

@media (min-width: 1200px) {
  #branding .block-vbf-community-vbf-community-user.login-block {
    min-width: 6.76471rem;
  }
}

body.not-logged-in #branding .block-vbf-community-vbf-community-user.login-block {
  min-width: 0;
}

@media (min-width: 992px) {
  body.not-logged-in #branding .block-vbf-community-vbf-community-user.login-block {
    min-width: 0;
  }
}

@media (min-width: 1200px) {
  body.not-logged-in #branding .block-vbf-community-vbf-community-user.login-block {
    min-width: 0;
  }
}

#branding .block-vbf-community-vbf-community-site-slogan {
  flex: 1 0 28%;
  max-width: 28%;
  order: 1;
  font-family: "museo-sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #005c8c;
  display: flex;
  align-items: center;
  margin: 0 auto 0 141px;
}

#branding .block-vbf-community-vbf-community-site-slogan p {
  font-size: 0.82353rem;
  line-height: 1.25;
  margin: 0;
  font-family: "museo-sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  max-width: 17em;
}

@media (max-width: 768px) {
  #branding .block-vbf-community-vbf-community-site-slogan {
    display: none;
  }
}

#branding .block-vbf-community-vbf-community-amka {
  order: 2;
  padding: 0 15px;
  margin-left: 0;
}

@media (max-width: 768px) {
  #branding .block-vbf-community-vbf-community-amka {
    display: none;
  }
}

#branding .block-vbf-community-vbf-community-amka .nav > li a {
  display: block;
  padding: 0;
}

#branding .block-vbf-community-vbf-community-amka #amka-logo {
  color: transparent;
  font-size: 0;
  display: block;
  width: 241px;
  height: 53px;
  background-image: url(../images/amka.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  #branding .block-vbf-community-vbf-community-amka #amka-logo {
    width: 241px;
    height: 56px;
  }
}

#branding .nav > li > a:hover, #branding .nav > li > a:active, #branding .nav > li > a:focus {
  background: none;
}

#branding .nav > li > a.dropdown-toggle {
  color: #C3C3C3;
  display: flex;
  align-items: center;
  padding-right: 0;
}

#branding .nav > li > a.dropdown-toggle span.glyphicon {
  margin-left: 1ch;
}

#sidebar-toggle {
  right: 0;
}

.dropdown-toggle .glyphicon {
  margin-left: 1ch;
}

#footer {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  background: #dde1e3;
}

#footer .footer-container {
  display: flex;
  flex-flow: row wrap;
}

#footer .region-footer {
  display: flex;
  flex-flow: column;
  flex: 0 0 66%;
}

#footer .footer-logo {
  display: block;
  height: 37px;
  width: 267px;
  background: url("../images/vbf-footer-logo@2x.png");
  background-repeat: no-repeat;
  color: transparent;
  background-size: contain;
  margin-top: 3rem;
  margin-left: auto;
}

@media (max-width: 768px) {
  #footer .footer-logo {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  #footer .footer-logo {
    margin-top: 0rem;
    height: 35px;
    width: 260px;
  }
}

#block-vbf-community-vbf-community-footer-menu {
  margin-bottom: 1.5rem;
}

#block-vbf-community-vbf-community-footer-text .footer-signature-r1,
#block-vbf-community-vbf-community-footer-text .footer-signature-r2 {
  color: #696969;
}

@media (max-width: 480px) {
  #block-vbf-community-vbf-community-footer-text .footer-signature-r1,
  #block-vbf-community-vbf-community-footer-text .footer-signature-r2 {
    font-weight: 600;
  }
}

#block-vbf-community-vbf-community-footer-text .footer-signature-r1 {
  text-transform: uppercase;
}

#sidebar-wrapper #sidebar-content .block-vbf-community-vbf-community-user.login-block .dropdown-menu a {
  padding: 1.5rem 15px;
  position: relative;
  color: #FFFFFF;
  text-decoration: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../libs/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./libs/slick/fonts/slick.eot");
  src: url("./libs/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("./libs/slick/fonts/slick.woff") format("woff"), url("./libs/slick/fonts/slick.ttf") format("truetype"), url("./libs/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "Glyphicons Halflings";
  font-size: 40px;
  line-height: 1;
  color: white;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -5%;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -5%;
}

.slick-prev:before {
  content: "";
}

[dir="rtl"] .slick-prev:before {
  content: "";
}

.slick-next {
  right: -5%;
}

[dir="rtl"] .slick-next {
  left: -5%;
  right: auto;
}

.slick-next:before {
  content: "";
}

[dir="rtl"] .slick-next:before {
  content: "";
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 1.5rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  padding: 0;
  cursor: pointer;
  text-align: center;
}

.slick-dots li button {
  display: block;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  cursor: pointer;
  padding: 6px;
  background: white;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 1px solid #005c8c;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  display: table-cell;
  vertical-align: middle;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
  background: #005c8c;
}

.slick-dots li.slick-active button {
  background: #005c8c;
  padding: 9px;
}

@media (max-width: 768px) {
  .slick-dots {
    bottom: .5rem;
  }
  .slick-dots button {
    padding: 3px;
  }
  .slick-dots li.slick-active button {
    position: relative;
    top: 1px;
    padding: 4.5px;
  }
}

.node:not(.node-teaser) .slick-slider {
  background: white;
}

.node:not(.node-teaser) .slick-slider figcaption {
  background: white;
}

.slick-slider figcaption {
  color: #333333;
  padding: 0.5rem 1rem;
  font-size: 0.88235rem;
}

#backToTopBtn {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Fixed/sticky position */
  bottom: 20px;
  /* Place the button at the bottom of the page */
  right: 30px;
  /* Place the button 30px from the right */
  z-index: 99;
  /* Make sure it does not overlap */
  border: none;
  /* Remove borders */
  outline: none;
  /* Remove outline */
  color: white;
  /* Text color */
  cursor: pointer;
  /* Add a mouse pointer on hover */
  padding: 15px;
  /* Some padding */
  border-radius: 100%;
  /* Rounded corners */
  font-size: 0;
  width: 70px;
  height: 70px;
  line-height: 1;
  transition: all 0.2s ease;
  background: url(../images/ic_arrow_up_white_48px.svg) no-repeat center #296192;
  background-size: 40px auto;
}

#backToTopBtn:hover {
  background-color: #4182be;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.75);
}

.node header h5 {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

article.node:not(.node-teaser) .field-group-content-access {
  position: absolute;
  top: 15px;
  right: 15px;
}

article.node:not(.node-teaser) .field-body .field-item {
  font-size: 1.17647rem;
}

article.node:not(.node-teaser) .field-body .field-item p {
  margin-bottom: 1.47059rem;
}

.main-container .node-event .event-date-blue-box {
  display: flex;
  background: #4182be;
  color: #FFFFFF;
  padding: 1.17647rem;
}

.main-container .node-event .event-date-blue-box a {
  color: #FFFFFF !important;
}

.main-container .node-event .event-date-blue-box .date-wrapper {
  display: flex;
  margin-right: 30px;
  align-items: baseline;
  flex: 0 0 auto;
}

.main-container .node-event .event-date-blue-box .months-differ {
  display: flex;
}

.main-container .node-event .event-date-blue-box .day-digits,
.main-container .node-event .event-date-blue-box .months-through {
  font-size: 2.35294rem;
  line-height: 2.35294rem;
}

@media (max-width: 480px) {
  .main-container .node-event .event-date-blue-box .day-digits,
  .main-container .node-event .event-date-blue-box .months-through {
    font-size: 1.29412rem;
  }
}

.main-container .node-event .event-date-blue-box .month-strings {
  text-align: center;
  text-transform: uppercase;
}

.main-container .node-event .event-date-blue-box .title-column {
  flex: 1 0 62%;
}

.main-container .node-event .event-date-title h1,
.main-container .node-event .event-date-title h4 {
  color: #FFFFFF !important;
  margin-bottom: 0.375rem;
  font-size: 1.17647rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  .main-container .node-event .event-date-title h1,
  .main-container .node-event .event-date-title h4 {
    word-wrap: break-word;
  }
}

.main-container .node-event .event-date-title .ics {
  display: flex;
  margin-left: auto;
  align-items: last baseline;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: auto;
  padding-top: 0.70588rem;
}

.main-container .node-event .event-date-title .ics span {
  transform: translateY(2px);
  margin-right: 7.5px;
}

.main-container .node-event:not(.node-teaser) {
  margin-bottom: 1.5rem;
}

.main-container .node-event:not(.node-teaser) .event-date-blue-box {
  padding: 30px;
}

.main-container .node-event:not(.node-teaser) .field-body {
  margin-top: 3rem;
}

.node-media-content .file-field-download-link {
  text-transform: uppercase;
}

.node-media-content .field-media-content-file {
  margin-bottom: 1.5rem;
}

.node-media-content:not(.node-teaser) .field-media-content-file {
  margin-right: -15px;
  margin-left: -15px;
}

.node-media-content:not(.node-teaser) .field-media-content-file:before, .node-media-content:not(.node-teaser) .field-media-content-file:after {
  display: table;
  content: " ";
}

.node-media-content:not(.node-teaser) .field-media-content-file:after {
  clear: both;
}

.node-media-content:not(.node-teaser) .field-media-content-file .field-item {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .node-media-content:not(.node-teaser) .field-media-content-file .field-item {
    float: left;
    width: 33.33333%;
  }
}

.node-page:not(.node-teaser) .embedded-video,
.node-page:not(.node-teaser) .image,
.node-post:not(.node-teaser) .embedded-video,
.node-post:not(.node-teaser) .image,
.node-media-content:not(.node-teaser) .embedded-video,
.node-media-content:not(.node-teaser) .image {
  margin-bottom: 2.625rem;
}

.node-page:not(.node-teaser) .embedded-video img,
.node-page:not(.node-teaser) .image img,
.node-post:not(.node-teaser) .embedded-video img,
.node-post:not(.node-teaser) .image img,
.node-media-content:not(.node-teaser) .embedded-video img,
.node-media-content:not(.node-teaser) .image img {
  width: 100%;
}

.node-page:not(.node-teaser) .embedded-video .slick-dots,
.node-page:not(.node-teaser) .image .slick-dots,
.node-post:not(.node-teaser) .embedded-video .slick-dots,
.node-post:not(.node-teaser) .image .slick-dots,
.node-media-content:not(.node-teaser) .embedded-video .slick-dots,
.node-media-content:not(.node-teaser) .image .slick-dots {
  bottom: 0;
  transform: translateY(100%);
}

.node-page:not(.node-teaser) video,
.node-post:not(.node-teaser) video,
.node-media-content:not(.node-teaser) video {
  margin-bottom: 2.25rem;
}

.node-page:not(.node-teaser) video + .field_video_description,
.node-post:not(.node-teaser) video + .field_video_description,
.node-media-content:not(.node-teaser) video + .field_video_description {
  margin-top: -35px;
  margin-bottom: 1.5rem;
}

.node-type-event .image .slick-dots {
  bottom: 3.5rem !important;
}

.block h1, .block h2, .block h3, .block h4, .block h5, .block h6 {
  font-family: "museo-sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.main-container .block h2 {
  font-weight: 300;
}

.block h2.block-title {
  margin: 22px 0;
}

.block span.group.manager, .block a.subscribe, .block a.unsubscribe {
  background: white;
  text-align: center;
  border: 2px solid #062352;
  display: inline-block;
  align-items: center;
  font-size: 700;
  padding: 0.58824rem 1.17647rem 0.58824rem 1.76471rem;
  transition: all 0.2s ease;
}

.block span.group.manager:before, .block span.group.manager:after, .block a.subscribe:before, .block a.subscribe:after, .block a.unsubscribe:before, .block a.unsubscribe:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.block span.group.manager:before, .block a.subscribe:before, .block a.unsubscribe:before {
  content: " ";
}

.block span.group.manager:hover, .block a.subscribe:hover, .block a.unsubscribe:hover {
  text-decoration: none;
  padding-left: 1.47059rem;
  padding-right: 1.47059rem;
}

.block span.group.manager:before, .block a.subscribe:before, .block a.unsubscribe:before {
  position: absolute;
  left: 6px;
  top: 12px;
}

.block a.subscribe:before, .block a.subscribe:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.block a.subscribe:before {
  content: " ";
}

.block a.unsubscribe:before, .block a.unsubscribe:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.block a.unsubscribe:before {
  content: " ";
}

.block .contextual-links-wrapper {
  background: #FFF;
}

#block-vbf-og-vbf-og-community-description .contextual-links-wrapper {
  top: -3rem;
}

@media (min-width: 992px) {
  #block-vbf-og-vbf-og-community-description {
    width: calc(66.66667% - 12px);
  }
}

#block-vbf-og-vbf-og-community-sidebar-teaser {
  margin-bottom: 1.5rem;
}

#block-addressfield-staticmap-addressfield-staticmap {
  margin-bottom: 3rem;
}

#block-addressfield-staticmap-addressfield-staticmap div#map_canvas {
  padding: 0 10%;
  width: 100% !important;
}

div .collapsiblockCollapsed a,
div .collapsiblock a {
  background: none;
  text-decoration: none;
  cursor: default;
}

@media (min-width: 768px) {
  .collapsiblock-processed div.field,
  .collapsiblock-processed span.file,
  .collapsiblock-processed div.view {
    display: block !important;
  }
}

@media (max-width: 767px) {
  div .collapsiblock a:before, div .collapsiblock a:after {
    color: inherit;
    font-size: 15px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Glyphicons Halflings';
  }
  div .collapsiblock a:after {
    content: "";
  }
  div .collapsiblockCollapsed a:before, div .collapsiblockCollapsed a:after {
    color: inherit;
    font-size: 15px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Glyphicons Halflings';
  }
  div .collapsiblockCollapsed a:after {
    content: "";
  }
  div .collapsiblock a,
  div .collapsiblockCollapsed a {
    position: relative;
    cursor: pointer;
  }
  div .collapsiblock a:after,
  div .collapsiblockCollapsed a:after {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

#block-vbf-og-menu-vbf-og-menu-single .dropdown-dummy-link {
  border-top: 1px solid #c3c7d0;
}

#navbar-secondary,
#block-vbf-og-menu-vbf-og-menu-single {
  width: 100%;
}

#navbar-secondary nav[role="navigation"] ul,
#block-vbf-og-menu-vbf-og-menu-single nav[role="navigation"] ul {
  float: left;
  width: 100%;
}

@media (max-width: 768px) {
  #navbar-secondary nav[role="navigation"] ul,
  #block-vbf-og-menu-vbf-og-menu-single nav[role="navigation"] ul {
    float: none;
  }
}

#navbar-secondary .menu,
#block-vbf-og-menu-vbf-og-menu-single .menu {
  font-size: 1.17647rem;
}

#navbar-secondary .menu a,
#block-vbf-og-menu-vbf-og-menu-single .menu a {
  color: #333333;
  padding: 0.58824rem 0;
  transition: all 0.2s ease-out;
}

#navbar-secondary .menu a:hover, #navbar-secondary .menu a:focus,
#block-vbf-og-menu-vbf-og-menu-single .menu a:hover,
#block-vbf-og-menu-vbf-og-menu-single .menu a:focus {
  background: none;
  text-decoration: none;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
}

#navbar-secondary .menu a .glyphicon,
#block-vbf-og-menu-vbf-og-menu-single .menu a .glyphicon {
  color: #C3C3C3;
  top: 8px;
}

#navbar-secondary li,
#block-vbf-og-menu-vbf-og-menu-single li {
  float: left;
  width: 100%;
}

#navbar-secondary li.expanded ul,
#block-vbf-og-menu-vbf-og-menu-single li.expanded ul {
  padding-left: 15px;
}

@media (min-width: 992px) {
  #navbar-secondary,
  #block-vbf-og-menu-vbf-og-menu-single {
    float: left;
  }
}

#navbar-secondary .preview-breadcrumb,
#block-vbf-og-menu-vbf-og-menu-single .preview-breadcrumb {
  display: none;
}

@media (max-width: 768px) {
  #navbar-secondary .menu.og-menu-processed,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed {
    margin-bottom: 1.5rem;
  }
  #navbar-secondary .menu.og-menu-processed .preview-breadcrumb,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed .preview-breadcrumb {
    border: 1px solid #F2F0F0;
    padding: 6px;
    display: block;
    position: relative;
  }
  #navbar-secondary .menu.og-menu-processed .preview-breadcrumb a,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed .preview-breadcrumb a {
    display: inline-block;
    padding: 0;
  }
  #navbar-secondary .menu.og-menu-processed .preview-breadcrumb a:before, #navbar-secondary .menu.og-menu-processed .preview-breadcrumb a:after,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed .preview-breadcrumb a:before,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed .preview-breadcrumb a:after {
    color: inherit;
    font-size: 15px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Glyphicons Halflings';
  }
  #navbar-secondary .menu.og-menu-processed .preview-breadcrumb a:after,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed .preview-breadcrumb a:after {
    content: "   ";
  }
  #navbar-secondary .menu.og-menu-processed .preview-breadcrumb a.active:after,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed .preview-breadcrumb a.active:after {
    display: none;
  }
  #navbar-secondary .menu.og-menu-processed .preview-breadcrumb a:not(:first-child):not(.active),
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed .preview-breadcrumb a:not(:first-child):not(.active) {
    color: #C3C3C3;
  }
  #navbar-secondary .menu.og-menu-processed .menu-toggle,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed .menu-toggle {
    color: #C3C3C3;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 0.52941rem;
    font-size: 0.70588rem;
  }
  #navbar-secondary .menu.og-menu-processed.menu-open li,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed.menu-open li {
    display: block;
  }
  #navbar-secondary .menu.og-menu-processed.menu-open .preview-breadcrumb,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed.menu-open .preview-breadcrumb {
    margin-bottom: 0;
    min-height: 42px !important;
  }
  #navbar-secondary .menu.og-menu-processed.menu-open .preview-breadcrumb a,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed.menu-open .preview-breadcrumb a {
    display: none;
  }
  #navbar-secondary .menu.og-menu-processed.menu-open .menu-toggle .menu-label,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed.menu-open .menu-toggle .menu-label {
    display: inline;
  }
}

@media (max-width: 480px) {
  #navbar-secondary .menu.og-menu-processed.menu-open .preview-breadcrumb,
  #block-vbf-og-menu-vbf-og-menu-single .menu.og-menu-processed.menu-open .preview-breadcrumb {
    min-height: 42px !important;
  }
}

.block-vbf-og-menu {
  box-sizing: border-box;
}

[data-og-menu-toggle] {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 16px;
  text-align: left;
  background: #FFF;
  padding: 1rem;
  box-shadow: inset 0 0 0 2px #062352;
  color: #4182be;
  width: 100%;
  position: relative;
}

[data-og-menu-toggle]:hover {
  color: #4182be;
}

[data-og-menu-toggle]::after {
  --bd-width: 6px;
  content: '';
  display: block;
  position: absolute;
  right: 1rem;
  bottom: 50%;
  transform: translateY(50%);
  width: 0;
  height: 0;
  border-left: var(--bd-width) solid transparent;
  border-right: var(--bd-width) solid transparent;
  border-top: var(--bd-width) solid #4182be;
}

[data-og-menu-toggle]:focus {
  color: #4182be;
}

.og-menu-open [data-og-menu-toggle]::after {
  transform: translateY(50%) rotate(180deg);
}

@media (min-width: 768px) {
  [data-og-menu-toggle] {
    display: none;
  }
}

@media (max-width: 768px) {
  .page-node-veranstaltungen .region-sidebar-first {
    height: 0;
    overflow: hidden;
  }
  .page-node-veranstaltungen.og-menu-open .region-sidebar-first {
    height: auto;
    overflow: visible;
  }
}

#block-vbf-og-vbf-og-community-description {
  border-bottom: 1px solid #C3C3C3;
  margin-bottom: 3rem;
}

#block-vbf-og-vbf-og-community-description .field-body {
  margin-bottom: 3rem;
}

#block-views-vbf-og-page-references-block {
  margin-top: 2.25rem;
}

#block-views-vbf-og-page-references-block > .view > .view-content {
  display: flex;
  flex-wrap: wrap;
}

#block-views-vbf-og-page-references-block > .view > .view-content:before {
  display: block;
}

@media (max-width: 992px) {
  #block-views-vbf-og-page-references-block > .view > .view-content {
    display: block;
  }
}

#block-blockify-blockify-page-title #page-title {
  margin-bottom: 3rem;
}

@media (max-width: 767px) {
  #block-blockify-blockify-page-title #page-title {
    margin-bottom: 1.5rem;
    padding-left: 0;
    padding-right: 0;
  }
}

#block-blockify-blockify-page-title h5 {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  color: #333333;
}

#block-blockify-blockify-actions .action-links {
  padding: 0;
  margin: 0;
  list-style: none;
}

#block-blockify-blockify-actions .action-links li {
  float: left;
  margin-bottom: 0.75rem;
}

#block-blockify-blockify-actions .action-links li a {
  color: #FFFFFF !important;
  transition: all 0.2s ease;
  padding: 15px;
}

#block-blockify-blockify-actions .action-links li a:hover {
  text-decoration: none;
  background: #062352;
}

#block-blockify-blockify-actions .action-links-single-wrapper li a,
#block-blockify-blockify-actions #add-node-toggle {
  width: 100%;
  display: block;
  font-size: 1.05882rem;
  background: #F2F0F0;
  position: relative;
  color: #005c8c;
  line-height: 3.76471rem;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  transition: all 0.2s ease-out;
  padding-left: 1.29412rem;
  padding-right: 4.82353rem;
}

#block-blockify-blockify-actions .action-links-single-wrapper li a:before, #block-blockify-blockify-actions .action-links-single-wrapper li a:after,
#block-blockify-blockify-actions #add-node-toggle:before,
#block-blockify-blockify-actions #add-node-toggle:after {
  color: white;
  font-size: 1.41176rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

#block-blockify-blockify-actions .action-links-single-wrapper li a:before,
#block-blockify-blockify-actions #add-node-toggle:before {
  content: "+";
}

#block-blockify-blockify-actions .action-links-single-wrapper li a:before,
#block-blockify-blockify-actions #add-node-toggle:before {
  position: absolute;
  background: #4182be;
  top: 0;
  right: 0;
  height: 100%;
  width: 3.52941rem;
  text-align: center;
  justify-content: center;
  transition: all 0.2s ease-out;
}

#block-blockify-blockify-actions .action-links-single-wrapper li a:hover,
#block-blockify-blockify-actions #add-node-toggle:hover {
  background: #062352;
  text-decoration: none;
  color: white;
}

#block-blockify-blockify-actions .action-links-single-wrapper li a:hover:before,
#block-blockify-blockify-actions #add-node-toggle:hover:before {
  background: rgba(6, 35, 82, 0.8) !important;
}

@media (min-width: 768px) {
  #block-blockify-blockify-actions .action-links-single-wrapper li a,
  #block-blockify-blockify-actions #add-node-toggle {
    font-size: 0.94118rem;
  }
}

@media (min-width: 992px) {
  #block-blockify-blockify-actions .action-links-single-wrapper li a,
  #block-blockify-blockify-actions #add-node-toggle {
    padding-left: 0.94118rem;
    padding-right: 3.52941rem;
  }
}

@media (min-width: 1200px) {
  #block-blockify-blockify-actions .action-links-single-wrapper li a,
  #block-blockify-blockify-actions #add-node-toggle {
    font-size: 1.05882rem;
    padding-left: 1.29412rem;
  }
}

#block-blockify-blockify-actions .action-links-single-wrapper li {
  width: 100%;
}

#block-blockify-blockify-actions .action-links-single-wrapper li a {
  padding: 0 15px;
}

#block-blockify-blockify-actions .action-links-multiple-wrapper {
  margin-bottom: 30px;
  position: relative;
}

#block-blockify-blockify-actions .action-links-multiple-wrapper ul {
  padding: 0;
  font-size: 1rem;
  background: #062352;
}

#block-blockify-blockify-actions .action-links-multiple-wrapper ul li {
  float: none;
  margin-bottom: 0;
}

#block-blockify-blockify-actions .action-links-multiple-wrapper ul li a {
  display: block;
  background: #4182be;
}

#block-blockify-blockify-actions .action-links-multiple-wrapper .action-links {
  max-height: 0;
  overflow: hidden;
  position: absolute;
  z-index: 200;
  width: 100%;
}

#block-blockify-blockify-actions .action-links-multiple-wrapper .action-links.open {
  max-height: 500px;
}

#block-vbf-og-vbf-og-user-memberships h2 {
  color: #005c8c;
}

#block-vbf-og-vbf-og-user-memberships .views-row {
  margin-bottom: 1.875rem;
  position: relative;
}

#block-vbf-og-vbf-og-user-memberships .views-row:before, #block-vbf-og-vbf-og-user-memberships .views-row:after {
  display: table;
  content: " ";
}

#block-vbf-og-vbf-og-user-memberships .views-row:after {
  clear: both;
}

#block-vbf-og-vbf-og-user-memberships .field-sc-profile-image {
  float: left;
}

#block-vbf-og-vbf-og-user-memberships .field-sc-profile-image img {
  border-radius: 50%;
  width: 3.64706rem;
  height: auto;
}

#block-vbf-og-vbf-og-user-memberships .views-field-title {
  position: absolute;
  top: 50%;
  left: 4.52941rem;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: 500;
}

#block-views-communities-block-1 .block-title {
  color: #005c8c;
  font-size: 2.64706rem;
  font-weight: 500;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

#block-vbf-community-vbf-newsletter iframe {
  height: 500px;
}

#navbar-secondary {
  margin-bottom: 0;
  border-bottom: 1px solid #c3c7d0;
}

.tabs--primary {
  border-bottom: none;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.tabs--primary:after, .tabs--primary:before {
  display: none;
}

.tabs--primary li {
  display: inline-block;
  float: none;
  margin-bottom: 0;
}

.tabs--primary li a {
  background: white;
  border: 1px solid #4182be;
  display: inline-block;
  float: none;
  padding: 0px 10px;
  line-height: 1.9;
  margin-right: 1rem;
  transition: all 0.15s ease-out;
}

.tabs--primary li a[href*="delete2"] {
  display: none;
}

.tabs--primary li a[href*="comment"] {
  display: none;
}

.region-navigation {
  display: flex;
}

.region-navigation #block-vbf-community-vbf-community-user .user-nav {
  width: auto;
}

.region-navigation #block-vbf-community-vbf-community-user .dropdown {
  position: relative;
}

.region-navigation #block-vbf-community-vbf-community-user .dropdown .dropdown-menu {
  position: absolute;
  width: auto;
}

.region-navigation #block-vbf-community-vbf-community-user .dropdown .dropdown-menu li {
  margin: 0;
}

.region-navigation #block-vbf-community-vbf-community-user .dropdown .dropdown-menu li a {
  padding: 0 15px;
}

.region-navigation #block-vbf-community-vbf-community-user .dropdown .dropdown-menu li a::after {
  left: 0;
  width: 100%;
}

.region-navigation #block-vbf-community-vbf-community-user a {
  color: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 768px) {
  .region-navigation {
    flex-direction: column;
  }
  .region-navigation #block-vbf-community-vbf-community-user {
    border-top: 0.06667rem solid #0e3262;
  }
}

@media screen and (min-width: 768px) {
  .region-navigation > section {
    margin: 0 calc(0.56667rem * 2);
  }
  .region-navigation > section:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) and (min-width: 1200px) {
  .region-navigation > section:last-child {
    margin-right: 15px;
  }
}

@media screen and (min-width: 768px) {
  .region-navigation .field-profile-image {
    display: none;
  }
}

#navbar-secondary .expanded.open ul,
.block-vbf-og-menu .expanded.open ul {
  display: block;
}

#navbar-secondary .expanded.open a,
.block-vbf-og-menu .expanded.open a {
  background: none;
}

#navbar-secondary .expanded ul,
.block-vbf-og-menu .expanded ul {
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
}

.pagination > li {
  display: inline-flex;
}

.pagination > li.pager-first, .pagination > li.pager-last, .pagination > li.next, .pagination > li.prev {
  font-size: 0;
}

.pagination > li.pager-first a, .pagination > li.pager-last a, .pagination > li.next a, .pagination > li.prev a {
  display: flex;
}

.pagination > li.pager-first a:before, .pagination > li.pager-first a:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.pagination > li.pager-first a:before {
  content: " ";
}

.pagination > li.prev a:before, .pagination > li.prev a:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.pagination > li.prev a:before {
  content: " ";
}

.pagination > li.next a:before, .pagination > li.next a:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.pagination > li.next a:before {
  content: " ";
}

.pagination > li.pager-last a:before, .pagination > li.pager-last a:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.pagination > li.pager-last a:before {
  content: " ";
}

@media (max-width: 768px) {
  .pagination > li {
    display: none;
  }
  .pagination > li.pager-first, .pagination > li.prev, .pagination > li.pager-ellipsis, .pagination > li.active, .pagination > li.next, .pagination > li.pager-last, .pagination > li:nth-child(2), .pagination > li:nth-child(3) {
    display: block;
  }
  .pagination > li.pager-first a, .pagination > li.prev a, .pagination > li.active a, .pagination > li.next a, .pagination > li.pager-last a {
    padding-top: 10px;
    padding-bottom: 9px;
  }
}

ul.pager--infinite-scroll.pager > li > a {
  background-color: #005c8c;
  color: #FFFFFF !important;
  border: none;
  border-radius: 0;
  transition: all 0.3s ease-out;
  padding: 1.5% 5%;
}

ul.pager--infinite-scroll.pager > li > a:hover {
  background-color: #062352 !important;
  padding: 1.5% 6%;
}

/*
section#navbar li a[href="/de/stadtraumfrankfurt"] {
  position: relative;
  text-indent: -99999px;

  &:after {
    position: absolute;
    display: block;
    content: "";
    background-image: url("/profiles/vbf_community/themes/custom/vbf_community_theme/images/StadtRAUMLogo.png");
    background-color: transparent;
    background-size: contain;
    width: 117px;
    height: 85px;
    z-index: 2;
    bottom:auto;
  }
}
*/
#block-vbf-community-vbf-landing-page-links .link-line2 {
  font-weight: normal;
}

#block-vbf-community-vbf-landing-page-links .nav li a.communities-link.startraum {
  position: relative;
  background-image: none;
}

#block-vbf-community-vbf-landing-page-links .nav li a.communities-link.startraum:before {
  transition: all 0.5s ease;
  content: "";
  background: url("../images/vbf_AmkA_Picto_stadtRAUMfrankfurt_white.svg") no-repeat center center;
  background-color: #296192;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: block;
  margin-right: 1.17647rem;
  flex: 0 0 auto;
}

@media (min-width: 768px) {
  #block-vbf-community-vbf-landing-page-links .nav li a.communities-link.startraum:before {
    height: 100px;
    width: 100px;
  }
}

#block-vbf-community-vbf-landing-page-links .nav li a.communities-link.startraum:hover {
  color: #4182be;
}

#block-vbf-community-vbf-landing-page-links .nav li a.communities-link.startraum:hover:before {
  background-color: #4182be;
}

section#navbar li a {
  text-transform: none;
  font-weight: 700;
  font-size: 0.88235rem;
}

@media screen and (max-width: 768px) {
  section#navbar {
    height: 64px;
  }
  section#navbar .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.og_menu_trigger {
  color: #333333;
  padding: 0.58824rem 0;
  transition: all 0.2s ease-out;
  font-size: 1.2rem;
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.og_menu_trigger span {
  top: 4px;
}

.og_menu_trigger:hover, .og_menu_trigger:focus {
  background: none;
  text-decoration: none;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
}

@media (min-width: 768px) {
  .og_menu_trigger {
    display: none;
  }
}

#block-vbf-og-menu-vbf-og-menu-single.open .og_menu_trigger span {
  transform: rotate(180deg);
}

.view-vbf-og-recent-comments.view-display-id-default {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.view-vbf-og-recent-comments.view-display-id-default .views-row {
  margin-bottom: 2.25rem;
  clear: both;
}

.view-vbf-og-recent-comments.view-display-id-default .views-field-realname,
.view-vbf-og-recent-comments.view-display-id-default .comment-who-where span a:first-child {
  padding-top: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.view-vbf-og-recent-comments.view-display-id-default .comment-who-where {
  line-height: 1.3em;
}

.view-vbf-og-recent-comments.view-display-id-default .views-field-created {
  font-size: 0.70588rem;
  color: #C3C3C3;
}

.view-vbf-og-recent-comments.view-display-id-default .field-comment-body {
  clear: both;
  padding-top: 0.75rem;
  padding-left: 0.88235rem;
}

.view-communities {
  margin-bottom: 4.5rem;
}

.view-communities .view-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -15px;
}

.view-communities .views-row {
  margin-bottom: 1.5rem;
  margin-right: 15px;
  margin-left: 15px;
  width: calc( 100% - 30px);
}

@media (min-width: 480px) {
  .view-communities .views-row {
    width: calc( 50% - 30px);
  }
}

@media (min-width: 768px) {
  .view-communities .views-row {
    width: calc( 33.3333% - 30px);
  }
}

@media (min-width: 992px) {
  .view-communities .views-row {
    width: calc( 25% - 30px);
  }
}

.view-communities .views-row.hidden-on-frontpage {
  display: none;
}

.view-vbf-og-page-references .views-row {
  width: 100%;
}

.view-vbf-og-group-members .views-exposed-form .views-exposed-widgets {
  display: flex;
  align-items: flex-end;
}

.view-vbf-og-group-members .views-exposed-widget {
  padding: 0;
  margin-right: 15px;
}

.view-vbf-og-group-members .views-exposed-widget .form-submit {
  margin: 0;
}

select.form-control {
  -webkit-appearance: none;
  border-radius: 0;
  border-bottom-left-radius: 0;
}

.webform-container-inline div.chosen-container {
  display: inline-block;
}

.webform-container-inline.webform-component-textarea .form-textarea-wrapper {
  display: block;
}

.field-type-entityreference .table-responsive {
  overflow: visible;
}

.tabledrag-toggle-weight-wrapper {
  display: none;
}

.field-multiple-table > thead > tr > th {
  border: none;
  padding: 0;
}

.field-multiple-table > thead > tr > th label {
  margin: 0;
}

.field-multiple-table > tbody > .draggable {
  background: none;
}

.field-multiple-table > tbody > .draggable:nth-of-type(2n + 1), .field-multiple-table > tbody > .draggable:hover {
  background: none;
}

.field-multiple-table > tbody > .draggable .tabledrag-handle .handle {
  background-image: none;
}

.field-multiple-table > tbody > .draggable .tabledrag-handle .handle:before, .field-multiple-table > tbody > .draggable .tabledrag-handle .handle:after {
  color: #333333;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.field-multiple-table > tbody > .draggable .tabledrag-handle .handle:before {
  content: "";
}

.field-multiple-table > tbody > .draggable td {
  padding: 0.23529rem 0;
  border: none;
}

.field-multiple-table > tbody > .draggable td .form-item {
  margin: 0;
}

.field-multiple-table > tbody > .draggable td .form-item label {
  margin: 0;
}

.field-multiple-table > tbody > .draggable td .form-item label:empty {
  display: none;
}

.field-multiple-table > tbody > .draggable .field-multiple-drag {
  vertical-align: middle;
}

.form-managed-file input.form-file {
  padding: 0;
  line-height: 3rem;
}

.form-textarea-wrapper {
  display: block;
}

#user-profile-form .collapse {
  display: block;
  opacity: 1;
}

fieldset[id^="edit-spamicide"] {
  display: none;
}

.form-managed-file.image-widget {
  display: flex;
}

.form-managed-file.image-widget .image-widget-data {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-managed-file.image-widget .image-widget-data .focal-point-help {
  font-size: 0.76471rem;
}

.form-managed-file.image-widget .image-widget-data .form-group {
  flex-grow: 1;
}

@media (max-width: 768px) {
  .form-managed-file.image-widget {
    display: block;
  }
  .form-managed-file.image-widget .image-widget-data {
    display: inline;
  }
  .form-managed-file.image-widget .image-widget-data .form-group {
    display: flex;
    align-items: center;
  }
  .form-managed-file.image-widget .image-widget-data .form-group + .form-group {
    margin-top: 10px;
  }
  .form-managed-file.image-widget .form-control {
    padding: 8px 12px;
    width: calc(100% - 24px);
  }
  .form-managed-file.image-widget .focal-point-help {
    display: none;
  }
}

div.horizontal-tabs {
  margin: 0;
  border: 0;
}

div.horizontal-tabs ul.horizontal-tabs-list {
  background: transparent;
  display: flex;
  border: 0;
}

div.horizontal-tabs li.horizontal-tab-button {
  background: #4182be;
  border: none;
}

div.horizontal-tabs li.horizontal-tab-button a {
  color: #FFFFFF;
}

div.horizontal-tabs li.horizontal-tab-button a:hover {
  background: #005c8c;
}

div.horizontal-tabs li.horizontal-tab-button a:hover strong {
  color: #FFFFFF;
}

div.horizontal-tabs li.horizontal-tab-button strong {
  font-weight: 500;
}

div.horizontal-tabs li.horizontal-tab-button.selected {
  border: 1px solid #C3C3C3;
  border-bottom: 0;
}

div.horizontal-tabs li.horizontal-tab-button.selected strong {
  color: #555555;
  font-weight: 500;
}

div.horizontal-tabs .horizontal-tabs-panes {
  padding-top: 15px;
  background: #FFFFFF;
  border: 1px solid #C3C3C3;
  margin-top: -1px;
}

div.horizontal-tabs fieldset.horizontal-tabs-pane {
  padding: 15px;
}

.field-group-htabs-wrapper + .form-actions {
  margin-top: 0.5rem;
}

.form-actions #edit-cancel {
  margin-left: 10px;
}

.form-control {
  border-color: #4182be;
}

.form-item:not(.form-type-checkbox):not(.form-type-textarea):not(.form-item-field-event-date-today-tomorrow):not(#edit-intro):not(.date-group):not(.time-group):not(.form-type-managed-file):not(.form-type-radio):not(.form-autocomplete):not(.form-type-select) > label {
  transition: all 0.1s ease-in;
  position: absolute;
  top: 10px;
  left: 12px;
  cursor: text;
  font-weight: 300;
  font-size: 0.82353rem;
  line-height: 1.41176rem;
}

.form-item:not(.form-type-checkbox):not(.form-type-textarea):not(.form-item-field-event-date-today-tomorrow):not(#edit-intro):not(.date-group):not(.time-group):not(.form-type-managed-file):not(.form-type-radio):not(.form-autocomplete):not(.form-type-select) {
  position: relative;
  margin-bottom: 40px;
}

.form-item:not(.form-type-checkbox):not(.form-type-textarea):not(.form-item-field-event-date-today-tomorrow):not(#edit-intro):not(.date-group):not(.time-group):not(.form-type-managed-file):not(.form-type-radio):not(.form-autocomplete):not(.form-type-select).focus > label {
  top: -18px;
  left: 2px;
  font-size: 0.70588rem;
  line-height: 1.05882rem;
}

@media (max-width: 768px) {
  .form-item:not(.form-type-checkbox):not(.form-type-textarea):not(.form-item-field-event-date-today-tomorrow):not(#edit-intro):not(.date-group):not(.time-group):not(.form-type-managed-file):not(.form-type-radio):not(.form-autocomplete):not(.form-type-select).form-item.form-type-select > label {
    position: relative;
    top: auto;
    left: auto;
  }
}

.form-control {
  padding: 8px 12px;
  height: 44px;
}

.form-type-checkbox, #sliding-popup .eu-cookie-compliance-category {
  position: relative;
  padding-left: 20px;
}

.form-type-checkbox label:before, #sliding-popup .eu-cookie-compliance-category label:before {
  position: absolute;
  left: 0;
  top: -2px;
  content: "";
  display: block;
  border: 1px solid #4182be;
  width: 30px;
  height: 30px;
  background-color: white;
  font-weight: 100;
}

.form-type-checkbox label:after, #sliding-popup .eu-cookie-compliance-category label:after {
  display: none;
  position: absolute;
  left: 0;
  top: -2px;
  content: "X";
  background-color: #4182be;
  width: 30px;
  height: 30px;
  color: white;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.form-type-checkbox input[type="checkbox"], #sliding-popup .eu-cookie-compliance-category input[type="checkbox"] {
  opacity: 0;
}

.form-type-checkbox.active label:after, #sliding-popup .active.eu-cookie-compliance-category label:after {
  display: block;
}

.form-item.form-type-managed-file.form-group.form-item-field-video-und-0 > label {
  position: relative;
  top: auto;
  left: auto;
  cursor: text;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
  font-weight: 500;
  color: #333333;
}

.field-widget-image-image.form-group .panel-title {
  margin-bottom: 0.8rem;
}

@media (max-width: 768px) {
  table.managed-files.table-bordered > tbody > tr > td {
    vertical-align: top;
  }
}

.form-fields-wrapper legend.panel-heading {
  margin-bottom: 0.5em;
}

table.managed-files tr.ajax-new-content td:nth-child(3) {
  vertical-align: text-bottom;
  padding-top: 32px;
}

.field-name-field-hide-fullname {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  select.input-sm {
    height: auto;
  }
}

/*
.webkit-browser .form-managed-file input.form-file {
  padding-top: 0 !important;
}*/
.form-managed-file .file {
  margin-top: 10px;
}

.form-managed-file .file-size {
  margin-top: 10px;
}

.form-managed-file .form-item-field-video-und-1-display .control-label {
  position: relative;
  padding-left: 38px;
}

.form-managed-file .form-item-field-video-und-1-display .control-label:before {
  left: 3px;
  top: -6px;
}

.form-managed-file .form-item-field-video-und-1-display .control-label:after {
  left: 3px;
  top: -6px;
}

.form-managed-file .form-item-field-video-und-1-description {
  margin-top: 20px;
}

/*
.views-exposed-form .form-item {
  &.form-type-datepicker {
    margin-top: 20px;
    //workaround ... lets see
    margin-left: 1px;
    margin-right: -2px;
  }
}*/
label[for="edit-field-www-und-0"] {
  z-index: 1;
}

.field-name-field-video {
  min-height: 20px;
}

div.addressfield-container-inline > div.form-item {
  float: none;
}

#user-login--3 a[href*="password"] {
  position: relative;
  z-index: 2;
}

.webkit-browser .node-form .date-form-element-content .container-inline-date input {
  margin-top: 0;
  top: 1px;
}

.node-form .field-name-body textarea {
  height: 150px;
}

@media only screen and (min-width: 992px) {
  .node-form .container-inline-date .input-group {
    float: left;
    width: 49%;
  }
  .node-form .container-inline-date .input-group.time-group {
    margin-left: 1%;
  }
  .node-form .container-inline-date .input-group.date-group {
    margin-right: 1%;
  }
}

.node-form #edit-media-input {
  margin-bottom: 0;
}

.node-form .table-responsive {
  border: none;
}

.node-form table th, .node-form table td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.node-form table th {
  font-size: 1rem !important;
  border-width: 1px !important;
}

.node-form table td .btn {
  float: right;
}

.node-form a.panel-title {
  display: flex !important;
  justify-content: space-between;
}

.node-form a.panel-title:before, .node-form a.panel-title:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.node-form a.panel-title:after {
  content: " ";
}

.node-form a.panel-title:hover, .node-form a.panel-title:active {
  text-decoration: none;
}

.node-form a.panel-title.collapsed:after {
  content: "";
}

.node-form .field-name-vbf-group-content-promote-opt-in,
.node-form .bordered-container {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin: 0;
  border-bottom: 1px solid #b9b4af;
}

.node-event-form .field-name-body {
  border-bottom: 1px solid #b9b4af;
  padding-bottom: 1.5rem;
  margin-bottom: 3rem;
}

.node-event-form .field-name-field-other-text,
.node-event-form .field-name-field-phone {
  border-bottom: 1px solid #b9b4af;
  padding-bottom: 1.5rem;
}

.paragraphs-item-teaser {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .paragraphs-item-teaser {
    float: left;
    width: 33.33333%;
  }
}

.paragraphs-item-teaser .content--teaser-image,
.paragraphs-item-teaser .field-teaser-image,
.paragraphs-item-teaser .field-item {
  width: 100%;
}

.paragraphs-item-teaser .content--teaser-image img,
.paragraphs-item-teaser .field-teaser-image img,
.paragraphs-item-teaser .field-item img {
  width: 100%;
  object-fit: cover;
}

.paragraphs-item-teaser .field-teaser-title,
.paragraphs-item-teaser .field-teaser-body,
.paragraphs-item-teaser .field-teaser-title a {
  color: white;
  font-size: 1rem;
  line-height: 1.23529rem;
}

.paragraphs-item-teaser .content--teaser-content {
  width: 100%;
  background-color: rgba(65, 130, 190, 0.9);
  padding: 15px;
}

.paragraphs-item-teaser .content--teaser-content p {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .paragraphs-item-teaser:hover .content--teaser-content {
    transform: translateY(0);
  }
  .paragraphs-item-teaser .content {
    position: relative;
    padding-bottom: 86.9697%;
  }
  .paragraphs-item-teaser .content--teaser-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .paragraphs-item-teaser .content--teaser-image,
  .paragraphs-item-teaser .field-teaser-image,
  .paragraphs-item-teaser .field-item {
    width: 100%;
    height: 100%;
  }
  .paragraphs-item-teaser .content--teaser-image img,
  .paragraphs-item-teaser .field-teaser-image img,
  .paragraphs-item-teaser .field-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .paragraphs-item-teaser .content--teaser-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(65, 130, 190, 0.9);
    padding: 20px 14px 0 14px;
    transform: translateY(calc(100% - 85px));
    transition: transform 0.6s ease-in-out;
    pointer-events: none;
  }
  .paragraphs-item-teaser .field-teaser-title {
    margin-bottom: 2.25rem;
  }
}

.view-vbf-og-main-teaser .paragraphs-item-teaser {
  background: white;
  margin-bottom: 0;
  padding-bottom: 0.375rem;
}

.view-vbf-og-main-teaser .paragraphs-item-teaser .field-teaser-title,
.view-vbf-og-main-teaser .paragraphs-item-teaser .field-teaser-body,
.view-vbf-og-main-teaser .paragraphs-item-teaser .field-teaser-link {
  padding: 0 15px;
}

.view-vbf-og-main-teaser .paragraphs-item-teaser .field-teaser-title {
  margin: 0;
  border: 0;
}

.view-vbf-og-main-teaser .paragraphs-item-teaser .field-teaser-title {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  overflow-wrap: break-word;
  /*-ms-word-break: break-all;
  -ms-word-wrap: break-all;
  -webkit-word-break: break-word;
  -webkit-word-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;*/
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.view-vbf-og-main-teaser .paragraphs-item-teaser .field-teaser-image img {
  width: 100%;
}

.contact-reference {
  margin-top: 3rem;
  margin-bottom: 3rem;
  height: 3.64706rem;
}

.contact-reference .field-profile-image {
  position: absolute;
  width: 3.64706rem;
  height: 3.64706rem;
}

.contact-reference .field-first-name {
  margin-left: 4.52941rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.05882rem;
  max-width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-reference .contact-position {
  margin-left: 4.52941rem;
  color: #C3C3C3;
  font-size: 0.70588rem;
  display: block;
}

.paragraphs-item-text-headline .field-title {
  font-size: 1.76471rem;
  line-height: 2.11765rem;
}

.paragraphs-item-text-headline .field-first-paragraph {
  font-size: 1rem;
}

.field-landing-page-content {
  padding-right: 40px;
}

body.blue #page-header .group.manager,
body.blue #page-header a,
body.blue .main-container .group.manager,
body.blue .main-container a {
  color: #4182be !important;
  border-color: #4182be;
}

body.blue #page-header .navbar-nav.secondary li.active,
body.blue .main-container .navbar-nav.secondary li.active {
  border-color: #005c8c;
}

body.blue #page-header .pagination > .active > span,
body.blue .main-container .pagination > .active > span {
  background: #4182be;
  border-color: #4182be;
}

body.blue #page-header #navbar-secondary,
body.blue .main-container #navbar-secondary {
  color: #005c8c;
}

body.blue #page-header #navbar-secondary a,
body.blue .main-container #navbar-secondary a {
  color: #005c8c;
}

body.blue #page-header aside > .region h1, body.blue #page-header section > .region h1,
body.blue .main-container aside > .region h1,
body.blue .main-container section > .region h1 {
  color: #005c8c;
}

body.blue #page-header aside > .region h1 a, body.blue #page-header section > .region h1 a,
body.blue .main-container aside > .region h1 a,
body.blue .main-container section > .region h1 a {
  color: #005c8c;
}

body.blue #page-header aside > .region h2,
body.blue .main-container aside > .region h2 {
  color: #005c8c;
}

body.blue #page-header aside > .region h2 a,
body.blue .main-container aside > .region h2 a {
  color: #005c8c;
}

body.blue #page-header aside > .region h3,
body.blue .main-container aside > .region h3 {
  color: #005c8c;
}

body.blue #page-header aside > .region h3 a,
body.blue .main-container aside > .region h3 a {
  color: #005c8c;
}

body.blue #navbar-secondary .glyphicon:before,
body.blue #block-vbf-og-menu-vbf-og-menu-single .glyphicon:before {
  color: #005c8c;
}

body.blue #navbar-secondary a:hover, body.blue #navbar-secondary a.active,
body.blue #block-vbf-og-menu-vbf-og-menu-single a:hover,
body.blue #block-vbf-og-menu-vbf-og-menu-single a.active {
  color: #005c8c !important;
}

body.blue #navbar-secondary a:hover .glyphicon, body.blue #navbar-secondary a.active .glyphicon,
body.blue #block-vbf-og-menu-vbf-og-menu-single a:hover .glyphicon,
body.blue #block-vbf-og-menu-vbf-og-menu-single a.active .glyphicon {
  color: #005c8c !important;
}

body.blue .contextual-links-trigger {
  color: #4182be;
  border-width: 0;
}

body.blue .contextual-links-active .contextual-links-trigger {
  color: #4182be !important;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #ccc !important;
}

body.blue .contextual-links-wrapper ul li a:hover {
  background: #cfe0ef;
}

body.blue #block-blockify-blockify-actions .action-links-single-wrapper li a,
body.blue #block-blockify-blockify-actions #add-node-toggle {
  color: #005c8c !important;
}

body.blue #block-blockify-blockify-actions .action-links-single-wrapper li a:before,
body.blue #block-blockify-blockify-actions #add-node-toggle:before {
  background: #4182be;
}

body.blue #block-blockify-blockify-actions .action-links-single-wrapper li a:hover,
body.blue #block-blockify-blockify-actions #add-node-toggle:hover {
  color: #FFFFFF !important;
  background: #005c8c;
}

body.blue #block-blockify-blockify-actions .action-links-single-wrapper li a:hover:before,
body.blue #block-blockify-blockify-actions #add-node-toggle:hover:before {
  background: #005c8c;
}

body.blue #block-blockify-blockify-actions .action-links-multiple-wrapper ul {
  background: #cfe0ef;
}

body.blue #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a {
  background: #4182be;
}

body.blue #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a:hover {
  background: #005c8c;
}

body.blue .primary-tabs-header {
  color: #4182be;
}

body.blue .tabs--primary.nav-tabs > li.active > a,
body.blue .tabs--primary.nav-tabs > li.active > a:focus,
body.blue .tabs--primary.nav-tabs > li.active > a:active {
  color: #cfe0ef;
  border-color: #cfe0ef;
}

body.blue .tabs--primary.nav-tabs > li > a {
  color: #005c8c;
  border-color: #005c8c;
}

body.blue .tabs--primary.nav-tabs > li > a:hover {
  background: #4182be;
  color: white !important;
}

@media (max-width: 992px) {
  body.blue .tabs-left > .nav-tabs > li.active > a,
  body.blue .tabs-left > .nav-tabs > li.active > a:focus,
  body.blue .tabs-left > .nav-tabs > li.active > a:active {
    color: #222222;
  }
}

body.blue .slick-dots li button {
  border-color: #005c8c;
}

body.blue .slick-dots li.slick-active button {
  background: #005c8c;
}

body.blue .node h1, body.blue .node h2, body.blue .node h3, body.blue .node h4, body.blue .node h5, body.blue .node h6,
body.blue .entity-paragraphs-item h1,
body.blue .entity-paragraphs-item h2,
body.blue .entity-paragraphs-item h3,
body.blue .entity-paragraphs-item h4,
body.blue .entity-paragraphs-item h5,
body.blue .entity-paragraphs-item h6 {
  color: #005c8c;
}

body.blue .node:not(.node-teaser) h5,
body.blue .entity-paragraphs-item:not(.node-teaser) h5 {
  color: #C3C3C3;
}

body.blue .node.node-teaser h5,
body.blue .entity-paragraphs-item.node-teaser h5 {
  color: #4182be !important;
}

body.blue article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
  background: #4182be;
  background: url("../images/museo_questionmark_white.svg") no-repeat right top #4182be;
  background-size: 120px auto;
}

@media (min-width: 992px) {
  body.blue article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
    background-size: 150px auto;
  }
}

body.blue article.node-advpoll .bar {
  background: #cfe0ef;
}

body.blue article.node-advpoll .foreground {
  background: #005c8c;
}

body.blue article.node-advpoll button {
  background: #005c8c;
}

body.blue article.node-advpoll button[id^=edit-submit-show-results-], body.blue article.node-advpoll button[id^=edit-submit-advpoll-] {
  border-left-color: #4182be;
  border-right-color: #4182be;
}

body.blue article.node-advpoll:not(.node-teaser) .field-poll-question {
  color: #005c8c;
}

body.blue article.node-event .event-date-title,
body.blue article.node-event .event-date-blue-box {
  background: #4182be;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  body.blue article.node-event.node-teaser section.node-main .content .event-date-location .day-time-location {
    border-top-color: #cfe0ef;
  }
}

body.blue article.node-media-content.node-teaser .node-main > .content:not(.submission-info) {
  background: #005c8c;
}

body.blue article.node-advpoll .background-icon,
body.blue article.node-media-content .background-icon {
  color: #cfe0ef;
}

body.blue article.node-advpoll.node-teaser .contextual-links-trigger,
body.blue article.node-media-content.node-teaser .contextual-links-trigger {
  color: white;
}

body.blue article.node-advpoll.node-teaser .node-main .content:not(.submission-info),
body.blue article.node-media-content.node-teaser .node-main .content:not(.submission-info) {
  color: white;
}

body.blue article.node-advpoll.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.blue article.node-advpoll.node-teaser .poll a,
body.blue article.node-advpoll.node-teaser h2, body.blue article.node-advpoll.node-teaser h2 a, body.blue article.node-advpoll.node-teaser h5,
body.blue article.node-media-content.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.blue article.node-media-content.node-teaser .poll a,
body.blue article.node-media-content.node-teaser h2,
body.blue article.node-media-content.node-teaser h2 a,
body.blue article.node-media-content.node-teaser h5 {
  color: white !important;
}

body.blue article.node-advpoll.node-teaser a.contextual-links-trigger:hover,
body.blue article.node-media-content.node-teaser a.contextual-links-trigger:hover {
  background: #FFFFFF;
}

body.blue article.node-teaser h5 {
  color: #4182be;
}

body.blue article.node-teaser footer > .content .list-inline {
  border-color: #cfe0ef;
}

body.blue #comments .new,
body.blue .page-comment .new {
  background: #005c8c;
}

body.blue #comments .field-name-comment-body textarea,
body.blue .page-comment .field-name-comment-body textarea {
  border-color: #4182be;
}

body.blue h2.comment-form {
  color: #005c8c;
}

body.blue .block-node-fields-block h5,
body.blue .block-node-fields-block .block-title {
  color: #4182be;
}

body.blue #block-node-fields-block-3 {
  background: #4182be;
}

body.blue #block-node-fields-block-6 span.file:before {
  color: #4182be;
}

body.blue .main-container .chosen-container a.chosen-single {
  border-color: #C3C3C3 !important;
}

body.blue .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) {
  background: #4182be;
}

body.blue .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) a {
  color: #FFFFFF !important;
}

body.blue .main-container div.horizontal-tabs li.horizontal-tab-button a:hover {
  background: #005c8c;
}

body.violet #page-header .group.manager,
body.violet #page-header a,
body.violet .main-container .group.manager,
body.violet .main-container a {
  color: #7f3f98 !important;
  border-color: #7f3f98;
}

body.violet #page-header .navbar-nav.secondary li.active,
body.violet .main-container .navbar-nav.secondary li.active {
  border-color: #4b1478;
}

body.violet #page-header .pagination > .active > span,
body.violet .main-container .pagination > .active > span {
  background: #7f3f98;
  border-color: #7f3f98;
}

body.violet #page-header #navbar-secondary,
body.violet .main-container #navbar-secondary {
  color: #4b1478;
}

body.violet #page-header #navbar-secondary a,
body.violet .main-container #navbar-secondary a {
  color: #4b1478;
}

body.violet #page-header aside > .region h1, body.violet #page-header section > .region h1,
body.violet .main-container aside > .region h1,
body.violet .main-container section > .region h1 {
  color: #4b1478;
}

body.violet #page-header aside > .region h1 a, body.violet #page-header section > .region h1 a,
body.violet .main-container aside > .region h1 a,
body.violet .main-container section > .region h1 a {
  color: #4b1478;
}

body.violet #page-header aside > .region h2,
body.violet .main-container aside > .region h2 {
  color: #4b1478;
}

body.violet #page-header aside > .region h2 a,
body.violet .main-container aside > .region h2 a {
  color: #4b1478;
}

body.violet #page-header aside > .region h3,
body.violet .main-container aside > .region h3 {
  color: #4b1478;
}

body.violet #page-header aside > .region h3 a,
body.violet .main-container aside > .region h3 a {
  color: #4b1478;
}

body.violet #navbar-secondary .glyphicon:before,
body.violet #block-vbf-og-menu-vbf-og-menu-single .glyphicon:before {
  color: #4b1478;
}

body.violet #navbar-secondary a:hover, body.violet #navbar-secondary a.active,
body.violet #block-vbf-og-menu-vbf-og-menu-single a:hover,
body.violet #block-vbf-og-menu-vbf-og-menu-single a.active {
  color: #4b1478 !important;
}

body.violet #navbar-secondary a:hover .glyphicon, body.violet #navbar-secondary a.active .glyphicon,
body.violet #block-vbf-og-menu-vbf-og-menu-single a:hover .glyphicon,
body.violet #block-vbf-og-menu-vbf-og-menu-single a.active .glyphicon {
  color: #4b1478 !important;
}

body.violet .contextual-links-trigger {
  color: #7f3f98;
  border-width: 0;
}

body.violet .contextual-links-active .contextual-links-trigger {
  color: #7f3f98 !important;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #ccc !important;
}

body.violet .contextual-links-wrapper ul li a:hover {
  background: #dfcfe5;
}

body.violet #block-blockify-blockify-actions .action-links-single-wrapper li a,
body.violet #block-blockify-blockify-actions #add-node-toggle {
  color: #4b1478 !important;
}

body.violet #block-blockify-blockify-actions .action-links-single-wrapper li a:before,
body.violet #block-blockify-blockify-actions #add-node-toggle:before {
  background: #7f3f98;
}

body.violet #block-blockify-blockify-actions .action-links-single-wrapper li a:hover,
body.violet #block-blockify-blockify-actions #add-node-toggle:hover {
  color: #FFFFFF !important;
  background: #4b1478;
}

body.violet #block-blockify-blockify-actions .action-links-single-wrapper li a:hover:before,
body.violet #block-blockify-blockify-actions #add-node-toggle:hover:before {
  background: #4b1478;
}

body.violet #block-blockify-blockify-actions .action-links-multiple-wrapper ul {
  background: #dfcfe5;
}

body.violet #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a {
  background: #7f3f98;
}

body.violet #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a:hover {
  background: #4b1478;
}

body.violet .primary-tabs-header {
  color: #7f3f98;
}

body.violet .tabs--primary.nav-tabs > li.active > a,
body.violet .tabs--primary.nav-tabs > li.active > a:focus,
body.violet .tabs--primary.nav-tabs > li.active > a:active {
  color: #dfcfe5;
  border-color: #dfcfe5;
}

body.violet .tabs--primary.nav-tabs > li > a {
  color: #4b1478;
  border-color: #4b1478;
}

body.violet .tabs--primary.nav-tabs > li > a:hover {
  background: #7f3f98;
  color: white !important;
}

@media (max-width: 992px) {
  body.violet .tabs-left > .nav-tabs > li.active > a,
  body.violet .tabs-left > .nav-tabs > li.active > a:focus,
  body.violet .tabs-left > .nav-tabs > li.active > a:active {
    color: #222222;
  }
}

body.violet .slick-dots li button {
  border-color: #4b1478;
}

body.violet .slick-dots li.slick-active button {
  background: #4b1478;
}

body.violet .node h1, body.violet .node h2, body.violet .node h3, body.violet .node h4, body.violet .node h5, body.violet .node h6,
body.violet .entity-paragraphs-item h1,
body.violet .entity-paragraphs-item h2,
body.violet .entity-paragraphs-item h3,
body.violet .entity-paragraphs-item h4,
body.violet .entity-paragraphs-item h5,
body.violet .entity-paragraphs-item h6 {
  color: #4b1478;
}

body.violet .node:not(.node-teaser) h5,
body.violet .entity-paragraphs-item:not(.node-teaser) h5 {
  color: #C3C3C3;
}

body.violet .node.node-teaser h5,
body.violet .entity-paragraphs-item.node-teaser h5 {
  color: #7f3f98 !important;
}

body.violet article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
  background: #7f3f98;
  background: url("../images/museo_questionmark_white.svg") no-repeat right top #7f3f98;
  background-size: 120px auto;
}

@media (min-width: 992px) {
  body.violet article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
    background-size: 150px auto;
  }
}

body.violet article.node-advpoll .bar {
  background: #dfcfe5;
}

body.violet article.node-advpoll .foreground {
  background: #4b1478;
}

body.violet article.node-advpoll button {
  background: #4b1478;
}

body.violet article.node-advpoll button[id^=edit-submit-show-results-], body.violet article.node-advpoll button[id^=edit-submit-advpoll-] {
  border-left-color: #7f3f98;
  border-right-color: #7f3f98;
}

body.violet article.node-advpoll:not(.node-teaser) .field-poll-question {
  color: #4b1478;
}

body.violet article.node-event .event-date-title,
body.violet article.node-event .event-date-blue-box {
  background: #7f3f98;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  body.violet article.node-event.node-teaser section.node-main .content .event-date-location .day-time-location {
    border-top-color: #dfcfe5;
  }
}

body.violet article.node-media-content.node-teaser .node-main > .content:not(.submission-info) {
  background: #4b1478;
}

body.violet article.node-advpoll .background-icon,
body.violet article.node-media-content .background-icon {
  color: #dfcfe5;
}

body.violet article.node-advpoll.node-teaser .contextual-links-trigger,
body.violet article.node-media-content.node-teaser .contextual-links-trigger {
  color: white;
}

body.violet article.node-advpoll.node-teaser .node-main .content:not(.submission-info),
body.violet article.node-media-content.node-teaser .node-main .content:not(.submission-info) {
  color: white;
}

body.violet article.node-advpoll.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.violet article.node-advpoll.node-teaser .poll a,
body.violet article.node-advpoll.node-teaser h2, body.violet article.node-advpoll.node-teaser h2 a, body.violet article.node-advpoll.node-teaser h5,
body.violet article.node-media-content.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.violet article.node-media-content.node-teaser .poll a,
body.violet article.node-media-content.node-teaser h2,
body.violet article.node-media-content.node-teaser h2 a,
body.violet article.node-media-content.node-teaser h5 {
  color: white !important;
}

body.violet article.node-advpoll.node-teaser a.contextual-links-trigger:hover,
body.violet article.node-media-content.node-teaser a.contextual-links-trigger:hover {
  background: #FFFFFF;
}

body.violet article.node-teaser h5 {
  color: #7f3f98;
}

body.violet article.node-teaser footer > .content .list-inline {
  border-color: #dfcfe5;
}

body.violet #comments .new,
body.violet .page-comment .new {
  background: #4b1478;
}

body.violet #comments .field-name-comment-body textarea,
body.violet .page-comment .field-name-comment-body textarea {
  border-color: #7f3f98;
}

body.violet h2.comment-form {
  color: #4b1478;
}

body.violet .block-node-fields-block h5,
body.violet .block-node-fields-block .block-title {
  color: #7f3f98;
}

body.violet #block-node-fields-block-3 {
  background: #7f3f98;
}

body.violet #block-node-fields-block-6 span.file:before {
  color: #7f3f98;
}

body.violet .main-container .chosen-container a.chosen-single {
  border-color: #C3C3C3 !important;
}

body.violet .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) {
  background: #7f3f98;
}

body.violet .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) a {
  color: #FFFFFF !important;
}

body.violet .main-container div.horizontal-tabs li.horizontal-tab-button a:hover {
  background: #4b1478;
}

body.red #page-header .group.manager,
body.red #page-header a,
body.red .main-container .group.manager,
body.red .main-container a {
  color: #cd2d37 !important;
  border-color: #cd2d37;
}

body.red #page-header .navbar-nav.secondary li.active,
body.red .main-container .navbar-nav.secondary li.active {
  border-color: #91223c;
}

body.red #page-header .pagination > .active > span,
body.red .main-container .pagination > .active > span {
  background: #cd2d37;
  border-color: #cd2d37;
}

body.red #page-header #navbar-secondary,
body.red .main-container #navbar-secondary {
  color: #91223c;
}

body.red #page-header #navbar-secondary a,
body.red .main-container #navbar-secondary a {
  color: #91223c;
}

body.red #page-header aside > .region h1, body.red #page-header section > .region h1,
body.red .main-container aside > .region h1,
body.red .main-container section > .region h1 {
  color: #91223c;
}

body.red #page-header aside > .region h1 a, body.red #page-header section > .region h1 a,
body.red .main-container aside > .region h1 a,
body.red .main-container section > .region h1 a {
  color: #91223c;
}

body.red #page-header aside > .region h2,
body.red .main-container aside > .region h2 {
  color: #91223c;
}

body.red #page-header aside > .region h2 a,
body.red .main-container aside > .region h2 a {
  color: #91223c;
}

body.red #page-header aside > .region h3,
body.red .main-container aside > .region h3 {
  color: #91223c;
}

body.red #page-header aside > .region h3 a,
body.red .main-container aside > .region h3 a {
  color: #91223c;
}

body.red #navbar-secondary .glyphicon:before,
body.red #block-vbf-og-menu-vbf-og-menu-single .glyphicon:before {
  color: #91223c;
}

body.red #navbar-secondary a:hover, body.red #navbar-secondary a.active,
body.red #block-vbf-og-menu-vbf-og-menu-single a:hover,
body.red #block-vbf-og-menu-vbf-og-menu-single a.active {
  color: #91223c !important;
}

body.red #navbar-secondary a:hover .glyphicon, body.red #navbar-secondary a.active .glyphicon,
body.red #block-vbf-og-menu-vbf-og-menu-single a:hover .glyphicon,
body.red #block-vbf-og-menu-vbf-og-menu-single a.active .glyphicon {
  color: #91223c !important;
}

body.red .contextual-links-trigger {
  color: #cd2d37;
  border-width: 0;
}

body.red .contextual-links-active .contextual-links-trigger {
  color: #cd2d37 !important;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #ccc !important;
}

body.red .contextual-links-wrapper ul li a:hover {
  background: #f2cacd;
}

body.red #block-blockify-blockify-actions .action-links-single-wrapper li a,
body.red #block-blockify-blockify-actions #add-node-toggle {
  color: #91223c !important;
}

body.red #block-blockify-blockify-actions .action-links-single-wrapper li a:before,
body.red #block-blockify-blockify-actions #add-node-toggle:before {
  background: #cd2d37;
}

body.red #block-blockify-blockify-actions .action-links-single-wrapper li a:hover,
body.red #block-blockify-blockify-actions #add-node-toggle:hover {
  color: #FFFFFF !important;
  background: #91223c;
}

body.red #block-blockify-blockify-actions .action-links-single-wrapper li a:hover:before,
body.red #block-blockify-blockify-actions #add-node-toggle:hover:before {
  background: #91223c;
}

body.red #block-blockify-blockify-actions .action-links-multiple-wrapper ul {
  background: #f2cacd;
}

body.red #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a {
  background: #cd2d37;
}

body.red #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a:hover {
  background: #91223c;
}

body.red .primary-tabs-header {
  color: #cd2d37;
}

body.red .tabs--primary.nav-tabs > li.active > a,
body.red .tabs--primary.nav-tabs > li.active > a:focus,
body.red .tabs--primary.nav-tabs > li.active > a:active {
  color: #f2cacd;
  border-color: #f2cacd;
}

body.red .tabs--primary.nav-tabs > li > a {
  color: #91223c;
  border-color: #91223c;
}

body.red .tabs--primary.nav-tabs > li > a:hover {
  background: #cd2d37;
  color: white !important;
}

@media (max-width: 992px) {
  body.red .tabs-left > .nav-tabs > li.active > a,
  body.red .tabs-left > .nav-tabs > li.active > a:focus,
  body.red .tabs-left > .nav-tabs > li.active > a:active {
    color: #222222;
  }
}

body.red .slick-dots li button {
  border-color: #91223c;
}

body.red .slick-dots li.slick-active button {
  background: #91223c;
}

body.red .node h1, body.red .node h2, body.red .node h3, body.red .node h4, body.red .node h5, body.red .node h6,
body.red .entity-paragraphs-item h1,
body.red .entity-paragraphs-item h2,
body.red .entity-paragraphs-item h3,
body.red .entity-paragraphs-item h4,
body.red .entity-paragraphs-item h5,
body.red .entity-paragraphs-item h6 {
  color: #91223c;
}

body.red .node:not(.node-teaser) h5,
body.red .entity-paragraphs-item:not(.node-teaser) h5 {
  color: #C3C3C3;
}

body.red .node.node-teaser h5,
body.red .entity-paragraphs-item.node-teaser h5 {
  color: #cd2d37 !important;
}

body.red article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
  background: #cd2d37;
  background: url("../images/museo_questionmark_white.svg") no-repeat right top #cd2d37;
  background-size: 120px auto;
}

@media (min-width: 992px) {
  body.red article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
    background-size: 150px auto;
  }
}

body.red article.node-advpoll .bar {
  background: #f2cacd;
}

body.red article.node-advpoll .foreground {
  background: #91223c;
}

body.red article.node-advpoll button {
  background: #91223c;
}

body.red article.node-advpoll button[id^=edit-submit-show-results-], body.red article.node-advpoll button[id^=edit-submit-advpoll-] {
  border-left-color: #cd2d37;
  border-right-color: #cd2d37;
}

body.red article.node-advpoll:not(.node-teaser) .field-poll-question {
  color: #91223c;
}

body.red article.node-event .event-date-title,
body.red article.node-event .event-date-blue-box {
  background: #cd2d37;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  body.red article.node-event.node-teaser section.node-main .content .event-date-location .day-time-location {
    border-top-color: #f2cacd;
  }
}

body.red article.node-media-content.node-teaser .node-main > .content:not(.submission-info) {
  background: #91223c;
}

body.red article.node-advpoll .background-icon,
body.red article.node-media-content .background-icon {
  color: #f2cacd;
}

body.red article.node-advpoll.node-teaser .contextual-links-trigger,
body.red article.node-media-content.node-teaser .contextual-links-trigger {
  color: white;
}

body.red article.node-advpoll.node-teaser .node-main .content:not(.submission-info),
body.red article.node-media-content.node-teaser .node-main .content:not(.submission-info) {
  color: white;
}

body.red article.node-advpoll.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.red article.node-advpoll.node-teaser .poll a,
body.red article.node-advpoll.node-teaser h2, body.red article.node-advpoll.node-teaser h2 a, body.red article.node-advpoll.node-teaser h5,
body.red article.node-media-content.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.red article.node-media-content.node-teaser .poll a,
body.red article.node-media-content.node-teaser h2,
body.red article.node-media-content.node-teaser h2 a,
body.red article.node-media-content.node-teaser h5 {
  color: white !important;
}

body.red article.node-advpoll.node-teaser a.contextual-links-trigger:hover,
body.red article.node-media-content.node-teaser a.contextual-links-trigger:hover {
  background: #FFFFFF;
}

body.red article.node-teaser h5 {
  color: #cd2d37;
}

body.red article.node-teaser footer > .content .list-inline {
  border-color: #f2cacd;
}

body.red #comments .new,
body.red .page-comment .new {
  background: #91223c;
}

body.red #comments .field-name-comment-body textarea,
body.red .page-comment .field-name-comment-body textarea {
  border-color: #cd2d37;
}

body.red h2.comment-form {
  color: #91223c;
}

body.red .block-node-fields-block h5,
body.red .block-node-fields-block .block-title {
  color: #cd2d37;
}

body.red #block-node-fields-block-3 {
  background: #cd2d37;
}

body.red #block-node-fields-block-6 span.file:before {
  color: #cd2d37;
}

body.red .main-container .chosen-container a.chosen-single {
  border-color: #C3C3C3 !important;
}

body.red .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) {
  background: #cd2d37;
}

body.red .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) a {
  color: #FFFFFF !important;
}

body.red .main-container div.horizontal-tabs li.horizontal-tab-button a:hover {
  background: #91223c;
}

body.orange #page-header .group.manager,
body.orange #page-header a,
body.orange .main-container .group.manager,
body.orange .main-container a {
  color: #f15a24 !important;
  border-color: #f15a24;
}

body.orange #page-header .navbar-nav.secondary li.active,
body.orange .main-container .navbar-nav.secondary li.active {
  border-color: #bf360c;
}

body.orange #page-header .pagination > .active > span,
body.orange .main-container .pagination > .active > span {
  background: #f15a24;
  border-color: #f15a24;
}

body.orange #page-header #navbar-secondary,
body.orange .main-container #navbar-secondary {
  color: #bf360c;
}

body.orange #page-header #navbar-secondary a,
body.orange .main-container #navbar-secondary a {
  color: #bf360c;
}

body.orange #page-header aside > .region h1, body.orange #page-header section > .region h1,
body.orange .main-container aside > .region h1,
body.orange .main-container section > .region h1 {
  color: #bf360c;
}

body.orange #page-header aside > .region h1 a, body.orange #page-header section > .region h1 a,
body.orange .main-container aside > .region h1 a,
body.orange .main-container section > .region h1 a {
  color: #bf360c;
}

body.orange #page-header aside > .region h2,
body.orange .main-container aside > .region h2 {
  color: #bf360c;
}

body.orange #page-header aside > .region h2 a,
body.orange .main-container aside > .region h2 a {
  color: #bf360c;
}

body.orange #page-header aside > .region h3,
body.orange .main-container aside > .region h3 {
  color: #bf360c;
}

body.orange #page-header aside > .region h3 a,
body.orange .main-container aside > .region h3 a {
  color: #bf360c;
}

body.orange #navbar-secondary .glyphicon:before,
body.orange #block-vbf-og-menu-vbf-og-menu-single .glyphicon:before {
  color: #bf360c;
}

body.orange #navbar-secondary a:hover, body.orange #navbar-secondary a.active,
body.orange #block-vbf-og-menu-vbf-og-menu-single a:hover,
body.orange #block-vbf-og-menu-vbf-og-menu-single a.active {
  color: #bf360c !important;
}

body.orange #navbar-secondary a:hover .glyphicon, body.orange #navbar-secondary a.active .glyphicon,
body.orange #block-vbf-og-menu-vbf-og-menu-single a:hover .glyphicon,
body.orange #block-vbf-og-menu-vbf-og-menu-single a.active .glyphicon {
  color: #bf360c !important;
}

body.orange .contextual-links-trigger {
  color: #f15a24;
  border-width: 0;
}

body.orange .contextual-links-active .contextual-links-trigger {
  color: #f15a24 !important;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #ccc !important;
}

body.orange .contextual-links-wrapper ul li a:hover {
  background: #fbd6c8;
}

body.orange #block-blockify-blockify-actions .action-links-single-wrapper li a,
body.orange #block-blockify-blockify-actions #add-node-toggle {
  color: #bf360c !important;
}

body.orange #block-blockify-blockify-actions .action-links-single-wrapper li a:before,
body.orange #block-blockify-blockify-actions #add-node-toggle:before {
  background: #f15a24;
}

body.orange #block-blockify-blockify-actions .action-links-single-wrapper li a:hover,
body.orange #block-blockify-blockify-actions #add-node-toggle:hover {
  color: #FFFFFF !important;
  background: #bf360c;
}

body.orange #block-blockify-blockify-actions .action-links-single-wrapper li a:hover:before,
body.orange #block-blockify-blockify-actions #add-node-toggle:hover:before {
  background: #bf360c;
}

body.orange #block-blockify-blockify-actions .action-links-multiple-wrapper ul {
  background: #fbd6c8;
}

body.orange #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a {
  background: #f15a24;
}

body.orange #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a:hover {
  background: #bf360c;
}

body.orange .primary-tabs-header {
  color: #f15a24;
}

body.orange .tabs--primary.nav-tabs > li.active > a,
body.orange .tabs--primary.nav-tabs > li.active > a:focus,
body.orange .tabs--primary.nav-tabs > li.active > a:active {
  color: #fbd6c8;
  border-color: #fbd6c8;
}

body.orange .tabs--primary.nav-tabs > li > a {
  color: #bf360c;
  border-color: #bf360c;
}

body.orange .tabs--primary.nav-tabs > li > a:hover {
  background: #f15a24;
  color: white !important;
}

@media (max-width: 992px) {
  body.orange .tabs-left > .nav-tabs > li.active > a,
  body.orange .tabs-left > .nav-tabs > li.active > a:focus,
  body.orange .tabs-left > .nav-tabs > li.active > a:active {
    color: #222222;
  }
}

body.orange .slick-dots li button {
  border-color: #bf360c;
}

body.orange .slick-dots li.slick-active button {
  background: #bf360c;
}

body.orange .node h1, body.orange .node h2, body.orange .node h3, body.orange .node h4, body.orange .node h5, body.orange .node h6,
body.orange .entity-paragraphs-item h1,
body.orange .entity-paragraphs-item h2,
body.orange .entity-paragraphs-item h3,
body.orange .entity-paragraphs-item h4,
body.orange .entity-paragraphs-item h5,
body.orange .entity-paragraphs-item h6 {
  color: #bf360c;
}

body.orange .node:not(.node-teaser) h5,
body.orange .entity-paragraphs-item:not(.node-teaser) h5 {
  color: #C3C3C3;
}

body.orange .node.node-teaser h5,
body.orange .entity-paragraphs-item.node-teaser h5 {
  color: #f15a24 !important;
}

body.orange article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
  background: #f15a24;
  background: url("../images/museo_questionmark_white.svg") no-repeat right top #f15a24;
  background-size: 120px auto;
}

@media (min-width: 992px) {
  body.orange article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
    background-size: 150px auto;
  }
}

body.orange article.node-advpoll .bar {
  background: #fbd6c8;
}

body.orange article.node-advpoll .foreground {
  background: #bf360c;
}

body.orange article.node-advpoll button {
  background: #bf360c;
}

body.orange article.node-advpoll button[id^=edit-submit-show-results-], body.orange article.node-advpoll button[id^=edit-submit-advpoll-] {
  border-left-color: #f15a24;
  border-right-color: #f15a24;
}

body.orange article.node-advpoll:not(.node-teaser) .field-poll-question {
  color: #bf360c;
}

body.orange article.node-event .event-date-title,
body.orange article.node-event .event-date-blue-box {
  background: #f15a24;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  body.orange article.node-event.node-teaser section.node-main .content .event-date-location .day-time-location {
    border-top-color: #fbd6c8;
  }
}

body.orange article.node-media-content.node-teaser .node-main > .content:not(.submission-info) {
  background: #bf360c;
}

body.orange article.node-advpoll .background-icon,
body.orange article.node-media-content .background-icon {
  color: #fbd6c8;
}

body.orange article.node-advpoll.node-teaser .contextual-links-trigger,
body.orange article.node-media-content.node-teaser .contextual-links-trigger {
  color: white;
}

body.orange article.node-advpoll.node-teaser .node-main .content:not(.submission-info),
body.orange article.node-media-content.node-teaser .node-main .content:not(.submission-info) {
  color: white;
}

body.orange article.node-advpoll.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.orange article.node-advpoll.node-teaser .poll a,
body.orange article.node-advpoll.node-teaser h2, body.orange article.node-advpoll.node-teaser h2 a, body.orange article.node-advpoll.node-teaser h5,
body.orange article.node-media-content.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.orange article.node-media-content.node-teaser .poll a,
body.orange article.node-media-content.node-teaser h2,
body.orange article.node-media-content.node-teaser h2 a,
body.orange article.node-media-content.node-teaser h5 {
  color: white !important;
}

body.orange article.node-advpoll.node-teaser a.contextual-links-trigger:hover,
body.orange article.node-media-content.node-teaser a.contextual-links-trigger:hover {
  background: #FFFFFF;
}

body.orange article.node-teaser h5 {
  color: #f15a24;
}

body.orange article.node-teaser footer > .content .list-inline {
  border-color: #fbd6c8;
}

body.orange #comments .new,
body.orange .page-comment .new {
  background: #bf360c;
}

body.orange #comments .field-name-comment-body textarea,
body.orange .page-comment .field-name-comment-body textarea {
  border-color: #f15a24;
}

body.orange h2.comment-form {
  color: #bf360c;
}

body.orange .block-node-fields-block h5,
body.orange .block-node-fields-block .block-title {
  color: #f15a24;
}

body.orange #block-node-fields-block-3 {
  background: #f15a24;
}

body.orange #block-node-fields-block-6 span.file:before {
  color: #f15a24;
}

body.orange .main-container .chosen-container a.chosen-single {
  border-color: #C3C3C3 !important;
}

body.orange .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) {
  background: #f15a24;
}

body.orange .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) a {
  color: #FFFFFF !important;
}

body.orange .main-container div.horizontal-tabs li.horizontal-tab-button a:hover {
  background: #bf360c;
}

body.cyan #page-header .group.manager,
body.cyan #page-header a,
body.cyan .main-container .group.manager,
body.cyan .main-container a {
  color: #00bcd4 !important;
  border-color: #00bcd4;
}

body.cyan #page-header .navbar-nav.secondary li.active,
body.cyan .main-container .navbar-nav.secondary li.active {
  border-color: #0097a7;
}

body.cyan #page-header .pagination > .active > span,
body.cyan .main-container .pagination > .active > span {
  background: #00bcd4;
  border-color: #00bcd4;
}

body.cyan #page-header #navbar-secondary,
body.cyan .main-container #navbar-secondary {
  color: #0097a7;
}

body.cyan #page-header #navbar-secondary a,
body.cyan .main-container #navbar-secondary a {
  color: #0097a7;
}

body.cyan #page-header aside > .region h1, body.cyan #page-header section > .region h1,
body.cyan .main-container aside > .region h1,
body.cyan .main-container section > .region h1 {
  color: #0097a7;
}

body.cyan #page-header aside > .region h1 a, body.cyan #page-header section > .region h1 a,
body.cyan .main-container aside > .region h1 a,
body.cyan .main-container section > .region h1 a {
  color: #0097a7;
}

body.cyan #page-header aside > .region h2,
body.cyan .main-container aside > .region h2 {
  color: #0097a7;
}

body.cyan #page-header aside > .region h2 a,
body.cyan .main-container aside > .region h2 a {
  color: #0097a7;
}

body.cyan #page-header aside > .region h3,
body.cyan .main-container aside > .region h3 {
  color: #0097a7;
}

body.cyan #page-header aside > .region h3 a,
body.cyan .main-container aside > .region h3 a {
  color: #0097a7;
}

body.cyan #navbar-secondary .glyphicon:before,
body.cyan #block-vbf-og-menu-vbf-og-menu-single .glyphicon:before {
  color: #0097a7;
}

body.cyan #navbar-secondary a:hover, body.cyan #navbar-secondary a.active,
body.cyan #block-vbf-og-menu-vbf-og-menu-single a:hover,
body.cyan #block-vbf-og-menu-vbf-og-menu-single a.active {
  color: #0097a7 !important;
}

body.cyan #navbar-secondary a:hover .glyphicon, body.cyan #navbar-secondary a.active .glyphicon,
body.cyan #block-vbf-og-menu-vbf-og-menu-single a:hover .glyphicon,
body.cyan #block-vbf-og-menu-vbf-og-menu-single a.active .glyphicon {
  color: #0097a7 !important;
}

body.cyan .contextual-links-trigger {
  color: #00bcd4;
  border-width: 0;
}

body.cyan .contextual-links-active .contextual-links-trigger {
  color: #00bcd4 !important;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #ccc !important;
}

body.cyan .contextual-links-wrapper ul li a:hover {
  background: #bfeef4;
}

body.cyan #block-blockify-blockify-actions .action-links-single-wrapper li a,
body.cyan #block-blockify-blockify-actions #add-node-toggle {
  color: #0097a7 !important;
}

body.cyan #block-blockify-blockify-actions .action-links-single-wrapper li a:before,
body.cyan #block-blockify-blockify-actions #add-node-toggle:before {
  background: #00bcd4;
}

body.cyan #block-blockify-blockify-actions .action-links-single-wrapper li a:hover,
body.cyan #block-blockify-blockify-actions #add-node-toggle:hover {
  color: #FFFFFF !important;
  background: #0097a7;
}

body.cyan #block-blockify-blockify-actions .action-links-single-wrapper li a:hover:before,
body.cyan #block-blockify-blockify-actions #add-node-toggle:hover:before {
  background: #0097a7;
}

body.cyan #block-blockify-blockify-actions .action-links-multiple-wrapper ul {
  background: #bfeef4;
}

body.cyan #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a {
  background: #00bcd4;
}

body.cyan #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a:hover {
  background: #0097a7;
}

body.cyan .primary-tabs-header {
  color: #00bcd4;
}

body.cyan .tabs--primary.nav-tabs > li.active > a,
body.cyan .tabs--primary.nav-tabs > li.active > a:focus,
body.cyan .tabs--primary.nav-tabs > li.active > a:active {
  color: #bfeef4;
  border-color: #bfeef4;
}

body.cyan .tabs--primary.nav-tabs > li > a {
  color: #0097a7;
  border-color: #0097a7;
}

body.cyan .tabs--primary.nav-tabs > li > a:hover {
  background: #00bcd4;
  color: white !important;
}

@media (max-width: 992px) {
  body.cyan .tabs-left > .nav-tabs > li.active > a,
  body.cyan .tabs-left > .nav-tabs > li.active > a:focus,
  body.cyan .tabs-left > .nav-tabs > li.active > a:active {
    color: #222222;
  }
}

body.cyan .slick-dots li button {
  border-color: #0097a7;
}

body.cyan .slick-dots li.slick-active button {
  background: #0097a7;
}

body.cyan .node h1, body.cyan .node h2, body.cyan .node h3, body.cyan .node h4, body.cyan .node h5, body.cyan .node h6,
body.cyan .entity-paragraphs-item h1,
body.cyan .entity-paragraphs-item h2,
body.cyan .entity-paragraphs-item h3,
body.cyan .entity-paragraphs-item h4,
body.cyan .entity-paragraphs-item h5,
body.cyan .entity-paragraphs-item h6 {
  color: #0097a7;
}

body.cyan .node:not(.node-teaser) h5,
body.cyan .entity-paragraphs-item:not(.node-teaser) h5 {
  color: #C3C3C3;
}

body.cyan .node.node-teaser h5,
body.cyan .entity-paragraphs-item.node-teaser h5 {
  color: #00bcd4 !important;
}

body.cyan article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
  background: #00bcd4;
  background: url("../images/museo_questionmark_white.svg") no-repeat right top #00bcd4;
  background-size: 120px auto;
}

@media (min-width: 992px) {
  body.cyan article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
    background-size: 150px auto;
  }
}

body.cyan article.node-advpoll .bar {
  background: #bfeef4;
}

body.cyan article.node-advpoll .foreground {
  background: #0097a7;
}

body.cyan article.node-advpoll button {
  background: #0097a7;
}

body.cyan article.node-advpoll button[id^=edit-submit-show-results-], body.cyan article.node-advpoll button[id^=edit-submit-advpoll-] {
  border-left-color: #00bcd4;
  border-right-color: #00bcd4;
}

body.cyan article.node-advpoll:not(.node-teaser) .field-poll-question {
  color: #0097a7;
}

body.cyan article.node-event .event-date-title,
body.cyan article.node-event .event-date-blue-box {
  background: #00bcd4;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  body.cyan article.node-event.node-teaser section.node-main .content .event-date-location .day-time-location {
    border-top-color: #bfeef4;
  }
}

body.cyan article.node-media-content.node-teaser .node-main > .content:not(.submission-info) {
  background: #0097a7;
}

body.cyan article.node-advpoll .background-icon,
body.cyan article.node-media-content .background-icon {
  color: #bfeef4;
}

body.cyan article.node-advpoll.node-teaser .contextual-links-trigger,
body.cyan article.node-media-content.node-teaser .contextual-links-trigger {
  color: white;
}

body.cyan article.node-advpoll.node-teaser .node-main .content:not(.submission-info),
body.cyan article.node-media-content.node-teaser .node-main .content:not(.submission-info) {
  color: white;
}

body.cyan article.node-advpoll.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.cyan article.node-advpoll.node-teaser .poll a,
body.cyan article.node-advpoll.node-teaser h2, body.cyan article.node-advpoll.node-teaser h2 a, body.cyan article.node-advpoll.node-teaser h5,
body.cyan article.node-media-content.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.cyan article.node-media-content.node-teaser .poll a,
body.cyan article.node-media-content.node-teaser h2,
body.cyan article.node-media-content.node-teaser h2 a,
body.cyan article.node-media-content.node-teaser h5 {
  color: white !important;
}

body.cyan article.node-advpoll.node-teaser a.contextual-links-trigger:hover,
body.cyan article.node-media-content.node-teaser a.contextual-links-trigger:hover {
  background: #FFFFFF;
}

body.cyan article.node-teaser h5 {
  color: #00bcd4;
}

body.cyan article.node-teaser footer > .content .list-inline {
  border-color: #bfeef4;
}

body.cyan #comments .new,
body.cyan .page-comment .new {
  background: #0097a7;
}

body.cyan #comments .field-name-comment-body textarea,
body.cyan .page-comment .field-name-comment-body textarea {
  border-color: #00bcd4;
}

body.cyan h2.comment-form {
  color: #0097a7;
}

body.cyan .block-node-fields-block h5,
body.cyan .block-node-fields-block .block-title {
  color: #00bcd4;
}

body.cyan #block-node-fields-block-3 {
  background: #00bcd4;
}

body.cyan #block-node-fields-block-6 span.file:before {
  color: #00bcd4;
}

body.cyan .main-container .chosen-container a.chosen-single {
  border-color: #C3C3C3 !important;
}

body.cyan .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) {
  background: #00bcd4;
}

body.cyan .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) a {
  color: #FFFFFF !important;
}

body.cyan .main-container div.horizontal-tabs li.horizontal-tab-button a:hover {
  background: #0097a7;
}

body.green #page-header .group.manager,
body.green #page-header a,
body.green .main-container .group.manager,
body.green .main-container a {
  color: #6caa26 !important;
  border-color: #6caa26;
}

body.green #page-header .navbar-nav.secondary li.active,
body.green .main-container .navbar-nav.secondary li.active {
  border-color: #558511;
}

body.green #page-header .pagination > .active > span,
body.green .main-container .pagination > .active > span {
  background: #6caa26;
  border-color: #6caa26;
}

body.green #page-header #navbar-secondary,
body.green .main-container #navbar-secondary {
  color: #558511;
}

body.green #page-header #navbar-secondary a,
body.green .main-container #navbar-secondary a {
  color: #558511;
}

body.green #page-header aside > .region h1, body.green #page-header section > .region h1,
body.green .main-container aside > .region h1,
body.green .main-container section > .region h1 {
  color: #558511;
}

body.green #page-header aside > .region h1 a, body.green #page-header section > .region h1 a,
body.green .main-container aside > .region h1 a,
body.green .main-container section > .region h1 a {
  color: #558511;
}

body.green #page-header aside > .region h2,
body.green .main-container aside > .region h2 {
  color: #558511;
}

body.green #page-header aside > .region h2 a,
body.green .main-container aside > .region h2 a {
  color: #558511;
}

body.green #page-header aside > .region h3,
body.green .main-container aside > .region h3 {
  color: #558511;
}

body.green #page-header aside > .region h3 a,
body.green .main-container aside > .region h3 a {
  color: #558511;
}

body.green #navbar-secondary .glyphicon:before,
body.green #block-vbf-og-menu-vbf-og-menu-single .glyphicon:before {
  color: #558511;
}

body.green #navbar-secondary a:hover, body.green #navbar-secondary a.active,
body.green #block-vbf-og-menu-vbf-og-menu-single a:hover,
body.green #block-vbf-og-menu-vbf-og-menu-single a.active {
  color: #558511 !important;
}

body.green #navbar-secondary a:hover .glyphicon, body.green #navbar-secondary a.active .glyphicon,
body.green #block-vbf-og-menu-vbf-og-menu-single a:hover .glyphicon,
body.green #block-vbf-og-menu-vbf-og-menu-single a.active .glyphicon {
  color: #558511 !important;
}

body.green .contextual-links-trigger {
  color: #6caa26;
  border-width: 0;
}

body.green .contextual-links-active .contextual-links-trigger {
  color: #6caa26 !important;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #ccc !important;
}

body.green .contextual-links-wrapper ul li a:hover {
  background: #daeac9;
}

body.green #block-blockify-blockify-actions .action-links-single-wrapper li a,
body.green #block-blockify-blockify-actions #add-node-toggle {
  color: #558511 !important;
}

body.green #block-blockify-blockify-actions .action-links-single-wrapper li a:before,
body.green #block-blockify-blockify-actions #add-node-toggle:before {
  background: #6caa26;
}

body.green #block-blockify-blockify-actions .action-links-single-wrapper li a:hover,
body.green #block-blockify-blockify-actions #add-node-toggle:hover {
  color: #FFFFFF !important;
  background: #558511;
}

body.green #block-blockify-blockify-actions .action-links-single-wrapper li a:hover:before,
body.green #block-blockify-blockify-actions #add-node-toggle:hover:before {
  background: #558511;
}

body.green #block-blockify-blockify-actions .action-links-multiple-wrapper ul {
  background: #daeac9;
}

body.green #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a {
  background: #6caa26;
}

body.green #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a:hover {
  background: #558511;
}

body.green .primary-tabs-header {
  color: #6caa26;
}

body.green .tabs--primary.nav-tabs > li.active > a,
body.green .tabs--primary.nav-tabs > li.active > a:focus,
body.green .tabs--primary.nav-tabs > li.active > a:active {
  color: #daeac9;
  border-color: #daeac9;
}

body.green .tabs--primary.nav-tabs > li > a {
  color: #558511;
  border-color: #558511;
}

body.green .tabs--primary.nav-tabs > li > a:hover {
  background: #6caa26;
  color: white !important;
}

@media (max-width: 992px) {
  body.green .tabs-left > .nav-tabs > li.active > a,
  body.green .tabs-left > .nav-tabs > li.active > a:focus,
  body.green .tabs-left > .nav-tabs > li.active > a:active {
    color: #222222;
  }
}

body.green .slick-dots li button {
  border-color: #558511;
}

body.green .slick-dots li.slick-active button {
  background: #558511;
}

body.green .node h1, body.green .node h2, body.green .node h3, body.green .node h4, body.green .node h5, body.green .node h6,
body.green .entity-paragraphs-item h1,
body.green .entity-paragraphs-item h2,
body.green .entity-paragraphs-item h3,
body.green .entity-paragraphs-item h4,
body.green .entity-paragraphs-item h5,
body.green .entity-paragraphs-item h6 {
  color: #558511;
}

body.green .node:not(.node-teaser) h5,
body.green .entity-paragraphs-item:not(.node-teaser) h5 {
  color: #C3C3C3;
}

body.green .node.node-teaser h5,
body.green .entity-paragraphs-item.node-teaser h5 {
  color: #6caa26 !important;
}

body.green article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
  background: #6caa26;
  background: url("../images/museo_questionmark_white.svg") no-repeat right top #6caa26;
  background-size: 120px auto;
}

@media (min-width: 992px) {
  body.green article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
    background-size: 150px auto;
  }
}

body.green article.node-advpoll .bar {
  background: #daeac9;
}

body.green article.node-advpoll .foreground {
  background: #558511;
}

body.green article.node-advpoll button {
  background: #558511;
}

body.green article.node-advpoll button[id^=edit-submit-show-results-], body.green article.node-advpoll button[id^=edit-submit-advpoll-] {
  border-left-color: #6caa26;
  border-right-color: #6caa26;
}

body.green article.node-advpoll:not(.node-teaser) .field-poll-question {
  color: #558511;
}

body.green article.node-event .event-date-title,
body.green article.node-event .event-date-blue-box {
  background: #6caa26;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  body.green article.node-event.node-teaser section.node-main .content .event-date-location .day-time-location {
    border-top-color: #daeac9;
  }
}

body.green article.node-media-content.node-teaser .node-main > .content:not(.submission-info) {
  background: #558511;
}

body.green article.node-advpoll .background-icon,
body.green article.node-media-content .background-icon {
  color: #daeac9;
}

body.green article.node-advpoll.node-teaser .contextual-links-trigger,
body.green article.node-media-content.node-teaser .contextual-links-trigger {
  color: white;
}

body.green article.node-advpoll.node-teaser .node-main .content:not(.submission-info),
body.green article.node-media-content.node-teaser .node-main .content:not(.submission-info) {
  color: white;
}

body.green article.node-advpoll.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.green article.node-advpoll.node-teaser .poll a,
body.green article.node-advpoll.node-teaser h2, body.green article.node-advpoll.node-teaser h2 a, body.green article.node-advpoll.node-teaser h5,
body.green article.node-media-content.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.green article.node-media-content.node-teaser .poll a,
body.green article.node-media-content.node-teaser h2,
body.green article.node-media-content.node-teaser h2 a,
body.green article.node-media-content.node-teaser h5 {
  color: white !important;
}

body.green article.node-advpoll.node-teaser a.contextual-links-trigger:hover,
body.green article.node-media-content.node-teaser a.contextual-links-trigger:hover {
  background: #FFFFFF;
}

body.green article.node-teaser h5 {
  color: #6caa26;
}

body.green article.node-teaser footer > .content .list-inline {
  border-color: #daeac9;
}

body.green #comments .new,
body.green .page-comment .new {
  background: #558511;
}

body.green #comments .field-name-comment-body textarea,
body.green .page-comment .field-name-comment-body textarea {
  border-color: #6caa26;
}

body.green h2.comment-form {
  color: #558511;
}

body.green .block-node-fields-block h5,
body.green .block-node-fields-block .block-title {
  color: #6caa26;
}

body.green #block-node-fields-block-3 {
  background: #6caa26;
}

body.green #block-node-fields-block-6 span.file:before {
  color: #6caa26;
}

body.green .main-container .chosen-container a.chosen-single {
  border-color: #C3C3C3 !important;
}

body.green .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) {
  background: #6caa26;
}

body.green .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) a {
  color: #FFFFFF !important;
}

body.green .main-container div.horizontal-tabs li.horizontal-tab-button a:hover {
  background: #558511;
}

body.lime #page-header .group.manager,
body.lime #page-header a,
body.lime .main-container .group.manager,
body.lime .main-container a {
  color: #7ad70b !important;
  border-color: #7ad70b;
}

body.lime #page-header .navbar-nav.secondary li.active,
body.lime .main-container .navbar-nav.secondary li.active {
  border-color: #5ea609;
}

body.lime #page-header .pagination > .active > span,
body.lime .main-container .pagination > .active > span {
  background: #7ad70b;
  border-color: #7ad70b;
}

body.lime #page-header #navbar-secondary,
body.lime .main-container #navbar-secondary {
  color: #5ea609;
}

body.lime #page-header #navbar-secondary a,
body.lime .main-container #navbar-secondary a {
  color: #5ea609;
}

body.lime #page-header aside > .region h1, body.lime #page-header section > .region h1,
body.lime .main-container aside > .region h1,
body.lime .main-container section > .region h1 {
  color: #5ea609;
}

body.lime #page-header aside > .region h1 a, body.lime #page-header section > .region h1 a,
body.lime .main-container aside > .region h1 a,
body.lime .main-container section > .region h1 a {
  color: #5ea609;
}

body.lime #page-header aside > .region h2,
body.lime .main-container aside > .region h2 {
  color: #5ea609;
}

body.lime #page-header aside > .region h2 a,
body.lime .main-container aside > .region h2 a {
  color: #5ea609;
}

body.lime #page-header aside > .region h3,
body.lime .main-container aside > .region h3 {
  color: #5ea609;
}

body.lime #page-header aside > .region h3 a,
body.lime .main-container aside > .region h3 a {
  color: #5ea609;
}

body.lime #navbar-secondary .glyphicon:before,
body.lime #block-vbf-og-menu-vbf-og-menu-single .glyphicon:before {
  color: #5ea609;
}

body.lime #navbar-secondary a:hover, body.lime #navbar-secondary a.active,
body.lime #block-vbf-og-menu-vbf-og-menu-single a:hover,
body.lime #block-vbf-og-menu-vbf-og-menu-single a.active {
  color: #5ea609 !important;
}

body.lime #navbar-secondary a:hover .glyphicon, body.lime #navbar-secondary a.active .glyphicon,
body.lime #block-vbf-og-menu-vbf-og-menu-single a:hover .glyphicon,
body.lime #block-vbf-og-menu-vbf-og-menu-single a.active .glyphicon {
  color: #5ea609 !important;
}

body.lime .contextual-links-trigger {
  color: #7ad70b;
  border-width: 0;
}

body.lime .contextual-links-active .contextual-links-trigger {
  color: #7ad70b !important;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #ccc !important;
}

body.lime .contextual-links-wrapper ul li a:hover {
  background: #def5c2;
}

body.lime #block-blockify-blockify-actions .action-links-single-wrapper li a,
body.lime #block-blockify-blockify-actions #add-node-toggle {
  color: #5ea609 !important;
}

body.lime #block-blockify-blockify-actions .action-links-single-wrapper li a:before,
body.lime #block-blockify-blockify-actions #add-node-toggle:before {
  background: #7ad70b;
}

body.lime #block-blockify-blockify-actions .action-links-single-wrapper li a:hover,
body.lime #block-blockify-blockify-actions #add-node-toggle:hover {
  color: #FFFFFF !important;
  background: #5ea609;
}

body.lime #block-blockify-blockify-actions .action-links-single-wrapper li a:hover:before,
body.lime #block-blockify-blockify-actions #add-node-toggle:hover:before {
  background: #5ea609;
}

body.lime #block-blockify-blockify-actions .action-links-multiple-wrapper ul {
  background: #def5c2;
}

body.lime #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a {
  background: #7ad70b;
}

body.lime #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a:hover {
  background: #5ea609;
}

body.lime .primary-tabs-header {
  color: #7ad70b;
}

body.lime .tabs--primary.nav-tabs > li.active > a,
body.lime .tabs--primary.nav-tabs > li.active > a:focus,
body.lime .tabs--primary.nav-tabs > li.active > a:active {
  color: #def5c2;
  border-color: #def5c2;
}

body.lime .tabs--primary.nav-tabs > li > a {
  color: #5ea609;
  border-color: #5ea609;
}

body.lime .tabs--primary.nav-tabs > li > a:hover {
  background: #7ad70b;
  color: white !important;
}

@media (max-width: 992px) {
  body.lime .tabs-left > .nav-tabs > li.active > a,
  body.lime .tabs-left > .nav-tabs > li.active > a:focus,
  body.lime .tabs-left > .nav-tabs > li.active > a:active {
    color: #222222;
  }
}

body.lime .slick-dots li button {
  border-color: #5ea609;
}

body.lime .slick-dots li.slick-active button {
  background: #5ea609;
}

body.lime .node h1, body.lime .node h2, body.lime .node h3, body.lime .node h4, body.lime .node h5, body.lime .node h6,
body.lime .entity-paragraphs-item h1,
body.lime .entity-paragraphs-item h2,
body.lime .entity-paragraphs-item h3,
body.lime .entity-paragraphs-item h4,
body.lime .entity-paragraphs-item h5,
body.lime .entity-paragraphs-item h6 {
  color: #5ea609;
}

body.lime .node:not(.node-teaser) h5,
body.lime .entity-paragraphs-item:not(.node-teaser) h5 {
  color: #C3C3C3;
}

body.lime .node.node-teaser h5,
body.lime .entity-paragraphs-item.node-teaser h5 {
  color: #7ad70b !important;
}

body.lime article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
  background: #7ad70b;
  background: url("../images/museo_questionmark_white.svg") no-repeat right top #7ad70b;
  background-size: 120px auto;
}

@media (min-width: 992px) {
  body.lime article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
    background-size: 150px auto;
  }
}

body.lime article.node-advpoll .bar {
  background: #def5c2;
}

body.lime article.node-advpoll .foreground {
  background: #5ea609;
}

body.lime article.node-advpoll button {
  background: #5ea609;
}

body.lime article.node-advpoll button[id^=edit-submit-show-results-], body.lime article.node-advpoll button[id^=edit-submit-advpoll-] {
  border-left-color: #7ad70b;
  border-right-color: #7ad70b;
}

body.lime article.node-advpoll:not(.node-teaser) .field-poll-question {
  color: #5ea609;
}

body.lime article.node-event .event-date-title,
body.lime article.node-event .event-date-blue-box {
  background: #7ad70b;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  body.lime article.node-event.node-teaser section.node-main .content .event-date-location .day-time-location {
    border-top-color: #def5c2;
  }
}

body.lime article.node-media-content.node-teaser .node-main > .content:not(.submission-info) {
  background: #5ea609;
}

body.lime article.node-advpoll .background-icon,
body.lime article.node-media-content .background-icon {
  color: #def5c2;
}

body.lime article.node-advpoll.node-teaser .contextual-links-trigger,
body.lime article.node-media-content.node-teaser .contextual-links-trigger {
  color: white;
}

body.lime article.node-advpoll.node-teaser .node-main .content:not(.submission-info),
body.lime article.node-media-content.node-teaser .node-main .content:not(.submission-info) {
  color: white;
}

body.lime article.node-advpoll.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.lime article.node-advpoll.node-teaser .poll a,
body.lime article.node-advpoll.node-teaser h2, body.lime article.node-advpoll.node-teaser h2 a, body.lime article.node-advpoll.node-teaser h5,
body.lime article.node-media-content.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.lime article.node-media-content.node-teaser .poll a,
body.lime article.node-media-content.node-teaser h2,
body.lime article.node-media-content.node-teaser h2 a,
body.lime article.node-media-content.node-teaser h5 {
  color: white !important;
}

body.lime article.node-advpoll.node-teaser a.contextual-links-trigger:hover,
body.lime article.node-media-content.node-teaser a.contextual-links-trigger:hover {
  background: #FFFFFF;
}

body.lime article.node-teaser h5 {
  color: #7ad70b;
}

body.lime article.node-teaser footer > .content .list-inline {
  border-color: #def5c2;
}

body.lime #comments .new,
body.lime .page-comment .new {
  background: #5ea609;
}

body.lime #comments .field-name-comment-body textarea,
body.lime .page-comment .field-name-comment-body textarea {
  border-color: #7ad70b;
}

body.lime h2.comment-form {
  color: #5ea609;
}

body.lime .block-node-fields-block h5,
body.lime .block-node-fields-block .block-title {
  color: #7ad70b;
}

body.lime #block-node-fields-block-3 {
  background: #7ad70b;
}

body.lime #block-node-fields-block-6 span.file:before {
  color: #7ad70b;
}

body.lime .main-container .chosen-container a.chosen-single {
  border-color: #C3C3C3 !important;
}

body.lime .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) {
  background: #7ad70b;
}

body.lime .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) a {
  color: #FFFFFF !important;
}

body.lime .main-container div.horizontal-tabs li.horizontal-tab-button a:hover {
  background: #5ea609;
}

body.brown #page-header .group.manager,
body.brown #page-header a,
body.brown .main-container .group.manager,
body.brown .main-container a {
  color: #79553e !important;
  border-color: #79553e;
}

body.brown #page-header .navbar-nav.secondary li.active,
body.brown .main-container .navbar-nav.secondary li.active {
  border-color: #5d4037;
}

body.brown #page-header .pagination > .active > span,
body.brown .main-container .pagination > .active > span {
  background: #79553e;
  border-color: #79553e;
}

body.brown #page-header #navbar-secondary,
body.brown .main-container #navbar-secondary {
  color: #5d4037;
}

body.brown #page-header #navbar-secondary a,
body.brown .main-container #navbar-secondary a {
  color: #5d4037;
}

body.brown #page-header aside > .region h1, body.brown #page-header section > .region h1,
body.brown .main-container aside > .region h1,
body.brown .main-container section > .region h1 {
  color: #5d4037;
}

body.brown #page-header aside > .region h1 a, body.brown #page-header section > .region h1 a,
body.brown .main-container aside > .region h1 a,
body.brown .main-container section > .region h1 a {
  color: #5d4037;
}

body.brown #page-header aside > .region h2,
body.brown .main-container aside > .region h2 {
  color: #5d4037;
}

body.brown #page-header aside > .region h2 a,
body.brown .main-container aside > .region h2 a {
  color: #5d4037;
}

body.brown #page-header aside > .region h3,
body.brown .main-container aside > .region h3 {
  color: #5d4037;
}

body.brown #page-header aside > .region h3 a,
body.brown .main-container aside > .region h3 a {
  color: #5d4037;
}

body.brown #navbar-secondary .glyphicon:before,
body.brown #block-vbf-og-menu-vbf-og-menu-single .glyphicon:before {
  color: #5d4037;
}

body.brown #navbar-secondary a:hover, body.brown #navbar-secondary a.active,
body.brown #block-vbf-og-menu-vbf-og-menu-single a:hover,
body.brown #block-vbf-og-menu-vbf-og-menu-single a.active {
  color: #5d4037 !important;
}

body.brown #navbar-secondary a:hover .glyphicon, body.brown #navbar-secondary a.active .glyphicon,
body.brown #block-vbf-og-menu-vbf-og-menu-single a:hover .glyphicon,
body.brown #block-vbf-og-menu-vbf-og-menu-single a.active .glyphicon {
  color: #5d4037 !important;
}

body.brown .contextual-links-trigger {
  color: #79553e;
  border-width: 0;
}

body.brown .contextual-links-active .contextual-links-trigger {
  color: #79553e !important;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #ccc !important;
}

body.brown .contextual-links-wrapper ul li a:hover {
  background: #ddd4cf;
}

body.brown #block-blockify-blockify-actions .action-links-single-wrapper li a,
body.brown #block-blockify-blockify-actions #add-node-toggle {
  color: #5d4037 !important;
}

body.brown #block-blockify-blockify-actions .action-links-single-wrapper li a:before,
body.brown #block-blockify-blockify-actions #add-node-toggle:before {
  background: #79553e;
}

body.brown #block-blockify-blockify-actions .action-links-single-wrapper li a:hover,
body.brown #block-blockify-blockify-actions #add-node-toggle:hover {
  color: #FFFFFF !important;
  background: #5d4037;
}

body.brown #block-blockify-blockify-actions .action-links-single-wrapper li a:hover:before,
body.brown #block-blockify-blockify-actions #add-node-toggle:hover:before {
  background: #5d4037;
}

body.brown #block-blockify-blockify-actions .action-links-multiple-wrapper ul {
  background: #ddd4cf;
}

body.brown #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a {
  background: #79553e;
}

body.brown #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a:hover {
  background: #5d4037;
}

body.brown .primary-tabs-header {
  color: #79553e;
}

body.brown .tabs--primary.nav-tabs > li.active > a,
body.brown .tabs--primary.nav-tabs > li.active > a:focus,
body.brown .tabs--primary.nav-tabs > li.active > a:active {
  color: #ddd4cf;
  border-color: #ddd4cf;
}

body.brown .tabs--primary.nav-tabs > li > a {
  color: #5d4037;
  border-color: #5d4037;
}

body.brown .tabs--primary.nav-tabs > li > a:hover {
  background: #79553e;
  color: white !important;
}

@media (max-width: 992px) {
  body.brown .tabs-left > .nav-tabs > li.active > a,
  body.brown .tabs-left > .nav-tabs > li.active > a:focus,
  body.brown .tabs-left > .nav-tabs > li.active > a:active {
    color: #222222;
  }
}

body.brown .slick-dots li button {
  border-color: #5d4037;
}

body.brown .slick-dots li.slick-active button {
  background: #5d4037;
}

body.brown .node h1, body.brown .node h2, body.brown .node h3, body.brown .node h4, body.brown .node h5, body.brown .node h6,
body.brown .entity-paragraphs-item h1,
body.brown .entity-paragraphs-item h2,
body.brown .entity-paragraphs-item h3,
body.brown .entity-paragraphs-item h4,
body.brown .entity-paragraphs-item h5,
body.brown .entity-paragraphs-item h6 {
  color: #5d4037;
}

body.brown .node:not(.node-teaser) h5,
body.brown .entity-paragraphs-item:not(.node-teaser) h5 {
  color: #C3C3C3;
}

body.brown .node.node-teaser h5,
body.brown .entity-paragraphs-item.node-teaser h5 {
  color: #79553e !important;
}

body.brown article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
  background: #79553e;
  background: url("../images/museo_questionmark_white.svg") no-repeat right top #79553e;
  background-size: 120px auto;
}

@media (min-width: 992px) {
  body.brown article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
    background-size: 150px auto;
  }
}

body.brown article.node-advpoll .bar {
  background: #ddd4cf;
}

body.brown article.node-advpoll .foreground {
  background: #5d4037;
}

body.brown article.node-advpoll button {
  background: #5d4037;
}

body.brown article.node-advpoll button[id^=edit-submit-show-results-], body.brown article.node-advpoll button[id^=edit-submit-advpoll-] {
  border-left-color: #79553e;
  border-right-color: #79553e;
}

body.brown article.node-advpoll:not(.node-teaser) .field-poll-question {
  color: #5d4037;
}

body.brown article.node-event .event-date-title,
body.brown article.node-event .event-date-blue-box {
  background: #79553e;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  body.brown article.node-event.node-teaser section.node-main .content .event-date-location .day-time-location {
    border-top-color: #ddd4cf;
  }
}

body.brown article.node-media-content.node-teaser .node-main > .content:not(.submission-info) {
  background: #5d4037;
}

body.brown article.node-advpoll .background-icon,
body.brown article.node-media-content .background-icon {
  color: #ddd4cf;
}

body.brown article.node-advpoll.node-teaser .contextual-links-trigger,
body.brown article.node-media-content.node-teaser .contextual-links-trigger {
  color: white;
}

body.brown article.node-advpoll.node-teaser .node-main .content:not(.submission-info),
body.brown article.node-media-content.node-teaser .node-main .content:not(.submission-info) {
  color: white;
}

body.brown article.node-advpoll.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.brown article.node-advpoll.node-teaser .poll a,
body.brown article.node-advpoll.node-teaser h2, body.brown article.node-advpoll.node-teaser h2 a, body.brown article.node-advpoll.node-teaser h5,
body.brown article.node-media-content.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.brown article.node-media-content.node-teaser .poll a,
body.brown article.node-media-content.node-teaser h2,
body.brown article.node-media-content.node-teaser h2 a,
body.brown article.node-media-content.node-teaser h5 {
  color: white !important;
}

body.brown article.node-advpoll.node-teaser a.contextual-links-trigger:hover,
body.brown article.node-media-content.node-teaser a.contextual-links-trigger:hover {
  background: #FFFFFF;
}

body.brown article.node-teaser h5 {
  color: #79553e;
}

body.brown article.node-teaser footer > .content .list-inline {
  border-color: #ddd4cf;
}

body.brown #comments .new,
body.brown .page-comment .new {
  background: #5d4037;
}

body.brown #comments .field-name-comment-body textarea,
body.brown .page-comment .field-name-comment-body textarea {
  border-color: #79553e;
}

body.brown h2.comment-form {
  color: #5d4037;
}

body.brown .block-node-fields-block h5,
body.brown .block-node-fields-block .block-title {
  color: #79553e;
}

body.brown #block-node-fields-block-3 {
  background: #79553e;
}

body.brown #block-node-fields-block-6 span.file:before {
  color: #79553e;
}

body.brown .main-container .chosen-container a.chosen-single {
  border-color: #C3C3C3 !important;
}

body.brown .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) {
  background: #79553e;
}

body.brown .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) a {
  color: #FFFFFF !important;
}

body.brown .main-container div.horizontal-tabs li.horizontal-tab-button a:hover {
  background: #5d4037;
}

body.gray #page-header .group.manager,
body.gray #page-header a,
body.gray .main-container .group.manager,
body.gray .main-container a {
  color: #78849c !important;
  border-color: #78849c;
}

body.gray #page-header .navbar-nav.secondary li.active,
body.gray .main-container .navbar-nav.secondary li.active {
  border-color: #4d5e70;
}

body.gray #page-header .pagination > .active > span,
body.gray .main-container .pagination > .active > span {
  background: #78849c;
  border-color: #78849c;
}

body.gray #page-header #navbar-secondary,
body.gray .main-container #navbar-secondary {
  color: #4d5e70;
}

body.gray #page-header #navbar-secondary a,
body.gray .main-container #navbar-secondary a {
  color: #4d5e70;
}

body.gray #page-header aside > .region h1, body.gray #page-header section > .region h1,
body.gray .main-container aside > .region h1,
body.gray .main-container section > .region h1 {
  color: #4d5e70;
}

body.gray #page-header aside > .region h1 a, body.gray #page-header section > .region h1 a,
body.gray .main-container aside > .region h1 a,
body.gray .main-container section > .region h1 a {
  color: #4d5e70;
}

body.gray #page-header aside > .region h2,
body.gray .main-container aside > .region h2 {
  color: #4d5e70;
}

body.gray #page-header aside > .region h2 a,
body.gray .main-container aside > .region h2 a {
  color: #4d5e70;
}

body.gray #page-header aside > .region h3,
body.gray .main-container aside > .region h3 {
  color: #4d5e70;
}

body.gray #page-header aside > .region h3 a,
body.gray .main-container aside > .region h3 a {
  color: #4d5e70;
}

body.gray #navbar-secondary .glyphicon:before,
body.gray #block-vbf-og-menu-vbf-og-menu-single .glyphicon:before {
  color: #4d5e70;
}

body.gray #navbar-secondary a:hover, body.gray #navbar-secondary a.active,
body.gray #block-vbf-og-menu-vbf-og-menu-single a:hover,
body.gray #block-vbf-og-menu-vbf-og-menu-single a.active {
  color: #4d5e70 !important;
}

body.gray #navbar-secondary a:hover .glyphicon, body.gray #navbar-secondary a.active .glyphicon,
body.gray #block-vbf-og-menu-vbf-og-menu-single a:hover .glyphicon,
body.gray #block-vbf-og-menu-vbf-og-menu-single a.active .glyphicon {
  color: #4d5e70 !important;
}

body.gray .contextual-links-trigger {
  color: #78849c;
  border-width: 0;
}

body.gray .contextual-links-active .contextual-links-trigger {
  color: #78849c !important;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #ccc !important;
}

body.gray .contextual-links-wrapper ul li a:hover {
  background: #dde0e6;
}

body.gray #block-blockify-blockify-actions .action-links-single-wrapper li a,
body.gray #block-blockify-blockify-actions #add-node-toggle {
  color: #4d5e70 !important;
}

body.gray #block-blockify-blockify-actions .action-links-single-wrapper li a:before,
body.gray #block-blockify-blockify-actions #add-node-toggle:before {
  background: #78849c;
}

body.gray #block-blockify-blockify-actions .action-links-single-wrapper li a:hover,
body.gray #block-blockify-blockify-actions #add-node-toggle:hover {
  color: #FFFFFF !important;
  background: #4d5e70;
}

body.gray #block-blockify-blockify-actions .action-links-single-wrapper li a:hover:before,
body.gray #block-blockify-blockify-actions #add-node-toggle:hover:before {
  background: #4d5e70;
}

body.gray #block-blockify-blockify-actions .action-links-multiple-wrapper ul {
  background: #dde0e6;
}

body.gray #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a {
  background: #78849c;
}

body.gray #block-blockify-blockify-actions .action-links-multiple-wrapper ul li a:hover {
  background: #4d5e70;
}

body.gray .primary-tabs-header {
  color: #78849c;
}

body.gray .tabs--primary.nav-tabs > li.active > a,
body.gray .tabs--primary.nav-tabs > li.active > a:focus,
body.gray .tabs--primary.nav-tabs > li.active > a:active {
  color: #dde0e6;
  border-color: #dde0e6;
}

body.gray .tabs--primary.nav-tabs > li > a {
  color: #4d5e70;
  border-color: #4d5e70;
}

body.gray .tabs--primary.nav-tabs > li > a:hover {
  background: #78849c;
  color: white !important;
}

@media (max-width: 992px) {
  body.gray .tabs-left > .nav-tabs > li.active > a,
  body.gray .tabs-left > .nav-tabs > li.active > a:focus,
  body.gray .tabs-left > .nav-tabs > li.active > a:active {
    color: #222222;
  }
}

body.gray .slick-dots li button {
  border-color: #4d5e70;
}

body.gray .slick-dots li.slick-active button {
  background: #4d5e70;
}

body.gray .node h1, body.gray .node h2, body.gray .node h3, body.gray .node h4, body.gray .node h5, body.gray .node h6,
body.gray .entity-paragraphs-item h1,
body.gray .entity-paragraphs-item h2,
body.gray .entity-paragraphs-item h3,
body.gray .entity-paragraphs-item h4,
body.gray .entity-paragraphs-item h5,
body.gray .entity-paragraphs-item h6 {
  color: #4d5e70;
}

body.gray .node:not(.node-teaser) h5,
body.gray .entity-paragraphs-item:not(.node-teaser) h5 {
  color: #C3C3C3;
}

body.gray .node.node-teaser h5,
body.gray .entity-paragraphs-item.node-teaser h5 {
  color: #78849c !important;
}

body.gray article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
  background: #78849c;
  background: url("../images/museo_questionmark_white.svg") no-repeat right top #78849c;
  background-size: 120px auto;
}

@media (min-width: 992px) {
  body.gray article.node-advpoll.node-teaser .node-main > .content:not(.submission-info) {
    background-size: 150px auto;
  }
}

body.gray article.node-advpoll .bar {
  background: #dde0e6;
}

body.gray article.node-advpoll .foreground {
  background: #4d5e70;
}

body.gray article.node-advpoll button {
  background: #4d5e70;
}

body.gray article.node-advpoll button[id^=edit-submit-show-results-], body.gray article.node-advpoll button[id^=edit-submit-advpoll-] {
  border-left-color: #78849c;
  border-right-color: #78849c;
}

body.gray article.node-advpoll:not(.node-teaser) .field-poll-question {
  color: #4d5e70;
}

body.gray article.node-event .event-date-title,
body.gray article.node-event .event-date-blue-box {
  background: #78849c;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  body.gray article.node-event.node-teaser section.node-main .content .event-date-location .day-time-location {
    border-top-color: #dde0e6;
  }
}

body.gray article.node-media-content.node-teaser .node-main > .content:not(.submission-info) {
  background: #4d5e70;
}

body.gray article.node-advpoll .background-icon,
body.gray article.node-media-content .background-icon {
  color: #dde0e6;
}

body.gray article.node-advpoll.node-teaser .contextual-links-trigger,
body.gray article.node-media-content.node-teaser .contextual-links-trigger {
  color: white;
}

body.gray article.node-advpoll.node-teaser .node-main .content:not(.submission-info),
body.gray article.node-media-content.node-teaser .node-main .content:not(.submission-info) {
  color: white;
}

body.gray article.node-advpoll.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.gray article.node-advpoll.node-teaser .poll a,
body.gray article.node-advpoll.node-teaser h2, body.gray article.node-advpoll.node-teaser h2 a, body.gray article.node-advpoll.node-teaser h5,
body.gray article.node-media-content.node-teaser a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
body.gray article.node-media-content.node-teaser .poll a,
body.gray article.node-media-content.node-teaser h2,
body.gray article.node-media-content.node-teaser h2 a,
body.gray article.node-media-content.node-teaser h5 {
  color: white !important;
}

body.gray article.node-advpoll.node-teaser a.contextual-links-trigger:hover,
body.gray article.node-media-content.node-teaser a.contextual-links-trigger:hover {
  background: #FFFFFF;
}

body.gray article.node-teaser h5 {
  color: #78849c;
}

body.gray article.node-teaser footer > .content .list-inline {
  border-color: #dde0e6;
}

body.gray #comments .new,
body.gray .page-comment .new {
  background: #4d5e70;
}

body.gray #comments .field-name-comment-body textarea,
body.gray .page-comment .field-name-comment-body textarea {
  border-color: #78849c;
}

body.gray h2.comment-form {
  color: #4d5e70;
}

body.gray .block-node-fields-block h5,
body.gray .block-node-fields-block .block-title {
  color: #78849c;
}

body.gray #block-node-fields-block-3 {
  background: #78849c;
}

body.gray #block-node-fields-block-6 span.file:before {
  color: #78849c;
}

body.gray .main-container .chosen-container a.chosen-single {
  border-color: #C3C3C3 !important;
}

body.gray .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) {
  background: #78849c;
}

body.gray .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) a {
  color: #FFFFFF !important;
}

body.gray .main-container div.horizontal-tabs li.horizontal-tab-button a:hover {
  background: #4d5e70;
}

a.node_event_blue_box_link:hover {
  text-decoration: none;
}

#block-vbf-community-vbf-community-comments {
  margin-top: 3rem;
  float: left;
  width: 100%;
}

#comments .indented,
.page-comment .indented {
  margin-left: 5.41176rem;
}

@media (max-width: 768px) {
  #comments .indented,
  .page-comment .indented {
    margin-left: 1.17647rem;
  }
}

#comments .indented .indented,
.page-comment .indented .indented {
  margin-left: 0;
}

#comments h2.title:not(.comment-form),
.page-comment h2.title:not(.comment-form) {
  display: none;
}

#comments h2.comment-form,
.page-comment h2.comment-form {
  margin-bottom: 2.25rem;
}

#comments form,
.page-comment form {
  float: left;
  width: 100%;
  border-bottom: 1px solid #C3C3C3;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}

#comments form .field-type-text-long label,
.page-comment form .field-type-text-long label {
  display: none;
}

#comments form .grippie,
.page-comment form .grippie {
  display: none;
}

#comments form .form-textarea,
.page-comment form .form-textarea {
  box-shadow: 1px 1px 4px -2px rgba(0, 0, 0, 0.5);
  padding: 15px;
  resize: none;
  line-height: 1.2;
}

#comments > .comment,
.page-comment > .comment {
  float: left;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

#comments > .comment .comment,
.page-comment > .comment .comment {
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

#comments .comment,
.page-comment .comment {
  color: #555555;
  position: relative;
}

#comments .comment .new,
.page-comment .comment .new {
  font-size: 0.70588rem;
  font-weight: 300;
  background: #062352;
  padding-left: 0.35294rem;
  padding-right: 0.47059rem;
}

#comments .comment .new:before, #comments .comment .new:after,
.page-comment .comment .new:before,
.page-comment .comment .new:after {
  color: inherit;
  font-size: 0.70588rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

#comments .comment .new:before,
.page-comment .comment .new:before {
  content: " ";
}

#comments .comment .new:before,
.page-comment .comment .new:before {
  position: relative;
  top: 0.11765rem;
}

#comments .comment .field-profile-image,
.page-comment .comment .field-profile-image {
  float: left;
  width: 3.64706rem;
}

#comments .comment .comment-content,
.page-comment .comment .comment-content {
  margin-left: 5.41176rem;
}

#comments .comment .submitted,
.page-comment .comment .submitted {
  font-size: 1.17647rem;
  margin-bottom: 0.375rem;
}

#comments .comment .field-comment-body,
.page-comment .comment .field-comment-body {
  margin-bottom: 0.4995rem;
}

#comments .comment .list-inline,
.page-comment .comment .list-inline {
  margin-right: 30px;
  display: inline-block;
  margin-left: 0;
  text-transform: uppercase;
  font-size: 0.70588rem;
  white-space: nowrap;
}

#comments .comment .rate-widget,
.page-comment .comment .rate-widget {
  margin-right: 30px;
  display: inline-block;
  padding: 0;
  background: transparent;
  white-space: nowrap;
}

#comments .comment .rate-widget .rate-button,
.page-comment .comment .rate-widget .rate-button {
  margin-right: 0.29412rem;
  top: 0;
  line-height: 0.70588rem;
}

#comments .comment .rate-widget .rate-button,
#comments .comment .rate-widget .rate-info,
.page-comment .comment .rate-widget .rate-button,
.page-comment .comment .rate-widget .rate-info {
  font-size: 0.70588rem;
  line-height: 0.70588rem;
}

#comments .comment .date,
.page-comment .comment .date {
  font-size: 0.70588rem;
  white-space: nowrap;
}

.field-profile-image img,
.field-sc-profile-image img {
  border-radius: 50%;
}

#block-vbf-community-vbf-community-user-header {
  position: relative;
  padding-top: 3.52941rem;
}

@media (max-width: 768px) {
  #block-vbf-community-vbf-community-user-header {
    display: flex;
  }
}

@media (max-width: 479px) {
  #block-vbf-community-vbf-community-user-header {
    padding: 0.23529rem 0px 0.47059rem 0px;
  }
}

#block-vbf-community-vbf-community-user-header .user-info {
  display: flex;
  flex-flow: column;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  #block-vbf-community-vbf-community-user-header .user-info {
    margin-right: 12px;
  }
}

#block-vbf-community-vbf-community-user-header h1 {
  color: #005c8c;
  font-weight: 500;
  margin-bottom: 0rem;
}

@media only screen and (min-width: 992px) {
  #block-vbf-community-vbf-community-user-header h1 {
    margin-bottom: 0.75rem;
  }
}

#block-vbf-community-vbf-community-user-header .avatar img {
  -webkit-box-shadow: 0 -4px 4px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -4px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border: 4px solid white;
  width: 7.05882rem;
}

@media (max-width: 768px) {
  #block-vbf-community-vbf-community-user-header .avatar img {
    border-width: 2px;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  #block-vbf-community-vbf-community-user-header .avatar {
    position: absolute;
    bottom: -1rem;
    right: 0;
    height: 100%;
  }
  #block-vbf-community-vbf-community-user-header .avatar img {
    width: 10.58824rem;
  }
}

.page-user .main-container-wrapper,
.page-user-me .main-container-wrapper {
  background: #F2F0F0;
}

.page-user .main-container,
.page-user-me .main-container {
  padding-top: 2.25rem;
  padding-bottom: 3.75rem;
}

@media (min-width: 768px) {
  .page-user .main-container,
  .page-user-me .main-container {
    padding-top: 6.75rem;
    padding-bottom: 6.75rem;
  }
}

@media (min-width: 992px) {
  .page-user .main-container > .row > section,
  .page-user-me .main-container > .row > section {
    float: right !important;
  }
}

.page-user .profile h2,
.page-user-me .profile h2 {
  color: #005c8c;
  margin-bottom: 1.5rem;
}

.page-user .profile dl,
.page-user-me .profile dl {
  margin-bottom: 0;
}

.page-user.not-logged-in .tabs--primary {
  margin-bottom: 0;
}

.page-user.not-logged-in .tabs--primary li a {
  border-color: transparent;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.page-user.not-logged-in .tabs--primary li a.active {
  background: #F2F0F0;
  border-color: #F2F0F0;
}

.page-user.not-logged-in .main-container form {
  background: #F2F0F0;
}

.page-user.not-logged-in .main-container form .form-fields-wrapper {
  background: transparent;
  padding: 0;
}

.page-user.not-logged-in .main-container form > div {
  padding: 30px;
}

.page-user.not-logged-in .main-container form > div .form-item-pass {
  margin-bottom: 0;
}

.page-user.not-logged-in .main-container form > div .form-actions {
  margin-top: 3rem;
}

@media (min-width: 992px) {
  #block-vbf-og-vbf-og-user-memberships {
    margin-bottom: 80px;
  }
}

#block-vbf-og-vbf-og-user-memberships .block-title {
  font-weight: 400;
}

#block-vbf-og-vbf-og-user-memberships .block-title:after {
  display: block;
  content: "";
  border-bottom: 1px solid #C3C3C3;
  margin-top: 14px;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  #block-vbf-og-vbf-og-user-memberships .block-title:after {
    margin-top: 34px;
    margin-bottom: 5px;
  }
}

.region.region-sidebar-second > #block-vbf-og-vbf-og-user-memberships:first-child h2 {
  margin-top: 0;
  padding-top: 0;
}

.page-user .region-content-wrapper {
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .page-user-me .region-content-wrapper {
    max-width: 100%;
  }
}

.page-user-me .main-container {
  padding-top: 2rem;
}

#header + .main-container-wrapper {
  padding-top: 24px;
  margin-top: -5rem;
}

@media (min-width: 992px) {
  #header + .main-container-wrapper {
    margin-top: 0;
    padding-top: 63px;
  }
}

#header + .main-container-wrapper > .main-container {
  margin-top: -1.2rem;
}

#profile2 .profile img {
  display: none;
}

.block-system > .profile #profile2 {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  max-width: 890px;
}

.block-system > .profile #profile2 .profile {
  float: left;
}

.block-system > .profile #profile2 .profile img {
  border-radius: 50%;
  width: 140px;
  height: auto;
  max-width: none;
  display: block;
}

.block-system > .profile #profile2 .content {
  margin-left: 6.17647rem;
}

body.page-welcome #block-blockify-blockify-page-title {
  display: none;
}

.community_register_step1__form, .community_register_step2__form {
  background-color: #F2F0F0;
  padding: 40px;
}

.community_register_step1__form .field-name-field-profile-image, .community_register_step2__form .field-name-field-profile-image,
.community_register_step1__form .help-block,
.community_register_step2__form .help-block {
  display: none;
}

.community_register_step1__form .form-actions, .community_register_step2__form .form-actions {
  float: none;
}

.community_register_step1__form .btn.form-submit, .community_register_step2__form .btn.form-submit {
  width: 100%;
  background-color: #062352;
}

.community_register_step1__form .btn.form-submit:hover, .community_register_step2__form .btn.form-submit:hover {
  background-color: #005c8c;
}

@media (max-width: 768px) {
  .community_register_step1__headline h1[data-hasmobile] {
    display: none;
  }
}

.community_register_step1__headline .mobileh1 {
  display: none;
}

@media (max-width: 768px) {
  .community_register_step1__headline .mobileh1 {
    display: block;
    font-size: 3rem;
    color: #275f91;
    margin-bottom: 3.75rem;
    font-weight: 300;
  }
}

.community_register_step1__desc {
  padding: 40px;
}

.community_register_step2__form div.horizontal-tabs ul.horizontal-tabs-list {
  display: none;
}

.community_register_step2__form .has-feedback label ~ .form-control-feedback {
  display: none;
}

.community_register_step2__form .form-item-pass-pass1 {
  margin-bottom: 0;
}

.community_register_step2__form div.horizontal-tabs .horizontal-tabs-panes {
  background-color: transparent;
  border: none;
  padding: 0;
}

.community_register_step2__form div.horizontal-tabs fieldset.horizontal-tabs-pane {
  padding: 0;
}

.community_register_step2__desc {
  padding: 40px;
}

.community_register_step3 {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .community_register_step3 {
    margin-bottom: 80px;
  }
}

@media (min-width: 992px) {
  #block-vbf-community-register-vbf-register-step3-welcome {
    background-image: url("../images/Swirl_white25.svg");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

.community_register_step3__desc {
  float: left;
  width: calc(100% - 130px);
}

@media (min-width: 992px) {
  .community_register_step3__desc {
    width: 50%;
  }
}

.community_register_step3 + .community_register_step3 {
  margin-top: 25px;
}

@media (min-width: 992px) {
  .community_register_step3 + .community_register_step3 {
    margin-top: 0;
    display: flex;
    align-items: center;
  }
}

.community_register_step3__image {
  width: calc(100px + 30px);
  height: 100px;
  float: left;
}

@media (min-width: 992px) {
  .community_register_step3__image {
    display: block;
    width: 165px;
    height: 135px;
  }
}

.community_register_step3__image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.community_register_step1_iconbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

@media (min-width: 992px) {
  .community_register_step1_iconbar {
    margin-left: 12.5%;
    margin-top: 85px;
  }
}

.community_register_step1_iconbar span {
  width: 96px;
  height: 2px;
  background-color: #005c8c;
}

.community_register_step1_iconbar img {
  max-width: 28%;
}

@media (min-width: 992px) {
  .community_register_step1_iconbar img {
    max-width: 33%;
  }
}

.community_register_step2_iconbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

@media (min-width: 992px) {
  .community_register_step2_iconbar {
    margin-left: 25%;
    margin-top: 85px;
  }
}

.community_register_step2_iconbar span {
  width: 96px;
  height: 2px;
  background-color: #005c8c;
}

.community_register_step2_iconbar img {
  max-width: 28%;
}

@media (min-width: 992px) {
  .community_register_step2_iconbar img {
    max-width: 33%;
  }
}

body.page-user-register-thx .messages.status {
  display: none;
}

[data-target="#modalRegister"] {
  position: relative;
}

li[data-target="#modalNewsletter"] > a {
  padding: 1.33333rem 0 0.5rem 15px;
  text-transform: uppercase;
}

.modalRegisterComment2,
.modalRegisterComment,
.modalRegisterNew,
#page-header #modalRegister {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  display: none;
}

.modalRegisterComment2.open,
.modalRegisterComment.open,
.modalRegisterNew.open,
#page-header #modalRegister.open {
  display: block;
}

.modalRegisterComment2 ul,
.modalRegisterComment ul,
.modalRegisterNew ul,
#page-header #modalRegister ul {
  padding: 0 15px 12px 15px;
}

.modalRegisterComment2 li,
.modalRegisterComment li,
.modalRegisterNew li,
#page-header #modalRegister li {
  background-color: #4182be;
}

.modalRegisterComment2 li a,
.modalRegisterComment li a,
.modalRegisterNew li a,
#page-header #modalRegister li a {
  color: #FFFFFF !important;
}

body.blue .modalRegisterComment2 li a,
body.violet .modalRegisterComment2 li a, body.blue
.modalRegisterComment li a,
body.violet
.modalRegisterComment li a, body.blue
.modalRegisterNew li a,
body.violet
.modalRegisterNew li a, body.blue
#page-header #modalRegister li a,
body.violet
#page-header #modalRegister li a {
  color: #FFFFFF !important;
}

.modalRegisterComment2 li:hover a,
.modalRegisterComment li:hover a,
.modalRegisterNew li:hover a,
#page-header #modalRegister li:hover a {
  text-decoration: underline;
}

.modalRegisterComment2 li.head,
.modalRegisterComment li.head,
.modalRegisterNew li.head,
#page-header #modalRegister li.head {
  background-color: #062352;
}

.modalRegisterComment2 li.head span,
.modalRegisterComment li.head span,
.modalRegisterNew li.head span,
#page-header #modalRegister li.head span {
  padding: 15px 15px 15px 0;
  font-size: 1rem;
  line-height: 1.2;
  display: block;
  text-shadow: none;
}

.modalRegisterComment2 li + li,
.modalRegisterComment li + li,
.modalRegisterNew li + li,
#page-header #modalRegister li + li {
  margin-top: 10px;
}

.modalRegisterComment2 {
  max-width: 300px;
  left: auto;
  right: 0;
}

.modalregister__container {
  position: relative;
}

.form-item-accept-netiquette .form-required {
  display: none;
}

#block-vbf-community-register-vbf-register-cancel .form-actions a {
  margin-top: 20px;
  display: block;
}

#block-vbf-community-register-vbf-register-login .community_register_step1__form, #block-vbf-community-register-vbf-register-login .community_register_step2__form {
  margin-bottom: 70px;
}

@media (min-width: 992px) {
  #block-vbf-community-register-vbf-register-login .community_register_step1__form:after, #block-vbf-community-register-vbf-register-login .community_register_step2__form:after {
    display: block;
    content: "";
    width: 500px;
    height: 400px;
    position: absolute;
    right: -310px;
    top: 10px;
    background-image: url("../images/Swirl_white25.svg");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

.comment_anonymous {
  margin-bottom: 26px;
}

#footer-prefix {
  background: #005c8c;
  color: #FFFFFF;
}

#footer-prefix .region-footer-prefix {
  position: relative;
  overflow: hidden;
}

#footer-prefix .region-footer-prefix:after {
  content: "";
  display: block;
  position: absolute;
  background-image: url("../images/Swirl_white25.svg");
  background-position: 128% 10%;
  background-repeat: no-repeat;
  background-size: auto 250%;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}

#footer-prefix #block-vbf-community-vbf-contact-block {
  display: grid;
  grid-template-rows: 15%;
  padding-top: 4%;
  padding-bottom: 4%;
  position: relative;
  z-index: 2;
}

#footer-prefix #block-vbf-community-vbf-contact-block .block-title {
  grid-row: 1;
}

#footer-prefix #block-vbf-community-vbf-contact-block .contact-form-intro {
  grid-row: 2;
  padding-right: 13px;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form {
  grid-row: 1/4;
  padding-top: 20px;
  display: grid;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div {
  display: grid;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-item-mail {
  grid-row: 1;
  grid-column: 2;
  margin-left: 5%;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-item-feed-me {
  display: none;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-item.form-item-message {
  grid-row: 3;
  grid-column: 1/3;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-actions {
  grid-row: 5;
  grid-column: 1/3;
  background: #062352;
  text-align: center;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-actions .form-submit {
  width: 100%;
  background: #062352;
  cursor: pointer !important;
  transition: all 0.5s ease;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-actions .form-submit:hover {
  background: #082d6a;
  box-shadow: 0px 0px 4px -2px rgba(6, 35, 82, 0.5);
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-item-data-protection.form-type-checkbox.checkbox, #footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div #sliding-popup .form-item-data-protection.checkbox.eu-cookie-compliance-category, #sliding-popup #footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-item-data-protection.checkbox.eu-cookie-compliance-category {
  grid-row: 4;
  grid-column: 1/3;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-item-data-protection.form-type-checkbox.checkbox label, #footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div #sliding-popup .form-item-data-protection.checkbox.eu-cookie-compliance-category label, #sliding-popup #footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-item-data-protection.checkbox.eu-cookie-compliance-category label {
  color: #FFFFFF;
  font-weight: 500;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-item-data-protection.form-type-checkbox.checkbox label a, #footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div #sliding-popup .form-item-data-protection.checkbox.eu-cookie-compliance-category label a, #sliding-popup #footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-item-data-protection.checkbox.eu-cookie-compliance-category label a {
  color: #FFFFFF;
  text-decoration: underline;
}

#footer-prefix #block-vbf-community-vbf-contact-block #contact-site-form > div .form-item.form-item-subject.form-type-textfield.form-group {
  grid-row: 2;
  grid-column: 1/3;
}

@media (max-width: 667px) {
  #contact-site-form {
    grid-row: 3 !important;
  }
  #footer-prefix {
    margin-top: 25px;
  }
}

.view-hero-teaser {
  display: flex;
  width: 100%;
  flex-flow: column;
}

@media (min-width: 992px) {
  .view-hero-teaser {
    flex-flow: row;
  }
}

.view-hero-teaser .view-content {
  background: #F2F0F0;
  display: flex;
  max-width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 992px) {
  .view-hero-teaser .view-content {
    flex: 0 0 66%;
    max-width: 66%;
  }
}

.view-hero-teaser .view-content .hero-slider,
.view-hero-teaser .view-content .views-row {
  width: 100%;
}

.view-hero-teaser .hero-headlines {
  max-width: 100%;
  order: 2;
}

@media (min-width: 992px) {
  .view-hero-teaser .hero-headlines {
    flex: 0 1 34%;
    max-width: 34%;
    padding-left: 30px;
  }
}

.view-hero-teaser .hero-headlines .view-hero-teaser {
  flex-flow: column;
  height: 100%;
}

.view-hero-teaser .hero-headlines .views-row {
  margin-bottom: 0.94118rem;
  width: 100%;
  flex: 1;
}

@media (min-width: 992px) {
  .view-hero-teaser .hero-headlines .views-row {
    margin-bottom: 30px;
  }
}

@media (max-width: 992px) {
  .view-hero-teaser .hero-headlines .views-row-first {
    margin-top: 1rem;
  }
}

.view-hero-teaser .hero-headlines .views-row-last {
  margin-bottom: 0;
}

.view-hero-teaser .slick-prev,
.view-hero-teaser .slick-next {
  top: auto;
  bottom: -24px;
  z-index: 2;
}

.view-hero-teaser .slick-prev::before, .view-hero-teaser .slick-prev::after,
.view-hero-teaser .slick-next::before,
.view-hero-teaser .slick-next::after {
  color: #005c8c;
  font-size: 22px;
}

@media (min-width: 768px) {
  .view-hero-teaser .slick-prev::before, .view-hero-teaser .slick-prev::after,
  .view-hero-teaser .slick-next::before,
  .view-hero-teaser .slick-next::after {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .view-hero-teaser .slick-prev,
  .view-hero-teaser .slick-next {
    top: auto;
    bottom: -18px;
  }
}

@media (min-width: 992px) {
  .view-hero-teaser .slick-prev,
  .view-hero-teaser .slick-next {
    bottom: -9px;
  }
}

.view-hero-teaser .slick-prev {
  left: auto;
  left: 0;
}

.view-hero-teaser .slick-next {
  right: 0.5rem;
}

.hero-slider .slick-prev {
  display: none !important;
}

.node-hero-content.node-full {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  overflow: hidden;
}

.node-hero-content.node-full .field-image {
  height: 200px;
}

@media (min-width: 768px) {
  .node-hero-content.node-full .field-image {
    height: 400px;
  }
}

@media (min-width: 992px) {
  .node-hero-content.node-full .field-image {
    height: 450px;
  }
}

.node-hero-content.node-full .field-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.node-hero-content.node-full .field-image .field-item,
.node-hero-content.node-full .field-image .img-responsive {
  height: 100%;
}

.node-hero-content.node-full .detail {
  z-index: 1;
  padding: 1.17647rem 1.47059rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
}

@media (min-width: 480px) {
  .node-hero-content.node-full .detail {
    bottom: 40px;
  }
}

@media (min-width: 768px) {
  .node-hero-content.node-full .detail {
    background-color: #e4eef3;
    position: absolute;
    bottom: -5px;
    padding: 1.64706rem 0.88235rem;
  }
}

@media (min-width: 992px) {
  .node-hero-content.node-full .detail {
    padding: 1.17647rem 0.88235rem;
  }
}

.node-hero-content.node-full h2 {
  margin: 0;
  font-weight: 700;
  font-size: 1.23529rem;
}

@media (max-width: 992px) {
  .node-hero-content.node-full h2 {
    font-size: 1.23529rem;
    font-weight: 600;
  }
}

@media (max-width: 480px) {
  .node-hero-content.node-full h2 {
    font-size: 1.05882rem;
    font-weight: bold;
  }
}

.node-hero-content.node-full .field-subtitle .field-item {
  display: none;
  font-size: 1rem;
  font-weight: 500;
}

@media (min-width: 992px) {
  .node-hero-content.node-full .field-subtitle .field-item {
    display: block;
  }
}

.node-hero-content.node-full h2,
.node-hero-content.node-full h2 a,
.node-hero-content.node-full .field-item {
  color: #4182be;
}

@media (min-width: 768px) {
  .node-hero-content.node-full h2,
  .node-hero-content.node-full h2 a,
  .node-hero-content.node-full .field-item {
    color: #005c8c;
  }
}

.node-hero-content.node-teaser {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.node-hero-content.node-teaser > a {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  height: 100%;
}

.node-hero-content.node-teaser .field-image-container {
  flex: 0 0 52%;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .node-hero-content.node-teaser .field-image-container {
    flex: 0 0 45%;
  }
}

@media (min-width: 480px) {
  .node-hero-content.node-teaser .field-image-container {
    margin-right: 20px;
    height: 100%;
  }
}

.node-hero-content.node-teaser .field-image {
  overflow: hidden;
  padding-top: calc(9 / 16 * 100%);
  background: #eee;
  position: relative;
  height: 100%;
  max-height: 130px;
}

.node-hero-content.node-teaser .field-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.node-hero-content.node-teaser .field-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: none;
}

.node-hero-content.node-teaser h2 {
  margin: 0;
  font-size: 1rem;
  line-height: 1.17647rem;
  font-weight: 700;
}

@media (max-width: 992px) {
  .node-hero-content.node-teaser h2 {
    font-size: 1.76471rem;
    font-weight: 600;
    line-height: 1;
  }
}

@media (max-width: 480px) {
  .node-hero-content.node-teaser h2 {
    font-size: 1.05882rem;
  }
}

#block-views-hero-teaser-block-hero .view-content {
  max-height: 450px;
}

#block-vbf-community-vbf-community-stats .block-title {
  text-align: center;
  font-size: 2.64706rem;
  color: #4182be;
  font-weight: 500;
  margin-bottom: 3rem;
}

#block-vbf-community-vbf-community-stats p.intro {
  font-size: 1.76471rem;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.25;
  margin-bottom: 4.5rem;
}

#block-vbf-community-vbf-community-stats .community-stats {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: 7.5rem;
}

#block-vbf-community-vbf-community-stats .community-stats li {
  display: flex;
  flex-flow: column;
  align-content: center;
  justify-content: center;
  background: #005c8c;
  text-align: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 1.17647rem;
  border-radius: 100%;
  line-height: 1;
  width: 180px;
  height: 180px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

#block-vbf-community-vbf-community-stats .community-stats li:before {
  margin-bottom: 1rem;
  width: 40%;
}

#block-vbf-community-vbf-community-stats .community-stats .community-no:before {
  content: url("../images/001-social-care.svg");
}

#block-vbf-community-vbf-community-stats .community-stats .member-no:before {
  content: url("../images/002-team.svg");
}

#block-vbf-community-vbf-community-stats .community-stats .post-no:before {
  content: url("../images/003-advertising.svg");
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-community-stats .community-stats li {
    margin-bottom: 0;
    width: 262px;
    height: 262px;
  }
}

#block-vbf-community-vbf-landing-page-links {
  position: relative;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-landing-page-links {
    min-height: 350px;
    margin-top: 4.11765rem;
  }
}

#block-vbf-community-vbf-landing-page-links:before {
  content: url("../images/Swirl_white25.svg");
  position: absolute;
  bottom: 0;
  height: 100%;
  right: 0%;
  width: 49.17647rem;
  z-index: -1;
  height: auto;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-landing-page-links:before {
    top: -6.41176rem;
    right: 7%;
    bottom: auto;
  }
}

@media (min-width: 1200px) {
  #block-vbf-community-vbf-landing-page-links:before {
    right: 18%;
  }
}

#block-vbf-community-vbf-landing-page-links .nav li a {
  display: flex;
  color: #296192;
  font-weight: 700;
  line-height: 1.294;
  font-size: 1rem;
  align-items: center;
  transition: all 0.5s ease;
  max-width: 80%;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-landing-page-links .nav li a {
    margin-left: 15%;
  }
}

#block-vbf-community-vbf-landing-page-links .nav li a:before {
  transition: all 0.5s ease;
  content: "";
  background: url("../images/vbf_AmkA_Picto_Veranstaltungen_white.svg") no-repeat center center;
  background-color: #296192;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: block;
  margin-right: 1.17647rem;
  flex: 0 0 auto;
}

@media (min-width: 768px) {
  #block-vbf-community-vbf-landing-page-links .nav li a:before {
    height: 100px;
    width: 100px;
  }
}

#block-vbf-community-vbf-landing-page-links .nav li a:hover {
  color: #4182be;
}

#block-vbf-community-vbf-landing-page-links .nav li a:hover:before {
  background-color: #4182be;
}

#block-vbf-community-vbf-landing-page-links .nav li a.communities-link:before {
  background-image: url("../images/vbf_AmkA_Picto_Pinnwand_white.svg");
}

.node-type-landing-page #block-views-communities-block-1 .block-title {
  display: none;
}

#block-vbf-og-vbf-og-community-header {
  position: relative;
  color: #FFFFFF;
}

#block-vbf-og-vbf-og-community-header h1 {
  margin: 0;
  font-weight: 500;
  color: #FFFFFF;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  font-size: 1.76471rem;
  line-height: 2.11765rem;
}

@media (max-width: 768px) {
  #block-vbf-og-vbf-og-community-header h1 {
    font-size: 7.3vw;
  }
}

#block-vbf-og-vbf-og-community-header .community-info-wrapper {
  position: absolute;
  bottom: 1.17647rem;
  padding: 0 1.17647rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#block-vbf-og-vbf-og-community-header .community-info-wrapper span:not(.group) {
  font-size: 1.05882rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

#block-vbf-og-vbf-og-community-header .community-info-wrapper span.no {
  font-size: 1.17647rem;
}

@media (max-width: 768px) {
  #block-vbf-og-vbf-og-community-header .community-info-wrapper {
    bottom: auto;
    top: 1em;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-around;
    height: calc(100% - 1em);
  }
  #block-vbf-og-vbf-og-community-header .community-info-wrapper .group {
    margin-top: 1rem;
  }
}

.rate-widget:before, .rate-widget:after {
  display: table;
  content: " ";
}

.rate-widget:after {
  clear: both;
}

.rate-widget .rate-button {
  font-weight: 500;
  background-image: none;
  text-indent: 0;
  margin-right: 30px;
  display: block;
  float: left;
  font-size: 1rem;
  line-height: 2.35294rem;
  position: relative;
  top: 1px;
}

.rate-widget .rate-button.rate-plus-one:before, .rate-widget .rate-button.rate-plus-one:after {
  color: #C3C3C3;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.rate-widget .rate-button.rate-plus-one:before {
  content: " ";
}

.rate-widget .rate-button.rate-minus-one:before, .rate-widget .rate-button.rate-minus-one:after {
  color: #C3C3C3;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.rate-widget .rate-button.rate-minus-one:before {
  content: " ";
}

.rate-widget .rate-button.rate-plus-one:before, .rate-widget .rate-button.rate-minus-one:before {
  position: relative;
  top: 0.17647rem;
}

.rate-widget .rate-info {
  display: inline-block;
  line-height: 2.35294rem;
  font-size: 1rem;
}

.node:not(.node-teaser) .rate-widget {
  background: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}

.node-teaser .submission-info .time-ago {
  display: none;
}

.node-teaser .submission-info:hover .date-format {
  display: none;
}

.node-teaser .submission-info:hover .time-ago {
  display: inline-block;
}

.node-teaser:not(.node-community):not(.node-hero-content) {
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 3rem;
}

.node-teaser:not(.node-community):not(.node-hero-content):before, .node-teaser:not(.node-community):not(.node-hero-content):after {
  display: table;
  content: " ";
}

.node-teaser:not(.node-community):not(.node-hero-content):after {
  clear: both;
}

.node-teaser:not(.node-community):not(.node-hero-content) section.node-main {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .node-teaser:not(.node-community):not(.node-hero-content) section.node-main {
    float: left;
    width: 66.66667%;
  }
}

.node-teaser:not(.node-community):not(.node-hero-content) section.node-main .content {
  background: #F2F0F0;
  padding: 15px;
}

.node-teaser:not(.node-community):not(.node-hero-content) section.node-main h2 {
  margin-bottom: 1.5rem;
}

.node-teaser:not(.node-community):not(.node-hero-content) .slick-slider {
  background: #555555;
}

.node-teaser:not(.node-community):not(.node-hero-content) .slick-slider img {
  margin: auto;
}

.node-teaser:not(.node-community):not(.node-hero-content) .submission-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #C3C3C3;
}

.node-teaser:not(.node-community):not(.node-hero-content) .field-body p {
  font-size: 13px;
}

.node-teaser:not(.node-community):not(.node-hero-content) footer {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}

@media (min-width: 992px) {
  .node-teaser:not(.node-community):not(.node-hero-content) footer {
    float: left;
    width: 33.33333%;
  }
}

.node-teaser:not(.node-community):not(.node-hero-content) footer .left-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 30px;
  left: -17px;
  border-width: 16px 16px;
  border-right-color: #F2F0F0;
}

.node-teaser:not(.node-community):not(.node-hero-content) footer > .content {
  background: #F2F0F0;
  padding: 15px;
}

.node-teaser:not(.node-community):not(.node-hero-content) footer .additional-links {
  border-top: 1px solid #C3C3C3;
}

.node-teaser:not(.node-community):not(.node-hero-content) footer .additional-links ul {
  margin: 0;
}

.node-teaser:not(.node-community):not(.node-hero-content) footer .additional-links ul li {
  padding: 0;
}

.node-teaser:not(.node-community):not(.node-hero-content) footer .additional-links.has_modal {
  position: relative;
}

.node-community.node-teaser {
  background: #F2F0F0;
  min-height: 100%;
}

.node-community.node-teaser h2 {
  font-size: 1.17647rem;
  font-weight: 500;
  text-align: center;
}

.node-community.node-teaser h2 a {
  color: #005c8c;
  display: block;
  transition: all 0.2s ease;
}

.node-community.node-teaser h2 a:hover {
  text-decoration: none;
  text-shadow: 0px 1px 1px rgba(0, 92, 140, 0.25);
}

.node-community.node-teaser h2 a:hover:after {
  width: 70%;
}

.node-community.node-teaser h2 a:after {
  content: "";
  background: #005c8c;
  transition: width 0.2s ease;
  width: 60%;
  height: 1px;
  margin: 1.76471rem auto;
  display: block;
}

.node-community.node-teaser .field-sc-header-image {
  margin-bottom: 0.75rem;
}

.node-community.node-teaser .node-main {
  padding: 15px;
}

.node-access-settings {
  line-height: 1;
  position: relative;
}

.node-access-settings.access-1:before, .node-access-settings.access-1:after {
  color: #C3C3C3;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.node-access-settings.access-1:before {
  content: "";
}

.node-access-settings.access-2 {
  filter: alpha(opacity=40);
  opacity: 0.4;
}

.node-access-settings.access-2:before, .node-access-settings.access-2:after {
  color: #C3C3C3;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

.node-access-settings.access-2:before {
  content: "";
}

.node-access-settings:before {
  line-height: 1.47059rem;
  cursor: default;
}

.node-teaser .node-access-settings:before {
  color: #C3C3C3;
}

#block-vbf-community-vbf-community-submitted,
#block-vbf-community-vbf-community-submitted-mobile {
  background: #F2F0F0;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 3rem;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  min-height: 5.41176rem;
}

@media (max-width: 768px) {
  #block-vbf-community-vbf-community-submitted,
  #block-vbf-community-vbf-community-submitted-mobile {
    display: none;
  }
}

#block-vbf-community-vbf-community-submitted .arrow,
#block-vbf-community-vbf-community-submitted-mobile .arrow {
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  left: 30px;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: #F2F0F0 transparent transparent transparent;
  transform: translateY(100%);
}

#block-vbf-community-vbf-community-submitted .field-profile-image,
#block-vbf-community-vbf-community-submitted-mobile .field-profile-image {
  position: absolute;
  width: 3.64706rem;
  height: 3.64706rem;
}

#block-vbf-community-vbf-community-submitted .field-first-name,
#block-vbf-community-vbf-community-submitted-mobile .field-first-name {
  margin-left: 4.52941rem;
  line-height: 1.05882rem;
  max-width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1.5rem;
}

#block-vbf-community-vbf-community-submitted .submitted-info,
#block-vbf-community-vbf-community-submitted-mobile .submitted-info {
  margin-left: 4.52941rem;
  color: #C3C3C3;
  font-size: 0.70588rem;
  display: block;
}

#block-vbf-community-vbf-community-submitted-mobile {
  display: block;
  border-bottom: 1px solid #F2F0F0;
}

@media (min-width: 768px) {
  #block-vbf-community-vbf-community-submitted-mobile {
    display: none;
  }
}

#block-vbf-community-vbf-community-submitted-mobile .arrow {
  top: -16px;
  left: 16px;
  right: unset;
  border-left-color: transparent;
  border-bottom-color: white;
}

.block-node-fields-block .field {
  margin-bottom: 0.75rem;
}

.block-node-fields-block .field-event-date {
  margin-bottom: 1.5rem;
}

aside .block-node-fields-block {
  margin-bottom: 0 !important;
}

aside .block-node-fields-block h5 {
  font-size: 1.17647rem;
  border-bottom: 1px solid #C3C3C3;
  color: #005c8c;
  font-weight: 500 !important;
  padding-bottom: 0.75rem;
  margin-bottom: 1.5rem;
}

aside .block-node-fields-block .field {
  margin-bottom: 1.5rem;
}

aside .block-node-fields-block.field-sc-downloads .file-icon, aside .block-node-fields-block.field-event-files .file-icon {
  display: none;
}

aside .block-node-fields-block .field-event-location.field {
  margin-bottom: 0;
}

.node-blocks-row-wrapper {
  margin-right: -15px;
  margin-left: -15px;
}

.node-blocks-row-wrapper:before, .node-blocks-row-wrapper:after {
  display: table;
  content: " ";
}

.node-blocks-row-wrapper:after {
  clear: both;
}

.node-blocks-row-wrapper .block {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 4.5rem;
}

@media (min-width: 768px) {
  .node-blocks-row-wrapper .block {
    float: left;
    width: 33.33333%;
  }
}

@media (min-width: 992px) {
  .node-blocks-row-wrapper .block:not(:last-child) {
    padding-right: 0;
  }
  .node-blocks-row-wrapper .block:not(:first-child) {
    padding-left: 0;
  }
  .node-blocks-row-wrapper .block-title,
  .node-blocks-row-wrapper .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.node-blocks-row-wrapper .block-title {
  border-bottom: 1px solid #C3C3C3;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 1.17647rem;
}

.node-blocks-row-wrapper .field .field-label {
  color: #333333;
}

.node-blocks-row-wrapper-events {
  margin-right: -15px;
  margin-left: -15px;
}

.node-blocks-row-wrapper-events:before, .node-blocks-row-wrapper-events:after {
  display: table;
  content: " ";
}

.node-blocks-row-wrapper-events:after {
  clear: both;
}

.node-blocks-row-wrapper-events .block {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .node-blocks-row-wrapper-events .block {
    float: left;
    width: 100%;
  }
}

.node-blocks-row-wrapper-events .block-title {
  padding-left: 0;
  padding-right: 0;
}

.node-blocks-row-wrapper-events .content {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.node-blocks-row-wrapper-events .content > div {
  flex: 50%;
  margin-bottom: 28px;
}

#block-node-fields-block-3 {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background: #4182be;
  color: white;
  display: flex;
}

#block-node-fields-block-3 a {
  color: white !important;
}

#block-node-fields-block-3 h5,
#block-node-fields-block-3 .field-label {
  font-size: 1.05882rem;
}

.node-advpoll .poll-bar {
  margin-bottom: 0.375rem;
}

.node-advpoll .poll-bar:before, .node-advpoll .poll-bar:after {
  display: table;
  content: " ";
}

.node-advpoll .poll-bar:after {
  clear: both;
}

.node-advpoll .poll .voted .bar {
  border: none;
}

.node-advpoll .poll .bar {
  width: 90%;
  float: left;
}

@media (max-width: 480px) {
  .node-advpoll .poll .bar {
    width: 85%;
  }
}

.node-advpoll .poll .percent {
  width: 10%;
  float: left;
  clear: right;
  text-align: right;
  font-size: 0.70588rem;
}

.node-advpoll .poll-message {
  text-align: center;
  font-size: 0.70588rem;
}

.node-advpoll .poll .total {
  margin-top: 0.75rem;
  float: left;
  width: 100%;
}

.node-advpoll .poll .text {
  margin-bottom: 0.375rem;
}

.node-advpoll .poll button {
  text-transform: uppercase;
  width: 47.5%;
}

.node-advpoll .poll button:first-of-type {
  margin-right: 7.5px;
}

.node-advpoll .poll button:last-of-type {
  margin-left: 7.5px;
}

.node-advpoll .poll button:only-of-type {
  margin-top: 1.5rem;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.node-advpoll div[id^=message] {
  clear: both;
  float: none;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border: 1px solid #ebccd1;
  position: relative;
  top: 1rem;
}

.node-advpoll div[id^=message] hr {
  border-top-color: #e4b9c0;
}

.node-advpoll div[id^=message] .alert-link {
  color: #843534;
}

.node-advpoll .poll,
.node-advpoll form[id^=advpoll-form-] {
  padding: 30px;
  color: #FFFFFF;
}

.node-advpoll .poll:before, .node-advpoll .poll:after,
.node-advpoll form[id^=advpoll-form-]:before,
.node-advpoll form[id^=advpoll-form-]:after {
  display: table;
  content: " ";
}

.node-advpoll .poll:after,
.node-advpoll form[id^=advpoll-form-]:after {
  clear: both;
}

@media (max-width: 768px) {
  .node-advpoll .poll,
  .node-advpoll form[id^=advpoll-form-] {
    margin-left: 0;
    margin-right: 0;
  }
  .node-advpoll .poll button,
  .node-advpoll form[id^=advpoll-form-] button {
    display: block;
    border-collapse: collapse;
  }
  .node-advpoll .poll button[id^=edit-submit-advpoll-],
  .node-advpoll form[id^=advpoll-form-] button[id^=edit-submit-advpoll-] {
    float: left;
    width: 50%;
    border-width: 0;
    border-right-width: 2px;
    border-style: solid;
    border-color: transparent;
  }
  .node-advpoll .poll button[id^=edit-submit-show-results-],
  .node-advpoll form[id^=advpoll-form-] button[id^=edit-submit-show-results-] {
    float: left;
    width: 50%;
    border-width: 0;
    border-left-width: 2px;
    border-style: solid;
    border-color: transparent;
  }
}

.node-advpoll form > div > .form-item {
  margin-bottom: 2.25rem;
}

.node-advpoll form > div > .form-item > label.control-label {
  display: none;
}

.node-advpoll form > div > .form-item label {
  font-weight: 300;
  color: white;
}

.node-advpoll button {
  width: 46.85%;
}

.node-advpoll:not(.node-teaser) {
  margin-bottom: 3rem;
}

.node-advpoll:not(.node-teaser) .field-poll-question {
  margin-bottom: 1.5rem;
  font-size: 1.76471rem;
}

.node-advpoll:not(.node-teaser) form,
.node-advpoll:not(.node-teaser) .poll {
  background: #4182be;
}

.node-advpoll.node-teaser .poll,
.node-advpoll.node-teaser form[id^=advpoll-form-] {
  padding-top: 0;
}

.view-vbf-taxonomy-term h5 {
  margin-bottom: 0.375rem;
}

.view-vbf-taxonomy-term h2 {
  margin-bottom: 3rem;
}

.view-vbf-taxonomy-term .views-row {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid #F2F0F0;
}

#block-vbf-search-vbf-search-overview-block {
  margin-bottom: 1.5rem;
}

#block-vbf-search-vbf-search-overview-block .panorama-title-prefix,
#block-vbf-search-vbf-search-overview-block .panorama-title-suffix {
  clear: both;
  color: #C3C3C3;
}

#block-vbf-search-vbf-search-overview-block h1 {
  color: #005c8c;
  margin: 0;
}

#block-vbf-search-vbf-search-filters-with-counts {
  margin-bottom: 3rem;
}

#block-vbf-search-vbf-search-filters-with-counts ul,
#block-vbf-search-vbf-search-filters-with-counts li {
  list-style: none;
  margin: 0;
  padding: 0;
}

#block-vbf-search-vbf-search-filters-with-counts li {
  position: relative;
  background: white;
  border-top: 1px solid #F2F0F0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

#block-vbf-search-vbf-search-filters-with-counts li:first-child {
  border-top: 0;
}

#block-vbf-search-vbf-search-filters-with-counts li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#block-vbf-search-vbf-search-filters-with-counts li a:hover {
  text-decoration: none;
}

#block-vbf-search-vbf-search-filters-with-counts li .search-count {
  background: #FFFFFF;
  font-size: 0.82353rem;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  color: #005c8c;
  display: flex;
  align-items: center;
  justify-content: center;
}

#block-vbf-search-vbf-search-filters-with-counts li:hover a, #block-vbf-search-vbf-search-filters-with-counts li.active a {
  color: #062352;
}

#block-vbf-search-vbf-search-filters-with-counts li:hover .search-count, #block-vbf-search-vbf-search-filters-with-counts li.active .search-count {
  background: #005c8c;
  color: #FFFFFF;
}

#block-views-exp-main-search-page {
  margin-bottom: 3rem;
}

#block-views-exp-main-search-page .form-item-search-api-multi-fulltext > label {
  display: none;
}

#block-views-exp-main-search-page .views-exposed-widgets {
  margin-bottom: 0;
}

#block-views-exp-main-search-page .views-submit-button .form-item {
  margin-bottom: 0;
}

#block-views-exp-main-search-page .views-exposed-form .views-exposed-widget {
  float: none;
  padding: 0;
  display: flex;
}

#block-views-exp-main-search-page .views-exposed-form .views-exposed-widget .form-item.form-item-query.form-type-textfield {
  float: left;
}

#block-views-exp-main-search-page .views-exposed-form .views-exposed-widget label {
  display: none;
}

#block-views-exp-main-search-page input[type="text"],
#block-views-exp-main-search-page input[type="search"] {
  color: #333333;
  width: 100%;
  float: left;
  padding: 0.5rem;
  padding-left: 15px;
  padding-right: 15px;
}

#block-views-exp-main-search-page button {
  background: #005c8c;
  font-size: 0;
}

#block-views-exp-main-search-page button:before, #block-views-exp-main-search-page button:after {
  color: #FFFFFF;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

#block-views-exp-main-search-page button:after {
  content: " ";
}

#block-views-exp-main-search-page button:after {
  margin-left: -4px;
}

#block-views-exp-main-search-page button:hover {
  background: #062352;
  color: white;
}

.view-main-search .views-row {
  padding-bottom: 1.5rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid #C3C3C3;
  overflow: hidden;
}

.view-main-search .views-row h3 {
  border: 0;
  padding: 0;
  font-size: 1.76471rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  margin-top: 0.375rem;
}

.view-main-search .views-row .type {
  color: #005c8c;
  text-transform: uppercase;
}

.view-main-search .views-row h3.event {
  margin-bottom: 0;
}

.view-main-search .views-row .event-date {
  margin-bottom: 1.5rem;
  display: block;
}

.view-main-search .views-row .image-wrapper {
  padding-top: 1.5rem;
}

@media (min-width: 992px) {
  .view-main-search .views-row .image-wrapper {
    position: relative;
    height: 8rem;
    float: left;
    width: auto;
    max-width: 25%;
    padding: 0;
    margin-right: 30px;
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  #block-vbf-community-vbf-community-search {
    position: absolute;
    z-index: 200;
    max-height: 0;
    overflow: hidden;
    right: 15px;
    top: 55px;
  }
  #block-vbf-community-vbf-community-search.open {
    max-height: 200px;
    overflow: visible;
  }
}

#block-vbf-community-vbf-community-search form {
  padding: 0.88235rem;
  box-shadow: 0 0px 4px -2px rgba(0, 0, 0, 0.5);
}

@media (min-width: 768px) {
  #block-vbf-community-vbf-community-search form {
    background: #062352;
  }
}

#block-vbf-community-vbf-community-search form > div {
  display: flex;
}

#block-vbf-community-vbf-community-search .control-label {
  display: none;
}

#block-vbf-community-vbf-community-search .form-item {
  margin: 0 !important;
}

#block-vbf-community-vbf-community-search .btn {
  background: #062352;
  font-size: 0;
  transition: all 0.2s ease-out;
}

#block-vbf-community-vbf-community-search .btn:before, #block-vbf-community-vbf-community-search .btn:after {
  color: #FFFFFF;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

#block-vbf-community-vbf-community-search .btn:after {
  content: " ";
}

#block-vbf-community-vbf-community-search .btn:after {
  margin-left: -4px;
}

@media (min-width: 1200px) {
  #block-vbf-community-vbf-community-by-menu {
    margin-right: 80px;
  }
}

@media (max-width: 768px) {
  #block-vbf-community-vbf-community-by-menu a[href*="search"] {
    display: none;
  }
}

#views-exposed-form-main-search-page .form-item {
  margin: 0 !important;
}

.view-vbf-wegweiser .view-filters {
  display: none;
}

.view-vbf-wegweiser .text-center {
  width: 100%;
  clear: both;
}

.vbf-radio-as-button {
  /* graceful degradation for ie8 */
}

.vbf-radio-as-button .form-item {
  display: flex;
}

.vbf-radio-as-button input[type='radio'] {
  line-height: normal;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
}

.vbf-radio-as-button input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border-collapse: collapse;
  display: none;
}

.vbf-radio-as-button input[type='radio'] {
  width: auto;
  float: left;
  margin-right: .75em;
}

.vbf-radio-as-button input[type='radio']:checked,
.vbf-radio-as-button input[type='radio']:focus,
.vbf-radio-as-button input[type='radio']:not(:checked) {
  background: transparent;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.vbf-radio-as-button input[type='radio'] + label {
  position: relative;
  cursor: pointer;
}

.vbf-radio-as-button input[type='radio']:not(:checked) + label {
  background: #FFFFFF;
  color: #555555;
}

.vbf-radio-as-button input[type='radio']:checked + label,
.vbf-radio-as-button input[type='radio']:hover + label {
  color: #FFFFFF;
  background: #005c8c;
}

.vbf-radio-as-button .radio + .radio, .vbf-radio-as-button .checkbox + .checkbox {
  margin: 0;
}

.vbf-radio-as-button .form-type-radio {
  display: flex;
  flex-flow: column;
}

.vbf-radio-as-button .form-type-radio label {
  width: 100%;
  margin-bottom: 1.125rem;
  text-align: center;
  border: 2px solid #005c8c;
  padding: 0.5rem;
}

#block-vbf-community-vbf-events-filters .views-exposed-widget {
  width: 100%;
  padding: 0;
}

#block-vbf-community-vbf-events-filters .chosen-container-single .chosen-single {
  border: 2px solid #005c8c;
  box-sizing: content-box;
}

#block-vbf-community-vbf-events-filters .form-type-select {
  margin-bottom: 1.125rem;
}

#block-vbf-community-vbf-events-filters .views-exposed-form .panel-heading {
  color: #C3C3C3;
  border: 0;
  margin-bottom: 1.875rem;
  display: none;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-events-filters .views-exposed-form .panel-heading {
    display: block;
    margin-bottom: 10px;
  }
}

#block-vbf-community-vbf-events-filters .form-type-datepicker {
  margin-bottom: 0 !important;
}

#block-vbf-community-vbf-events-filters .reset-button {
  color: #005c8c;
  background: transparent;
}

#block-vbf-community-vbf-events-filters .reset-button:hover {
  text-decoration: underline;
}

#block-vbf-community-vbf-events-filters #edit-actions {
  display: flex;
  justify-content: space-between;
}

#block-vbf-community-vbf-events-filters .form-item .date-group {
  width: 100%;
  padding-bottom: 1.5rem;
}

#block-vbf-community-vbf-events-filters .ui-datepicker {
  width: 100%;
  border-color: #005c8c;
  border-width: 2px;
  border-radius: 0;
}

#block-vbf-community-vbf-events-filters .ui-datepicker td {
  padding: 0;
}

#block-vbf-community-vbf-events-filters .ui-datepicker td a {
  padding: 0;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header {
  background: #FFFFFF;
  border: hidden;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-corner-all {
  border-radius: 0;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-corner-all .ui-icon {
  background: none;
  display: none;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-prev {
  left: 0;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-prev:before, #block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-prev:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-prev:after {
  content: " ";
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-next {
  right: 0;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-next:before, #block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-next:after {
  color: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Glyphicons Halflings';
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-next:after {
  content: " ";
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-prev,
#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-next {
  display: flex;
  width: 2rem;
  height: 100%;
  align-items: center;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-prev:after,
#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-next:after {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-prev:hover,
#block-vbf-community-vbf-events-filters .ui-datepicker-header .ui-datepicker-next:hover {
  background: #005c8c;
  color: #FFFFFF;
  border: none;
  text-decoration: none;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-title {
  font-weight: 700;
  font-size: 0.88235rem;
  display: flex;
  justify-content: space-between;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-title select {
  display: block;
  line-height: 1.3;
  padding: .6em 1.4em .5em .6em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("../images/icon-chevron-down.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 0.1em top 60%, 0 0;
  background-size: 1.4em auto, 100%;
  width: 49%;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-title select::-ms-expand {
  display: none;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-title select:hover {
  border-color: #888;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-title select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

#block-vbf-community-vbf-events-filters .ui-datepicker-title select option {
  font-weight: normal;
}

#block-vbf-community-vbf-events-filters a.ui-state-default {
  background: #FFFFFF;
  border: 0;
  text-align: center;
}

#block-vbf-community-vbf-events-filters a.ui-state-default.ui-state-active, #block-vbf-community-vbf-events-filters a.ui-state-default:hover {
  background: #005c8c;
  color: #FFFFFF;
}

#block-vbf-community-vbf-events-filters .input-group-addon.glyphicon {
  visibility: hidden;
}

.eu-cookie-compliance-banner {
  background: #FFFFFF;
  padding: 4rem;
  border: 1px solid #4182be;
  box-shadow: 0px 1px 0 0 rgba(0, 92, 140, 0.5);
}

#sliding-popup {
  left: 4%;
  transform: translate(0, 0);
  top: 20px !important;
  max-width: 92%;
}

@media (min-width: 992px) {
  #sliding-popup {
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50% !important;
    height: auto;
  }
}

#sliding-popup:before {
  width: 1000%;
  height: 1000%;
  background-color: rgba(85, 85, 85, 0.8);
  position: absolute;
  z-index: 0;
  top: -200%;
  left: -200%;
  right: -200%;
  bottom: -200%;
}

#sliding-popup button {
  background: #4182be;
  color: white;
  border: none;
  font-weight: 500;
}

#sliding-popup button:hover {
  background-color: #c9e2f2;
  color: #555555;
}

#sliding-popup button.eu-cookie-withdraw-tab {
  display: none;
}

#sliding-popup .eu-cookie-compliance-category {
  padding-left: 30px;
  margin-bottom: 2rem;
}

#sliding-popup .eu-cookie-compliance-category input[type="checkbox"] {
  position: absolute;
}

#sliding-popup .eu-cookie-compliance-category label {
  padding-left: 16px;
}

#sliding-popup .eu-cookie-compliance-category-description {
  padding-left: 18px;
}

#sliding-popup .eu-cookie-compliance-banner {
  position: relative;
  z-index: 2;
  padding: 2rem 2.6rem;
  height: 95vh;
  overflow: scroll;
}

@media (min-width: 992px) {
  #sliding-popup .eu-cookie-compliance-banner {
    height: auto;
    overflow: auto;
  }
}

#sliding-popup .popup-content {
  display: flex;
  flex-flow: column;
  max-width: none;
  position: relative;
}

#sliding-popup .popup-content #popup-text {
  max-width: none;
  float: none;
  margin-bottom: 1.5rem;
}

#sliding-popup .popup-content #popup-buttons {
  display: flex;
  max-width: none;
}

#sliding-popup .popup-content #popup-buttons button + button {
  margin-left: 1rem;
}

#sliding-popup button.eu-cookie-compliance-save-preferences-button {
  background-color: #FFFFFF;
  color: #4182be;
}

.agree-button.eu-cookie-compliance-default-button {
  position: absolute;
  left: 210px;
  bottom: 24px;
}

@media (max-width: 768px) {
  .agree-button.eu-cookie-compliance-default-button {
    position: relative;
    left: auto;
    bottom: auto;
  }
}

#eu-cookie-compliance-categories {
  margin-bottom: 1.5rem;
}

body.page-admin #sliding-popup {
  display: none !important;
}

.eu-cookie-withdraw-button {
  display: none;
}

.event-number {
  display: flex;
  flex: flex;
  margin-bottom: 7.5rem;
}

.event-number li {
  display: flex;
  flex-flow: column;
  align-content: center;
  justify-content: center;
  background: #005c8c;
  text-align: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 1.17647rem;
  border-radius: 100%;
  line-height: 1;
  width: 262px;
  height: 262px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

.event-number li:before {
  margin-bottom: 1rem;
  width: 40%;
}

@media (max-device-width: 667px) {
  .event-number {
    display: block !important;
  }
}

.modal-content {
  border-radius: 10px;
  background-color: transparent;
}

.modal-header {
  background-color: #005c8c;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 13px 20px;
}

.modal-header h3 {
  color: #FFFFFF;
  margin-bottom: 0;
  padding: 0;
}

.modal-header .close {
  width: 25px;
  height: 25px;
  background-color: #FFFFFF;
  border-radius: 50%;
  margin-top: 5px;
  opacity: 1;
}

.modal-header .close span {
  font-size: 20px;
  line-height: 22px;
  display: block;
  height: 25px;
  font-weight: 100;
  color: #005c8c;
}

.modal-body {
  padding: 0;
}

.modal-body p {
  font-size: 16px;
  font-weight: 500;
}

#block-vbf-community-vbf-community-newsletter-info {
  padding: 30px 0;
  background-color: #FFFFFF;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-community-newsletter-info {
    padding: 40px 90px;
  }
}

#block-vbf-community-vbf-community-newsletter-info > div:nth-child(2),
#block-vbf-community-vbf-community-newsletter-archive > div:nth-child(2) {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#block-vbf-community-vbf-community-newsletter-info > div:nth-child(2) a,
#block-vbf-community-vbf-community-newsletter-archive > div:nth-child(2) a {
  text-align: center;
}

#block-vbf-community-vbf-newsletter {
  background-color: #005c8c;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#block-vbf-community-vbf-newsletter form {
  padding: 0 10px;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-newsletter form {
    padding: 0 90px;
  }
}

#block-vbf-community-vbf-newsletter div.form {
  max-width: none;
  position: relative;
  padding: 18px 0;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-newsletter div.form {
    padding: 38px 0;
  }
}

#block-vbf-community-vbf-newsletter div.form .form_border {
  border: none;
  background-color: transparent;
  padding: 0;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-newsletter div.form .form_border {
    padding: 10px;
  }
}

#block-vbf-community-vbf-newsletter div.form ul:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0px;
}

#block-vbf-community-vbf-newsletter div.form ul li {
  padding-left: 0;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-newsletter div.form ul li {
    width: 51%;
    float: left;
  }
}

#block-vbf-community-vbf-newsletter div.form label {
  color: #FFFFFF;
  display: block;
  width: 100%;
  margin: 0;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
}

#block-vbf-community-vbf-newsletter div.form label.focus {
  margin-top: 10px;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-newsletter div.form label.focus {
    position: absolute;
    right: 0;
    top: 72px;
    width: calc(49% - 20px);
    padding-left: 20px;
    margin-top: 0;
  }
}

#block-vbf-community-vbf-newsletter div.form .form_button_submit {
  border-radius: 4px;
  background-color: #062352;
  width: 100%;
  text-align: center;
}

@media (min-width: 992px) {
  #block-vbf-community-vbf-newsletter div.form .form_button_submit {
    margin-right: -50%;
  }
}

#modalNewsletterArchive .modal-body {
  padding: 20px;
  background-color: #FFFFFF;
}

body.og-context .main-container-wrapper [data-og-menu-toggle],
.page-node-community .main-container-wrapper [data-og-menu-toggle] {
  margin-top: 0.5em;
}

@media (max-width: 992px) {
  body.front .main-container-wrapper {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

@media (max-width: 992px) {
  body.front .main-container-wrapper h1 {
    margin-bottom: 30px;
  }
}

.field-name-field-additional-eventdate .panel-title {
  display: none;
}

.field-name-field-additional-eventdate .field-label label {
  font-weight: 600;
  font-size: 1.2rem;
  display: block;
  margin-bottom: 10px;
}

.field-name-field-additional-eventdate .date-float {
  margin-bottom: 15px;
}

.og-context-node-2584 #block-vbf-og-menu-vbf-og-menu-single a[href="/en/node/2584/contact"],
.og-context-node-2584 #block-vbf-og-menu-vbf-og-menu-single a[href="/de/node/2584/contact"] {
  display: none;
  height: 0;
}

.accordion__wrapper {
  margin: 2rem 0;
}

.accordion__item {
  margin-bottom: 1rem;
}

.accordion__item--title {
  cursor: pointer;
  padding: 1.1rem 2.4rem 1.1rem 1.25rem;
  background-color: rgba(221, 225, 227, 0.75);
  color: #696969;
  position: relative;
}

.accordion__item--title > h4 {
  margin-bottom: 0;
  font-size: 1.33333rem;
}

.accordion__item--title > .glyphicon {
  position: absolute;
  top: 1.1rem;
  right: 1.25rem;
  transform: rotate(0deg);
  transition: all 0.1 0.5s;
}

[aria-expanded="true"] .accordion__item--title > .glyphicon {
  transform: rotate(180deg);
}

body.blue .entity-paragraphs-item .accordion__item--title h4 {
  color: #696969;
}

.accordion__item--text {
  font-size: 1.33333rem;
}

[aria-expanded="true"] .accordion__item--text {
  padding: 1rem 1.25rem;
  height: auto;
  overflow: visible;
  opacity: 1;
  transition: all .3s;
}

[aria-expanded="false"] .accordion__item--text {
  padding: 0 1.25rem;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all .3s;
}

@media print {
  #block-vbf-og-vbf-og-community-main-teaser > .view .views-row,
  #block-vbf-og-vbf-og-page-main-teaser > .view .views-row {
    border-color: transparent !important;
  }
  #block-vbf-community-vbf-community-footer-menu {
    display: none !important;
  }
  #navbar-secondary,
  #block-vbf-og-menu-vbf-og-menu-single {
    display: none !important;
  }
}
