// Theme Customizations to Blocks //
.block {
  @extend %user-contextual-links;

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-sans-serif;
  }

  .main-container & {
    h2 {
      font-weight: 300;
    }
  }

  h2.block-title {
    margin: $line-height-computed 0;
  }

  // Subscribe Links to do a[href*="subscribe"]
  span.group.manager, a.subscribe, a.unsubscribe {
    @include glyphicon($glyphicon-info-sign);

    background: white;
    text-align: center;
    border: 2px solid $blue_darker;
    display: inline-block;
    align-items: center;
    font-size: $bold;
    padding: rem(10) rem(20) rem(10) rem(30);
    transition: all 0.2s ease;

    &:hover {
      text-decoration: none;
      padding-left: rem(25);
      padding-right: rem(25);
    }

    &:before {
      position: absolute;
      left: 6px;
      top: 12px;
    }
  }

  a {
    &.subscribe {
      @include glyphicon($glyphicon-ok-sign);

    }
    &.unsubscribe {
      @include glyphicon($glyphicon-remove-sign);

    }
  }
}

.block .contextual-links-wrapper {
  background: #FFF;
}

#block-vbf-og-vbf-og-community-description .contextual-links-wrapper {
  top: -3rem;
}
#block-vbf-og-vbf-og-community-description {
	@media (min-width: $screen-md-min) {
		width: calc(66.66667% - 12px);
	}
}

#block-vbf-og-vbf-og-community-sidebar-teaser {
  @include vbf-margin-trailer(1);
}

#block-addressfield-staticmap-addressfield-staticmap {
  @include vbf-margin-trailer(2);
  div#map_canvas{
    padding: 0 10%;
    width: 100% !important;
  }
}

// ----------------------------------------------------
// Collapsible blocks
// ----------------------------------------------------
div {
  .collapsiblockCollapsed,
  .collapsiblock {
    a {
      background: none;
      text-decoration: none;
      cursor: default;
    }
  }
}
// collapsible blocks not on big screens
@media (min-width: $screen-sm-min) {
  .collapsiblock-processed {
    div.field,
    span.file,
    div.view {
      display: block !important;
    }
  }
}
@media (max-width: $screen-sm-min - 1) {
  div {
    .collapsiblock a {
      @include glyphicon-after-standalone($glyphicon-menu-up);
    }
    .collapsiblockCollapsed a {
      @include glyphicon-after-standalone($glyphicon-menu-down);

    }
    .collapsiblock a,
    .collapsiblockCollapsed a {
      @include after-absolute-right-centered ($spacing: 0);
      cursor: pointer;
    }
  }
}

// ----------------------------------------------------
// Community region-before (left side) blocks
// ----------------------------------------------------
#block-vbf-og-menu-vbf-og-menu-single  {
  .dropdown-dummy-link {
    border-top: 1px solid $blue-gray;
  }
}

#navbar-secondary,
#block-vbf-og-menu-vbf-og-menu-single {
  width: 100%;
  // float: left;
  nav[role="navigation"] ul{
    float: left;
    width: 100%;

    @media (max-width: $screen-sm) {
      float: none;
    }
  }
  .menu {
    font-size: rem(20);
  }
  .menu a {
    color: $gray-dark;
    padding: rem(10) 0;
    transition: all 0.2s ease-out;
    &:hover,
    &:focus {
      background: none;
      text-decoration: none;
      text-shadow: 0px 0px 0px rgba($black, 0.5);
    }
    .glyphicon {
      color: $gray-light;
      top: 8px;
    }
  }
  li {
    float: left;
    width: 100%;
    &.expanded {
      ul {
        padding-left: $grid-gutter-width/2;
      }
    }
  }

  @media (min-width: $screen-md) {
    float: left;
  }


  // mobile breadcrumb
  .preview-breadcrumb {
    display: none;
  }
  @media (max-width: $screen-sm) {
    .menu.og-menu-processed {
      @include vbf-margin-trailer;

      li {
        // display: none;
      }
      .preview-breadcrumb {
        border: 1px solid $gray-lighter;
        // padding-right: rem(50);
        padding: 6px;
        display: block;
        position: relative;

        a {
          display: inline-block;
          padding: 0;
          @include glyphicon-after($glyphicon-menu-right + "\2006\2006");

          &.active:after {
            display: none;
          }
          &:not(:first-child):not(.active) {
            color: $gray-light;
          }
        }
      }
      .menu-toggle {
        color: $gray-light;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: rem(9);
        font-size: rem(12);
        .menu-label {
          //display: none;
        }
      }
      &.menu-open {
        li {
          display: block;
        }
        .preview-breadcrumb {
          margin-bottom: 0;
          min-height: 42px !important;
          a {
            display: none;
          }
        }
        .menu-toggle {
          .menu-label {
            display: inline;
          }
        }
      }
    }
  }
  @media (max-width: $screen-xs) {
    .menu.og-menu-processed {
      &.menu-open {
        .preview-breadcrumb {
          min-height: 42px !important;
        }
      }
    }
  }
}

// Menu Toggle

.block-vbf-og-menu {
  box-sizing: border-box;
}

[data-og-menu-toggle] {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 16px;
  text-align: left;
  background: #FFF;
  padding: 1rem;
  box-shadow: inset 0 0 0 2px #062352;
  color: #4182be;
  width: 100%;
  position: relative;
  &:hover {
    color: #4182be;
  }
  &::after {
    --bd-width: 6px;
    content: '';
    display: block;
    position: absolute;
    right: 1rem;
    bottom: 50%;
    transform: translateY(50%);
    width: 0;
    height: 0;
    border-left: var(--bd-width) solid transparent;
    border-right: var(--bd-width) solid transparent;
    border-top: var(--bd-width) solid #4182be;

  }
  &:focus {
    color: #4182be;
  }
}

.og-menu-open [data-og-menu-toggle]::after {
  transform: translateY(50%) rotate(180deg);
}

@media (min-width: $screen-sm) {
  [data-og-menu-toggle] {
    display: none;
  }
}

@media (max-width: $screen-sm) {
  .page-node-veranstaltungen .region-sidebar-first {
    height: 0;
    overflow: hidden;
  }

  .page-node-veranstaltungen.og-menu-open .region-sidebar-first {
    height: auto;
    overflow: visible;
  }
}


// ----------------------------------------------------
// Community main region blocks // overview
// ----------------------------------------------------
#block-vbf-og-vbf-og-community-description {
  border-bottom: 1px solid $gray-light;
  @include vbf-margin-trailer(2);
  .field-body {
    @include vbf-margin-trailer(2);
  }
}

#block-views-vbf-og-page-references-block {
  @include vbf-margin-leader(1.5);
  > .view {
    > .view-content {
      &:before {
        display: block;
      }
      display: flex;
      flex-wrap: wrap;
      @media (max-width: $screen-md) {
        display: block;
      }
    }
  }
}

// ----------------------------------------------------
// Blockify: Page Title
// ----------------------------------------------------
#block-blockify-blockify-page-title {
  #page-title {
    @include vbf-margin-trailer(2);
    @media (max-width: $screen-sm-min - 1) {
      @include vbf-margin-trailer(1);
      padding-left: 0;
      padding-right: 0;
    }
  }
  h5 {
    text-transform: uppercase;
    @include vbf-margin-trailer(1);
    color: $gray-dark;
  }
}

// ----------------------------------------------------
// Blockify: Actions
// ----------------------------------------------------
#block-blockify-blockify-actions {
  // unwrapped
  .action-links {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      float: left;
      @include vbf-margin-trailer(0.5);
      a {
        color: $white !important;
        transition: all 0.2s ease;
        padding: $grid-gutter-width/2;
        &:hover {
          text-decoration: none;
          background: $blue-darker;
        }
      }
    }
  }
  .action-links-single-wrapper li a,
  #add-node-toggle {
    width: 100%;
    @include glyphicon-standalone($glyphicon-plus, white, rem(24));
    display: block;
    font-size: rem(18);
    background: $gray-lighter;
    position: relative;
    color: $blue-dark;
    line-height: rem(64);
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    transition: all 0.2s ease-out;
    padding-left: rem(22);
    padding-right: rem(82);
    &:before {
      position: absolute;
      background: $blue;
      top: 0;
      right: 0;
      height: 100%;
      width: rem(60);
      text-align: center;
      justify-content: center;
      transition: all 0.2s ease-out;
    }
    &:hover {
      background: $blue-darker;
      text-decoration: none;
      color: white;
      &:before {
        background: rgba($blue-darker, 0.8) !important;
      }
    }
    @media (min-width: $screen-sm-min) {
      font-size: rem(16);

    }
    @media (min-width: $screen-md-min) {
      padding-left: rem(16);
      padding-right: rem(60);
    }
    @media (min-width: $screen-lg-min) {
      font-size: rem(18);
      padding-left: rem(22);
    }
  }
  // single
  .action-links-single-wrapper {
    li {
      width: 100%;
      a {
        padding: 0 $grid-gutter-width/2;
      }
    }
  }
  // mutiple-dropdown
  .action-links-multiple-wrapper{
    margin-bottom: $grid-gutter-width;
    position: relative;
    ul {
      padding: 0;
      font-size: rem($font-size-large);
      background: $blue_darker;
      li {
        float: none;
        margin-bottom: 0;
        a {
          display: block;
          background: $blue;
        }
      }
    }
    .action-links {
      max-height: 0;
      overflow: hidden;
      position: absolute;
      z-index: 200;
      width: 100%;

      &.open {
        max-height: 500px;
        // box-shadow: 0px 0px 4px -2px rgba($black, 0.5);
      }
    }
  }
}

// ----------------------------------------------------
// VBF: OG Memberships
// ----------------------------------------------------
#block-vbf-og-vbf-og-user-memberships {
  h2 {
    color: $blue_dark;
  }
  .views-row {
    @include clearfix;
    @include vbf-margin-trailer(1.25);
    position: relative;
  }
  .field-sc-profile-image {
    float: left;
    img {
      border-radius: 50%;
      width: rem(62);
      height: auto;
    }
  }
  .views-field-title {
    position: absolute;
    top: 50%;
    left: rem(62 + $grid-gutter-width/2);
    @include translate(0, -50%);
    @include make-bold;
  }
}

// ----------------------------------------------------
// Communities Overview
// ----------------------------------------------------
#block-views-communities-block-1 {
  .block-title {
    color: $blue_dark;
    font-size: rem(45);
    font-weight: $normal;
    margin-top: 3rem;
    @include vbf-margin-trailer(2);
  }
}

#block-vbf-community-vbf-newsletter {

  iframe {
    height: 500px;
  }
}
