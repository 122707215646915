.eu-cookie-compliance-banner {
  background: $white;
  padding: 4rem;
  border: 1px solid $blue;
  box-shadow: 0px 1px 0 0 rgba($blue-dark, 0.5);
}

#sliding-popup {
  left: 4%;
  transform: translate(0, 0);
  top: 20px !important;
  max-width: 92%;

  @media (min-width: $screen-md) {
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50% !important;
    height: auto;
  }

  &:before {
    width: 1000%;
    height: 1000%;
    background-color: rgba($gray, 0.8);
    position: absolute;
    z-index: 0;
    top:-200%;
    left: -200%;
    right: -200%;
    bottom: -200%;
  }

  button {
    background: $blue;
    color: white;
    border: none;
    @include make-bold;

    &:hover {
      background-color: $blue-lighter;
      color: $gray;
    }
  }

  button.eu-cookie-withdraw-tab {
    display: none;
  }

  .eu-cookie-compliance-category {
    @extend .form-type-checkbox;

    padding-left: 30px;
    margin-bottom: 2rem;

    input[type="checkbox"] {
      position: absolute;
    }

    label {
      padding-left: 16px;
    }
  }

  .eu-cookie-compliance-category-description {
    padding-left: 18px;
  }

  .eu-cookie-compliance-banner {
    position: relative;
    z-index: 2;
    padding: 2rem 2.6rem;
    height: 95vh;
    overflow: scroll;

    @media (min-width: $screen-md) {
      height: auto;
      overflow: auto;
    }
  }

  .popup-content {
    display: flex;
    flex-flow: column;
    max-width: none;
    position: relative;

    #popup-text {
      max-width: none;
      float: none;
      @include vbf-margin-trailer(1);
    }
    // consent all / consent withdraw buttons;
    #popup-buttons {
      display: flex;
      max-width: none;

      button + button {
        margin-left: 1rem;
      }
    }
  }
}

#sliding-popup button.eu-cookie-compliance-save-preferences-button {
  background-color: $white;
  color: $blue;
}

.agree-button.eu-cookie-compliance-default-button {
  position: absolute;
  left: 210px;
  bottom: 24px;

  @media (max-width: $screen-sm) {
    position: relative;
    left: auto;
    bottom: auto;
  }
}

#eu-cookie-compliance-categories {
  margin-bottom: 1.5rem;
}

body.page-admin {
  #sliding-popup {
    display: none !important;
  }
}

.eu-cookie-withdraw-button{
  display: none;
}
