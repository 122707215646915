.view-hero-teaser {
  display: flex;
  width: 100%;
  flex-flow: column;
  @media (min-width: $screen-md) {
    flex-flow: row;
  }
  // main
  .view-content {
    background: $gray-lighter;
    display: flex;
    max-width: 100%;
    flex: 0 0 100%;
    @media (min-width: $screen-md) {
      flex: 0 0 66%;
      max-width: 66%;
    }
    .hero-slider,
    .views-row {
      width: 100%;
    }
  }
  .hero-headlines {
    max-width: 100%;
    order: 2;
    @media (min-width: $screen-md) {
      flex: 0 1 34%;
      max-width: 34%;
      padding-left: $grid-gutter-width;
    }

    .view-hero-teaser {
      flex-flow: column;
      height: 100%;
    }

    .views-row {
      margin-bottom: rem(16);
      width: 100%;
      flex: 1;
      @media (min-width: $screen-md) {
        margin-bottom: 30px;
      }
    }
    @media (max-width: $screen-md) {
      .views-row-first {
        margin-top: 1rem;
      }
    }
    .views-row-last {
      margin-bottom: 0;
    }
  }
  .slick-prev,
  .slick-next {
    top: auto;
    bottom: -24px;

    z-index: 2;
    &::before,
    &::after {
      color: #005c8c;
      font-size: 22px;

      @media (min-width: $screen-sm) {
        font-size: 40px;
      }
    }
    @media (min-width: $screen-sm) {
      top: auto;
      bottom: -18px;
    }
    @media (min-width: $screen-md) {
      bottom: -9px;
    }
  }
  .slick-prev {
    left: auto;
    left: 0;
  }
  .slick-next {
    right: 0.5rem;
  }
}
.hero-slider .slick-prev {
  display: none !important;
}
.node-hero-content {
  &.node-full {
    @media (min-width: $screen-sm) {
      // height: 400px;
    }
    @media (min-width: $screen-md) {
      // height: 450px;
    }
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    overflow: hidden;
    .field-image {
      height: 200px;
      @media (min-width: $screen-sm) {
        height: 400px;
      }
      @media (min-width: $screen-md) {
        height: 450px;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .field-item,
      .img-responsive {
        height: 100%;
      }
    }
    .detail {
      z-index: 1;
      padding: rem(20) rem(25);
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 100%;

      @media (min-width: $screen-xs) {
        bottom: 40px;
      }

      @media (min-width: $screen-sm) {
        background-color: #e4eef3;

        position: absolute;
        bottom: -5px;
        padding: rem(28) rem(15);
      }
      @media (min-width: $screen-md) {
        padding: rem(20) rem(15);
      }
    }
    h2 {
      margin: 0;
      font-weight: $bold;
      font-size: rem(21);

      @media (max-width: $screen-md) {
        font-size: rem(21);
        font-weight: 600;
      }

      @media (max-width: $screen-xs) {
        font-size: rem(18);
        font-weight: bold;
      }
    }
    .field-subtitle .field-item {
      display: none;
      @media (min-width: $screen-md) {
        // text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
        display: block;
      }
      font-size: rem(17);
      font-weight: $normal;
    }
    h2,
    h2 a,
    .field-item {
      color: $blue;

      @media (min-width: $screen-sm) {
        color: #005c8c;
      }
    }
  }

  &.node-teaser {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    > a {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      align-items: center;
      height: 100%;
    }

    .field-image-container {
      flex: 0 0 52%;
      margin-right: 10px;

      @media (max-width: $screen-sm) {
        flex: 0 0 45%;
      }

      @media (min-width: $screen-xs) {
        margin-right: 20px;
        height: 100%;
      }
    }
    .field-image {
      overflow: hidden;
      padding-top: calc(9 / 16 * 100%);
      background: #eee;
      position: relative;
      height: 100%;
      max-height: 130px;
    }

    .field-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-width: none;
      }
    }

    h2 {
      margin: 0;
      font-size: rem(17);
      line-height: rem(20);
      font-weight: $bold;

      @media (max-width: $screen-md) {
        font-size: rem(30);
        font-weight: 600;
        line-height: 1;
      }

      @media (max-width: $screen-xs) {
        font-size: rem(18);
      }
    }
  }
}

// Needed to handle Safari Not Re-Calculating FlexboxLayout after Slider Load

#block-views-hero-teaser-block-hero {
  .view-content {
    max-height: 450px;
    // overflow: hidden;
  }
}
