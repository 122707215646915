// Print styles //

@media print {
  #block-vbf-og-vbf-og-community-main-teaser,
  #block-vbf-og-vbf-og-page-main-teaser {
    > .view .views-row {
      border-color: transparent !important;
    }
  }

  #block-vbf-community-vbf-community-footer-menu {
    display: none !important;
  }

  #navbar-secondary,
  #block-vbf-og-menu-vbf-og-menu-single {
    display: none !important;
  }
}
