////////////////////////
// Functions Partial
//
// All of your global functions should go in this file.
////////////////////////

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function rem($pxValue) {
  $rootValue: strip-unit($font-size-large);
  $value: strip-unit($pxValue);
  $remValue: ($value / $rootValue) + rem;
  @return $remValue;
}

@function rem-rhythm-value($lines) {
  @return $lines * 1.5rem;
}

@function headings($from:1, $to:6) {
  @if $from == $to {
    @return 'h#{$from}';
  } @else {
    @return 'h#{$from},' + headings($from+1, $to);
  }
}