.accordion__wrapper { 
	margin: 2rem 0;
}

.accordion__item {
	margin-bottom: 1rem;
}

.accordion__item--title {
	cursor: pointer;
	padding: 1.1rem 2.4rem 1.1rem 1.25rem;
	background-color: rgba($footer-bg-color, 0.75);
	color: #696969;
	position: relative;
	> h4 {
		margin-bottom: 0;
		font-size: 1.33333rem;
	}
	> .glyphicon {
		position: absolute;
		top: 1.1rem;
		right: 1.25rem;
		transform: rotate(0deg);
		transition: all .1.5s;
	}
	[aria-expanded="true"] & > .glyphicon {
		transform: rotate(180deg);
	}
}

body.blue .entity-paragraphs-item .accordion__item--title h4 {
	color: #696969;
}

.accordion__item--text {
	font-size: 1.33333rem;
	[aria-expanded="true"] & {
		padding: 1rem 1.25rem;
		@include expanded();
	}
	[aria-expanded="false"] & {
		padding: 0 1.25rem;
		@include closed();
	}
}