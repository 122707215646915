//
// Badges
// --------------------------------------------------

// Base class
%badge {
  display: inline-block;
  min-width: 22px;
  min-height: 22px;
  padding: 3px 4px;
  font-size: $font-size-small;
  font-weight: $badge-font-weight;
  color: $badge-color;
  line-height: $badge-line-height;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: $badge-bg;
  border-radius: $badge-border-radius;

  // Empty badges collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }

  // Quick fix for badges in buttons
  .btn & {
    position: relative;
    top: -1px;
  }

  .btn-xs &,
  .btn-group-xs > .btn & {
    top: 0;
    padding: 1px 5px;
  }

  // [converter] extracted a& to a.badge

  // Account for badges in navs
  .list-group-item.active > &,
  .nav-pills > .active > a > & {
    color: $badge-active-color;
    background-color: $badge-active-bg;
  }

  .list-group-item > & {
    float: right;
  }

  .list-group-item > & + & {
    margin-right: 5px;
  }

  .nav-pills > li > a > & {
    margin-left: 3px;
  }
}

//
// Contextual links that shall be available to users
// ---------------------------------------------------
%user-contextual-links {
  &.contextual-links-region-active {
    outline-width: 0;
  }
  .contextual-links-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;

    ul.contextual-links {
      top: 35px;
      border-radius: 0;
      padding: 0;

      li {
        a {
          margin: 0;
          @include vbf-rhythm-padding(.333);
          padding-left: .5rem;
          padding-right: .5rem;

          &:hover {
            background-color: #c9e5ff;
          }
          // mobile: bigger buttons for bigger fingers
          @media (max-width: $screen-md) {
            padding: 1rem;
            font-size: $font-size-h4;
          }
        }
      }
    }
    &.contextual-links-active {
      ul,
      .contextual-links-trigger {
        @include box-shadow(0 -4px 4px 1px rgba(0,0,0,.1));
        border-radius: 0;
      }
    }

  }
  .contextual-links-trigger {
    display: block;
    background-image: none;
    color: $blue;
    @include glyphicon-standalone($glyphicon-cog);
    text-indent: 0;
    height: 25px;
    outline-width: 0;
    display: block;
    &:before {
      margin-bottom: 10px;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}

%toggle-mobile-tabs {
  display: none;
  position: relative;
  margin-bottom: 0;
  padding: $grid-gutter-width/2;
  padding-right: $grid-gutter-width*1.5;
  text-align: right;
  font-size: rem($font-size-base);
  font-weight: normal;
  color: $blue;
  cursor: pointer;
  @include glyphicon-standalone($glyphicon-cog);

  &:before {
    position: absolute;
    right: $grid-gutter-width/2;
    top: 50%;
    @include translate(0, -50%);
    color: $blue;
  }
  @media (max-width: $screen-sm) {
    display: block;
  }
}

%toggled-mobile-tabs {
  @media (max-width: $screen-sm) {
    display: none;

    &.mobile-open {
      border-color: transparent;
      height: auto;
      display: block;
    }
    li {
      float: none;
      font-size: $font-size-h4;
      //
      a {
        border: 1px solid $nav-tabs-border-color;
      }

      &.active > a,
      &.active > a:focus,
      &.active > a:active {
        color: $gray-darker;
      }

    }
  }
}

%table-responsive-to-list {
  @media (max-width: $screen-sm) {
    border: none;

    table {
      max-width: 100%;

      thead {
        display: none;
      }
      tbody {
        tr {
          display: block;
          clear: both;
          @include vbf-margin-trailer;
          @include clearfix;
          max-width: 100%;
          background: white;
        }
        td {
          display: block;
          float: left;
          border: none;
          background: none;
          width: auto;
          @include padding-gutter-horizontal;
          @include padding-gutter;
        }
      }
    }
  }
}

%element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
