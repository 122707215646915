.view-vbf-taxonomy-term  {
  h5 {
    @include vbf-margin-trailer(0.25);
  }
  h2 {
    @include vbf-margin-trailer(2);
  }
  .views-row {
    @include vbf-padding-trailer(2);
    @include vbf-margin-trailer(2);
    border-bottom: 1px solid $gray-lighter;
  }
}