// Theme Customizations for CTAs/Buttons //

//
// AJAX, also matches links, not just buttons
// --------------------------------------------------
.progress-disabled {
  .ajax-progress-throbber {
    margin-left: rem(10);
    i.glyphicon {
      @include translate(0, -50%);
      &:before {
        content : $glyphicon-refresh;
      }
      &.glyphicon-spin {
        @include animated-throbber;
      }
    }
  }
}