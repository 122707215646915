
// ----------------------------------------------------
// Submitted block
// ----------------------------------------------------
#block-vbf-community-vbf-community-submitted,
#block-vbf-community-vbf-community-submitted-mobile {
  @media (max-width: $screen-sm) {
    display: none;
  }
  background: $gray-lighter;
  @include padding-gutter;
  @include vbf-margin-trailer(2);
  padding-top: $grid-gutter-width/2;
  padding-bottom: $grid-gutter-width/2;
  position: relative;
  min-height: rem(92);

  .arrow {
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    left: $grid-gutter-width;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    border-color: $gray-lighter transparent transparent transparent;
    transform: translateY(100%);
  }

  .field-profile-image {
    position: absolute;
    width: rem(62);
    height: rem(62);
  }
  .field-first-name {
    margin-left: rem(77);
    line-height: rem(18);
    max-width: 65%;
    overflow: hidden;
    text-overflow: ellipsis;
    @include vbf-margin-trailer(1);
  }
  .submitted-info {
    margin-left: rem(77);
    color: $gray-light;
    font-size: rem(12);
    display: block;
  }
}
#block-vbf-community-vbf-community-submitted-mobile {
  display: block;
  border-bottom: 1px solid $gray-lighter;

  @media (min-width: $screen-sm) {
    display: none;
  }
  .arrow {
    top: -16px;
    left: 16px;
    right: unset;
    border-left-color: transparent;
    border-bottom-color: white;
  }
}

.block-node-fields-block {
  .field {
    @include vbf-margin-trailer(.5);
  }
  .field-event-date {
    @include vbf-margin-trailer(1);
  }
}

// sidebar node-fields blocks
aside .block-node-fields-block {
  margin-bottom: 0 !important;
  h5 {
    font-size: rem(20);
    border-bottom: 1px solid $gray-light;
    color: $blue-dark;
    font-weight: $normal !important;
    @include vbf-padding-trailer(0.5);
    @include vbf-margin-trailer(1);
  }
  .field {
    @include vbf-margin-trailer(1);
  }
  &.field-sc-downloads,
  &.field-event-files {
    .file-icon {
      display: none;
    }
  }

  .field-event-location.field {
    margin-bottom: 0;

  }
}

// ----------------------------------------------------
// Fields blocks
// ----------------------------------------------------
// Event 3-column Blocks
.node-blocks-row-wrapper {
  @include make-row;
  .block {
    @include make-sm-column(4);
    @include vbf-margin-trailer(3);
  }
  @media (min-width: $screen-md) {
    .block:not(:last-child){
      padding-right: 0;
    }
    .block:not(:first-child){
      padding-left: 0;
    }
    .block-title,
    .content {
      @include padding-gutter;
    }
  }
  .block-title {
    border-bottom: 1px solid $gray-light;
    @include vbf-margin-trailer(1);
    @include vbf-padding-trailer(1);
    font-size: rem(20);
  }
  .field .field-label {
    color: $gray-dark;
  }
}

.node-blocks-row-wrapper-events {
  @include make-row;

  .block {
    @include make-sm-column(12);
    @include vbf-margin-trailer(2);
  }

  .block-title {
    padding-left: 0;
    padding-right: 0;
  }

  .content {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
      flex: 50%;
      margin-bottom: 28px;
    }
  }
}


// Event: Date and Location
#block-node-fields-block-3 {
  @include padding-gutter;
  @include vbf-rhythm-padding();
  background: $blue;
  color: white;
  display: flex;
  a {
    color: white !important;
  }
  h5,
  .field-label {
    font-size: rem(18);
  }
}