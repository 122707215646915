//
// Basics // rate widget
// --------------------------------------------------
.rate-widget {
  @include clearfix;

  .rate-button {
    @include make-bold;
    background-image: none;
    text-indent: 0;
    margin-right: $grid-gutter-width;
    display: block;
    float: left;
    font-size: rem($font-size-large);
    line-height: rem(40);
    position: relative;
    top: 1px;

    &.rate-plus-one {
      @include glyphicon($glyphicon-heart-empty, $gray-light, rem($font-size-large*1.2));
    }
    &.rate-minus-one {
      @include glyphicon($glyphicon-heart, $gray-light, rem($font-size-large*1.2));
    }
    &.rate-plus-one,
    &.rate-minus-one {
      &:before {
        position: relative;
        top: rem(3);
      }
    }
  }
  .rate-info {
    display: inline-block;
    line-height: rem(40);
    font-size: rem($font-size-large);
  }
}

.node:not(.node-teaser) {
  .rate-widget {
    background: white;
    @include padding-gutter;
    @include vbf-rhythm-padding(.125);
  }
}