@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./libs/slick/fonts/" !default;
$slick-font-family: "slick" !default;
$icon-font-family: "Glyphicons Halflings" !default;
$slick-loader-path: "../libs/slick/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: white !default;
$slick-dot-color-active: $blue-dark !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 12px !default;
$slick-dot-size-active: 18px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	}
	@else {
		@return url($slick-font-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 50px;
	width: 50px;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;
		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
	&:before {
		font-family: $icon-font-family;
		font-size: 40px;
		line-height: 1;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// @media (max-width: $screen-sm) {
		// 	color: #4182BE;
		// }
	}
}

.slick-prev {
	left: -5%;
	[dir="rtl"] & {
		left: auto;
		right: -5%;
	}
	&:before {
		content: $glyphicon-menu-left;
		[dir="rtl"] & {
			content: $glyphicon-menu-right;
		}
	}
}

.slick-next {
	right: -5%;
	[dir="rtl"] & {
		left: -5%;
		right: auto;
	}
	&:before {
		content: $glyphicon-menu-right;
		[dir="rtl"] & {
			content: $glyphicon-menu-left;
		}
	}
}

/* Dots */
.slick-dotted.slick-slider {}

.slick-dots {
	position: absolute;
	bottom: 1.5rem;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		margin: 0 2px;
		padding: 0;
		cursor: pointer;
		text-align: center;
		button {
			display: block;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			cursor: pointer;
			padding: $slick-dot-size/2;
			background: $slick-dot-color;
			text-align: center;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			border: 1px solid $blue-dark;
			@include border-radius($slick-dot-size-active);
			display: table-cell;
			vertical-align: middle;
			&:hover, &:focus {
				outline: none;
				background: $slick-dot-color-active;
			}
		}
		&.slick-active  button {
			background: $slick-dot-color-active;
			padding: $slick-dot-size-active/2;
		}
	}
}

@media (max-width: $screen-sm) {
	.slick-dots {
		bottom: .5rem;
		button {
			padding: $slick-dot-size/4;
		}
		li.slick-active  button {
			position: relative;
			top: 1px;
			padding: $slick-dot-size-active/4;
		}
	}
}
.node:not(.node-teaser) {
	.slick-slider {
		background: white;
		figcaption {
			background: white;
		}
	}
}
.slick-slider {
	figure {}
	figcaption {
		color: $gray-dark;
		padding: 0.5rem 1rem;
		font-size: rem(15px);
	}
}
