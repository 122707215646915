#block-vbf-community-vbf-community-comments {
  @include vbf-margin-leader(2);
  float: left;
  width: 100%;
}
#comments,
.page-comment {
  .indented {
    margin-left: rem(62 + $grid-gutter-width);

    @media (max-width: $screen-sm) {
      margin-left: rem(20);
    }

    .indented {
      margin-left: 0;
    }
  }
  h2.title:not(.comment-form) {
    display: none;
  }
  h2.comment-form {
    @include vbf-margin-trailer(1.5);
  }
  form {
    float: left;
    width: 100%;
    border-bottom: 1px solid $gray-light;
    @include vbf-margin-trailer(1);
    @include vbf-padding-trailer(1);
    .field-type-text-long label {
      display: none;
    }
    .grippie {
      display: none;
    }
    .form-textarea {
      box-shadow: 1px 1px 4px -2px rgba($black, 0.5);
      padding: $grid-gutter-width /2;
      resize: none;
      line-height: 1.2;
    }
  }
  > .comment {
    float: left;
    width: 100%;
    @include vbf-margin-trailer(1);
    @include vbf-margin-leader(1);
    .comment {
      @include vbf-margin-trailer(0.5);
      @include vbf-margin-leader(0.5);
    }
  }
  .comment {

    color: $gray;
    position: relative;

    .new {
      @include glyphicon($glyphicon-star, inherit, rem(12));
      @extend %badge;
      font-size: rem(12);
      font-weight: 300;
      background: $blue_darker;
      padding-left: rem(6);
      padding-right: rem(8);

      &:before {
        position: relative;
        top: rem(2);
      }
    }

    .field-profile-image {
      float: left;
      width: rem(62);
    }
    .comment-content {
      margin-left: rem(62 + $grid-gutter-width);
    }
    .submitted {
      font-size: rem(20);
      @include vbf-margin-trailer(.25);
    }
    .field-comment-body {
      @include vbf-margin-trailer(.333);
    }
    .list-inline {
      margin-right: $grid-gutter-width;
      display: inline-block;
      margin-left: 0;
      text-transform: uppercase;
      font-size: rem(12);
      white-space: nowrap;
    }
    .rate-widget {
      margin-right: $grid-gutter-width;
      display: inline-block;
      padding: 0;
      background: transparent;
      white-space: nowrap;


      .rate-button {
        margin-right: rem(5);
        top: 0;
        line-height: rem(12);
      }
      .rate-button,
      .rate-info {
        font-size: rem(12);
        line-height: rem(12);
      }
    }
    .date {
      font-size: rem(12);
      white-space: nowrap;
    }
  }
}
