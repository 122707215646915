.modal-content {
  border-radius: 10px;
  background-color: transparent;
}

.modal-header {
  background-color: $blue-dark;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 13px 20px;

  h3 {
    color: $white;
    margin-bottom: 0;
    padding: 0;
  }

  .close {
    width: 25px;
    height: 25px;
    background-color: $white;
    border-radius: 50%;
    margin-top: 5px;
    opacity: 1;

    span {
      font-size: 20px;
      line-height: 22px;
      display: block;
      height: 25px;
      font-weight: 100;
      color: $blue-dark;
    }
  }
}

.modal-body {
  padding: 0;

  p {
    font-size: 16px;
    font-weight: 500;
  }
}

#block-vbf-community-vbf-community-newsletter-info {
  padding: 30px 0;
  background-color: $white;

  @media (min-width: $screen-md) {
    padding: 40px 90px;
  }
}

#block-vbf-community-vbf-community-newsletter-info,
#block-vbf-community-vbf-community-newsletter-archive {
  > div {
    &:nth-child(2) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      a {
        text-align: center;
      }
    }
  }
}

#block-vbf-community-vbf-newsletter {
  background-color: $blue-dark;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  form {
    padding: 0 10px;

    @media (min-width: $screen-md) {
      padding: 0 90px;
    }
  }

  div.form {
    max-width: none;
    position: relative;
    padding: 18px 0;

    @media (min-width: $screen-md) {
      padding: 38px 0;
    }

    .form_border {
      border:none;
      background-color: transparent;
      padding: 0;

      @media (min-width: $screen-md) {
        padding: 10px;
      }
    }

    ul {
      &:after {
        content: ".";
        clear: both;
        display: block;
        visibility: hidden;
        height: 0px;
      }

      li {
        padding-left: 0;

        @media (min-width: $screen-md) {
          width: 51%;
          float: left;
        }
      }
    }

    label {
      color: $white;
      display: block;
      width: 100%;
      margin: 0;
      text-align: left;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.83;
    }

    label.focus {
      margin-top: 10px;

      @media (min-width: $screen-md) {
        position: absolute;
        right: 0;
        top:72px;
        width: calc(49% - 20px);
        padding-left: 20px;
        margin-top: 0;
      }
    }

    .form_button_submit {
      border-radius: 4px;
      background-color: $blue-darker;
      width: 100%;
      text-align: center;

      @media (min-width: $screen-md) {
        margin-right: -50%;
      }
    }
  }
}

#modalNewsletterArchive {
  .modal-body {
    padding: 20px;
    background-color: $white;
  }
}