body.og-context,
.page-node-community {
  .main-container-wrapper {
    [data-og-menu-toggle] {
      margin-top: 0.5em;
    }
  }
}

body.front .main-container-wrapper {
  @media (max-width: $screen-md) {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  h1 {
    @media (max-width: $screen-md) {
      margin-bottom: 30px;
    }
  }
}