#header {
	margin-bottom: 6em;
	background-image: linear-gradient(to right, #9fb3c9 50%, white 50%, white);
	@media (min-width: $screen-md) {
		margin-bottom: 0;
	}
}

#branding {
	height: 168px;
	// background: url("../images/VBF_Headergrafik.svg") no-repeat;
	background-color: #9fb3c9;
	
	background: url("../images/NEU_Blau_AmkA_Website_VBF_HeaderGrafik_2400px_dy150623.png") no-repeat;
	background-size: auto 168px;
	background-position: calc(50% - 190px) top;
	position: relative;

	@media (max-width: $grid-float-breakpoint-max) {
		background: url("../images/vbf_HeaderBackground_mobile.png") no-repeat;
		background-size: 100% auto;
		background-position: center top;
		height: 80px;
	}
	@media (min-width: 1920px) {
		// background-position: right top;
	}

	.container {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		padding-bottom: 20px;
		height: 100%;
		position: relative;
	}

	.logo {
		display: block;
		position: absolute;
		left: .9rem;
		top: -0.6rem;
		width: 138px;
		height: 138px;
		z-index: 2;
		img {
			position: relative;
			border-radius: 50%;
			box-shadow: 2px 10px 20px 0px rgba(0, 0, 0, 0.3);
			color: transparent;
		}
		@media (min-width: $grid-float-breakpoint) {
			width: 200px;
			height: 200px;
			left: rem(0);
			top: -12px;
		}
		@media (min-width: $screen-md-min) {
			left: rem(-82);
			width: 223px;
			height: 223px;
		}
		@media (min-width: $screen-lg) {
			left: rem(-65);
		}
	}

	#block-vbf-community-vbf-frankfurt-logo {
		order: 1;
		background: url("../images/Logo_StadtFFM_farbig.svg") no-repeat;
		background-size: 100% auto;
		height: 38.5833px;
		width: 270px;
		margin-left: auto;
		margin-bottom: 10px;
		> div {
			text-indent: -9999rem;
			overflow: hidden;
		}
		@media (max-width: $grid-float-breakpoint-max) {
			display: none;
		}
	}

	.block-vbf-community-vbf-community-user {
		position: relative;
		height: 100%;
		order: 5;
		display: flex;
		align-items: flex-end;
		padding-left: 15px;
		margin-left: 0;


		@media (max-width: $grid-float-breakpoint-max) {
			display: none;
		}

		.user-nav {
			position: relative;
			.field-profile-image {
				img {
					float: left;
					min-width: rem(60);
				}
			}
			.realname {
				display: none;
			}
		}
		.open a {
			background: transparent;
		}

		.dropdown-menu {
			top: rem(90);
			right: -5px;
			padding: 36px 15px;
			@include box-shadow(0 -4px 4px 1px rgba(0, 0, 0, 0.1));

			> li {
				background-color: $blue;

				> a {
					padding: 10px 40px;
					text-align: center;
				}

				&.active,
				&:hover {
					> a {
						text-decoration: underline;
					}
				}
			}

			> li + li {
				margin-top: 20px;
			}

			@media (max-width: $screen-md) {
				top: rem(100);
			}
		}
		.dropdown-arrow {
			top: rem(90 - $tooltip-arrow-width);
			left: 40%;
			@media (max-width: $screen-md) {
				top: rem(100 - $tooltip-arrow-width);
			}
		}
		// logged out state
		&.login-block {
			min-width: rem(135);
			.menu > li > a {
				&:before {
					content: url("../images/login.svg");
					display: block;
					transition: all 0.2s ease-out;
					width: rem(30);
					height: rem(30);
					margin-left: -10px;
					margin-top: 10px;
					transform-origin: center center;
				}
				display: flex;
				flex-flow: column;
				justify-content: center;
				align-items: center;
				padding: 0;
				&:hover:before {
					transform: scale(1.1);
				}
			}
			@media (min-width: $screen-md-min) {
				min-width: rem(120);
			}
			@media (min-width: $screen-lg-min) {
				min-width: rem(115);
			}
		}
		body.not-logged-in &.login-block {
			min-width: 0;
			@media (min-width: $screen-md-min) {
				min-width: 0;
			}
			@media (min-width: $screen-lg-min) {
				min-width: 0;
			}
		}
	}
	.block-vbf-community-vbf-community-site-slogan {
		flex: 1 0 28%;
		max-width: 28%;
		order: 1;
		font-family: $font-family-sans-serif;
		color: $blue-dark;
		display: flex;
		align-items: center;
		margin: 0 auto 0 141px;

		p {
			font-size: rem(14);
			line-height: 1.25;
			margin: 0;
			font-family: $font-family-sans-serif;
			font-weight: 300;
			max-width: 17em;
		}

		@media (max-width: $grid-float-breakpoint-max) {
			display: none;
		}
	}

	.block-vbf-community-vbf-community-amka {
		order: 2;
		padding: 0 $grid-gutter-width / 2;
		margin-left: 0;

		@media (max-width: $grid-float-breakpoint-max) {
			display: none;
		}
		.nav > li a {
			display: block;
			padding: 0;
		}
		#amka-logo {
			color: transparent;
			font-size: 0;
			display: block;
			@include size(241px, 53px);
			background-image: url(../images/amka.png);
			background-position: center center;
			background-repeat: no-repeat;
			background-color: transparent;
			background-size: contain;
			margin-bottom: 10px;
			@media (max-width: $screen-md) {
				@include size(241px, 56px);
			}
		}
	}

	.nav > li > a {
		&:hover,
		&:active,
		&:focus {
			background: none;
		}
		// contains profile image and arrow
		&.dropdown-toggle {
			color: $gray-light;
			display: flex;
			align-items: center;
			padding-right: 0;
			span.glyphicon {
				margin-left: 1ch;
				// margin-left: rem(15);
			}
		}
	}
}

#sidebar-toggle {
	right: 0;
}

.dropdown-toggle .glyphicon {
	margin-left: 1ch;
}