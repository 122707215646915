//
// Node Type Adv Poll
// --------------------------------------------------
.node-advpoll {
  .poll-bar {
    @include clearfix;
    @include vbf-margin-trailer(.25);
  }
  .poll .voted .bar {
    border: none;
  }
  .poll .bar {
    width: 90%;
    float: left;
    @media (max-width: $screen-xs){
      width: 85%;
    }
  }
  .poll .percent {
    width: 10%;
    float: left;
    clear: right;
    text-align: right;
    font-size: rem(12);
  }
  .poll-message {
    text-align: center;
    font-size: rem(12);
  }
  .poll .total {
    @include vbf-margin-leader(.5);
    float: left;
    width: 100%;
  }
  .poll .text {
    @include vbf-margin-trailer(.25);
  }
  .poll button {
    text-transform: uppercase;
    width: 47.5%;

    &:first-of-type {
      margin-right: $grid-gutter-width/4;
    }
    &:last-of-type {
      margin-left: $grid-gutter-width/4;
    }
    &:only-of-type {
      @include vbf-margin-leader(1);
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  // err
  div[id^=message] {
    clear: both;
    float: none;
    @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
    @include padding-gutter;
    @include padding-gutter-horizontal(.25);
    border: 1px solid $alert-danger-border;
    position: relative;
    top: 1rem;
  }

  .poll,
  form[id^=advpoll-form-] {
    @include clearfix;
    padding:  $grid-gutter-width;
    color: $white;
    @media (max-width: $screen-sm) {
      margin-left: 0;
      margin-right: 0;

      button {
        display: block;
        border-collapse: collapse;

        &[id^=edit-submit-advpoll-] {
          float: left;
          width: 50%;
          border-width: 0;
          border-right-width: 2px;
          border-style: solid;
          border-color: transparent;
        }
        &[id^=edit-submit-show-results-] {
          float: left;
          width: 50%;
          border-width: 0;
          border-left-width: 2px;
          border-style: solid;
          border-color: transparent;
        }
      }
    }
  }

  form {
    > div > .form-item {
      @include vbf-margin-trailer(1.5);
      > label.control-label {
        display: none;
      }
      label {
        font-weight: 300;
        color: white;
      }
    }
  }

  button {
    width: 46.85%;
  }
  // full view
  &:not(.node-teaser) {
    @include vbf-margin-trailer(2);
    .field-poll-question {
      @include vbf-margin-trailer;
      font-size: rem(30);
    }
    form,
    .poll {
      background: $blue;
    }
  }
  // teaser
  &.node-teaser {
    .poll,
    form[id^=advpoll-form-] {
      padding-top: 0;
    }
  }
}
