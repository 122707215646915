// fill with selectors that diefferentiate your color scheme
// optimally ids
$sc-colors-selectors: 'body.blue' 'body.violet' 'body.red' 'body.orange' 'body.cyan' 'body.green' 'body.lime' 'body.brown' 'body.gray';
// define primary color for each selector
$sc-colors-primary: $blue-dark #4b1478 #91223c #bf360c #0097a7 #558511 #5ea609 #5d4037 #4d5e70;
// define secondary color for each selector
$sc-colors-secondary: $blue #7f3f98 #cd2d37 #f15a24 #00bcd4 #6caa26 #7ad70b #79553e #78849c;
// define secondary color for each selector
//$sc-colors-secondary-transparent: rgba(65, 130, 190, .75) rgba(255, 84, 80, .75) rgba(204, 78, 200, .75);
// define tertiary color for each selector
$sc-colors-tertiary: #cfe0ef #dfcfe5 #f2cacd #fbd6c8 #bfeef4 #daeac9 #def5c2 #ddd4cf #dde0e6;
//
$sc-colors-force-primary: #navbar-secondary 'aside > .region h1, section > .region h1' 'aside > .region h2' 'aside > .region h3';


@each $sc-colors-current-selector in $sc-colors-selectors {
  $i: index($sc-colors-selectors, $sc-colors-current-selector);
  $primary: nth($sc-colors-primary, $i);
  $secondary: nth($sc-colors-secondary, $i);
  //$secondary-transparent: nth($sc-colors-secondary-transparent, $i);
  $tertiary: nth($sc-colors-tertiary, $i);

  #{$sc-colors-current-selector} {

    #page-header,
    .main-container {
      // links an navigation
      .group.manager,
      a {
        color: #{$secondary} !important;
        border-color: #{$secondary};
      }
      .navbar-nav.secondary li.active {
        border-color: #{$primary};
      }
      .pagination > .active > span {
        background: #{$secondary};
        border-color: #{$secondary};
      }
      @each $sc-colors-current-force-primary in $sc-colors-force-primary {
        #{$sc-colors-current-force-primary} {
          color: #{$primary};
          a {
            color: #{$primary};
          }
        }
      }

    }
    #navbar-secondary,
    #block-vbf-og-menu-vbf-og-menu-single {
      .glyphicon:before {
        color: #{$primary};
      }
      a {
        &:hover,
        &.active {
          color: #{$primary} !important;
          .glyphicon {
            color: #{$primary} !important;
          }
        }
      }
    }
    // contextual links
    .contextual-links-trigger {
      color: #{$secondary};
      border-width: 0;
    }
    .contextual-links-active {
      .contextual-links-trigger {
        color: #{$secondary} !important;
        border-top-width: 1px;
        border-left-width: 1px;
        border-right-width: 1px;
        border-color: #ccc !important;
      }
    }
    .contextual-links-wrapper {
      ul li a:hover {
        background: #{$tertiary}
      }
    }

    #block-blockify-blockify-actions {
      .action-links-single-wrapper  li a,
      #add-node-toggle {
        color: #{$primary} !important;
        &:before {
          background: #{$secondary};
        }
        &:hover {
          color: $white !important;
          background: #{$primary};
          &:before {
            background: #{$primary};
          }
        }
      }
    // create content links

      .action-links-multiple-wrapper {
        ul {
          background: #{$tertiary};
          li a {
            background: #{$secondary};
            &:hover {
              background: #{$primary};
            }
          }
        }
      }
    }
    // primary tabs
    .primary-tabs-header {
      color: #{$secondary};
    }
    .tabs--primary {
      &.nav-tabs > li {
        &.active > a,
        &.active > a:focus,
        &.active > a:active {
          color: #{$tertiary};
          border-color: #{$tertiary};
        }
        > a {
          color: #{$primary};
          border-color: #{$primary};
          &:hover {
            background: #{$secondary};
            color: white !important;
          }
        }
      }

    }
    // vertical tabs (mobile)
    @media (max-width: $screen-md) {
      .tabs-left {
        > .nav-tabs > li {
          &.active > a,
          &.active > a:focus,
          &.active > a:active {
            color: $gray-darker;
          }
        }
      }
    }
    // slick slider
    .slick-dots {
      li {
        button {
          border-color: #{$primary};
        }
        &.slick-active {
          button {
            background: #{$primary};
          }
        }
      }
    }
    // node
    .node,
    .entity-paragraphs-item {
      h1, h2, h3, h4, h5, h6 {
        color: #{$primary};
      }
      &:not(.node-teaser) {
        h5 {
          color: $gray-light;
        }
      }
      &.node-teaser {
        h5 {
          color: #{$secondary} !important;
        }
      }
    }

    // Polls
    article.node-advpoll {
      &.node-teaser {
        .node-main > .content:not(.submission-info) {
          background: #{$secondary};
          background: url('../images/museo_questionmark_white.svg') no-repeat right top #{$secondary};
          background-size: 120px auto;
          @media (min-width: $screen-md) {
            background-size: 150px auto;
          }
        }
      }
      .bar {
          background: #{$tertiary};
        }
      .foreground {
        background: #{$primary};
      }
      .poll,
      form {
        // background: #{$secondary};
      }
      button {
        background: #{$primary};

        &[id^=edit-submit-show-results-],
        &[id^=edit-submit-advpoll-] {
          border-left-color: #{$secondary};
          border-right-color: #{$secondary};
        }
      }
      &:not(.node-teaser) {
        .field-poll-question {
          color: #{$primary};
        }
      }
    }

    // Events
    article.node-event {
      .event-date-title,
      .event-date-blue-box {
        background: #{$secondary};
        color: $white;
      }
      &.node-teaser {
        section.node-main .content .event-date-location {
          @media (max-width: $screen-sm - 1) {
            .day-time-location {
              border-top-color: #{$tertiary};
            }
          }
        }
      }
    }
    article.node-media-content {
      &.node-teaser {
        .node-main > .content:not(.submission-info) {
          background: #{$primary};
        }
      }
    }

    // teasers with colour background
    article.node-advpoll,
    article.node-media-content {
      .background-icon {
        color: #{$tertiary};
      }
      &.node-teaser {
        .contextual-links-trigger {
          color: white;
        }
        .node-main .content:not(.submission-info) {
          color: white;
        }
        a.contextual-links-trigger:not(.contextual-links-trigger-active):before,
        .poll a,
        h2, h2 a, h5 {
          color: white !important;
        }
        a.contextual-links-trigger:hover {
          background: $white;
        }
      }
    }

    // teasers
    article.node-teaser {
      h5 {
        color: #{$secondary};
      }
      footer {
        > .content {
          .list-inline {
            border-color: #{$tertiary};
          }
        }
      }
    }

    // comments
    #comments,
    .page-comment {
      .new {
        background: #{$primary};
      }
      .field-name-comment-body textarea {
        border-color: #{$secondary};
      }
    }
    h2.comment-form {
      color: #{$primary};
    }
    // node fields blocks
    .block-node-fields-block {
      h5,
      .block-title {
        color: #{$secondary};
      }
    }
    #block-node-fields-block-3 {
      background: #{$secondary};
    }
    #block-node-fields-block-6 {
      span.file {
        &:before {
          color: #{$secondary};
        }
      }
    }
    // forms
    .main-container .chosen-container {
      a.chosen-single {
        border-color: $gray-light !important;
      }
    }
    // horizontal tabs
    .main-container div.horizontal-tabs li.horizontal-tab-button:not(.selected) {
      background: #{$secondary};
      a {
        color: $white !important;
      }
    }
    .main-container div.horizontal-tabs li.horizontal-tab-button a:hover {
      background: #{$primary};
    }
  }
}

a.node_event_blue_box_link {
  &:hover {
    text-decoration: none;
  }
}
