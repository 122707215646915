// Theme customizations to paragraph entities //

.paragraphs-item-teaser {
  @include make-xs-column(12);
  @include make-md-column(4);
  @include vbf-margin-trailer(1);

  .content--teaser-image,
  .field-teaser-image,
  .field-item {
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .field-teaser-title,
  .field-teaser-body,
  .field-teaser-title a {
    color: white;
    font-size: rem(17px);
    line-height: rem(21px);
  }
  .content--teaser-content {
    width: 100%;
    background-color: rgba($blue, 0.9);
    padding: 15px;
    p {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 992px) {
    &:hover {
      .content--teaser-content {
        transform: translateY(0);
      }
    }
    .content {
      position: relative;
      padding-bottom: (287 / 330 * 100%);
    }
    .content--teaser-inner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
    .content--teaser-image,
    .field-teaser-image,
    .field-item {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content--teaser-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($blue, 0.9);
      padding: 20px 14px 0 14px;
      transform: translateY(calc(100% - 85px));
      transition: transform 0.6s ease-in-out;
      pointer-events: none;
    }

    .field-teaser-title {
      @include vbf-margin-trailer(1.5);
    }
  }
  // Main column
  .view-vbf-og-main-teaser & {
    background: white;
    margin-bottom: 0;
    @include vbf-padding-trailer(0.25);

    .field-teaser-title,
    .field-teaser-body,
    .field-teaser-link {
      padding: 0 $grid-gutter-width/2;
    }
    .field-teaser-title {
      margin: 0;
      border: 0;
    }
    .field-teaser-title {
      @include vbf-margin-leader(1);
      @include vbf-margin-trailer(0.5);
      @include wordwrap();
    }
    .field-teaser-image {
      img {
        width: 100%;
      }
    }
  }
}

.contact-reference {
  @include vbf-margin-leader(2);
  @include vbf-margin-trailer(2);
  height: rem(62);

  .field-profile-image {
    position: absolute;
    width: rem(62);
    height: rem(62);
  }
  .field-first-name {
    margin-left: rem(77);
    text-transform: uppercase;
    @include make-bold;
    line-height: rem(18);
    max-width: 65%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .contact-position {
    margin-left: rem(77);
    color: $gray-light;
    font-size: rem(12);
    display: block;
  }
}

.paragraphs-item-text-headline {
  .field-title {
    font-size: rem(30);
    line-height: rem(36);
  }
  .field-first-paragraph {
    font-size: rem(17);
  }
}

.field-landing-page-content {
  padding-right: 40px;
}
