// -----------------------------------------------
// Make parent's theme variable override available
// and bootstrap variables / mixins
// -----------------------------------------------

// Bootstrap Framework variables (overrides).
@import "../../vbf_base_theme/sass/base/variable-overrides";
//@import "variable-overrides";

// Bootstrap Framework variables.
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";

// Bootstrap Framework mixins.
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";
// -----------------------------------------------
// Do not import bootstrap for a second time
// this would blow up the stylesheet unnecessarily
// -----------------------------------------------

/*
// Reset
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";

// Core CSS
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";

@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";

@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../vbf_base_theme/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";
*/
