// Theme Customizations to Nodes //

//
// Blocks, Nodes // contextual links (edit|delete|...)
// ---------------------------------------------------
.node {
  @extend %user-contextual-links;
  header {
    h5 {
      text-transform: uppercase;
      @include vbf-margin-trailer(1);
    }
  }
}

//
// Node Full view
// --------------------------------------------------
article.node:not(.node-teaser) {
  .field-group-content-access {
    position: absolute;
    top: $grid-gutter-width / 2;
    right: $grid-gutter-width / 2;
  }
  .field-body {
    .field-item {
      font-size: rem(20);
      p {
        margin-bottom: rem(25);
      }
    }
  }
  &.has-first-paragraph {
  }
}

//
// Node Type Event
// --------------------------------------------------
.main-container .node-event {
  .event-date-blue-box {
    display: flex;
    background: $blue;
    color: $white;
    padding: rem(20);
    a {
      color: $white !important;
    }
    .date-wrapper {
      display: flex;
      margin-right: $grid-gutter-width;
      align-items: baseline;
      flex: 0 0 auto;
    }
    .months-differ {
      display: flex;
    }
    .day-digits,
    .months-through {
      font-size: rem(40);
      line-height: rem(40);
      @media (max-width: $screen-xs) {
        font-size: rem(22);
      }
    }
    .month-strings {
      text-align: center;
      text-transform: uppercase;
    }
    .title-column {
      flex: 1 0 62%;
    }
  }

  .event-date-title {
    h1,
    h4 {
      color: $white !important;
      @include vbf-margin-trailer(0.25);
      font-size: rem(20);
      font-weight: $bold;
      @media (max-width: $screen-sm) {
        word-wrap: break-word;
      }
    }
    .ics {
      display: flex;
      margin-left: auto;
      align-items: last baseline;
      flex-flow: row wrap;
      justify-content: flex-start;
      margin-top: auto;
      padding-top: rem(12);
      span {
        transform: translateY(2px);
        margin-right: $grid-gutter-width / 4;
      }
    }
  }
  // Full View Styles
  &:not(.node-teaser) {
    @include vbf-margin-trailer(1);
    .event-date-blue-box {
      padding: $grid-gutter-width;
    }
    .field-body {
      @include vbf-margin-leader(2);
    }
  }
}

//
// Node Type Media
// --------------------------------------------------
.node-media-content {
  .file-field-download-link {
    text-transform: uppercase;
  }
  .field-media-content-file {
    @include vbf-margin-trailer(1);
  }
  &:not(.node-teaser) {
    .field-media-content-file {
      @include make-row;
      .field-item {
        @include make-md-column(4);
      }
    }
  }
}

//
// Image / Video handling
// --------------------------------------------------
.node-page,
.node-post,
.node-media-content {
  &:not(.node-teaser) {
    .embedded-video,
    .image {
      @include vbf-margin-trailer(1.75);
      img {
        width: 100%;
      }

      .slick-dots {
        bottom: 0;
        transform: translateY(100%);
      }
    }

    video {
      @include vbf-margin-trailer(1.5);
    }

    video + .field_video_description {
      margin-top: -35px;
      @include vbf-margin-trailer(1);
    }
  }
}

.node-type-event {
  .image {
    .slick-dots {
      bottom: 3.5rem !important;
    }
  }
}
