//////////////////////////
// Font Declarations
//////////////////////////

@font-face {
	font-family: 'museo';
	src: url('../fonts/Museo-300/Museo-300.eot');
	src: url('../fonts/Museo-300/Museo-300.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/Museo-300/Museo-300.otf')  format('opentype'),
	     url('../fonts/Museo-300/Museo-300.woff') format('woff'),
	     url('../fonts/Museo-300/Museo-300.ttf')  format('truetype'),
	     url('../fonts/Museo-300/Museo-300.svg#Museo-300') format('svg');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'museo';
	src: url('../fonts/Museo-500/Museo-500.eot');
	src: url('../fonts/Museo-500/Museo-500.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/Museo-500/Museo-500.otf')  format('opentype'),
			 url('../fonts/Museo-500/Museo-500.woff') format('woff'),
			 url('../fonts/Museo-500/Museo-500.ttf')  format('truetype'),
			 url('../fonts/Museo-500/Museo-500.svg#Museo-500') format('svg');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'museo';
	src: url('../fonts/Museo-700/Museo-700.eot');
	src: url('../fonts/Museo-700/Museo-700.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/Museo-700/Museo-700.otf')  format('opentype'),
	     url('../fonts/Museo-700/Museo-700.woff') format('woff'),
	     url('../fonts/Museo-700/Museo-700.ttf')  format('truetype'),
	     url('../fonts/Museo-700/Museo-700.svg#Museo-700') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'museo-sans';
	src: url('../fonts/MuseoSans-300/MuseoSans-300.eot');
	src: url('../fonts/MuseoSans-300/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans-300/MuseoSans-300.otf')  format('opentype'),
	     url('../fonts/MuseoSans-300/MuseoSans-300.woff') format('woff'),
	     url('../fonts/MuseoSans-300/MuseoSans-300.ttf')  format('truetype'),
	     url('../fonts/MuseoSans-300/MuseoSans-300.svg#MuseoSans-300') format('svg');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'museo-sans';
	src: url('../fonts/MuseoSans-500/museosans_500-webfont.eot');
	src: url('../fonts/MuseoSans-500/museosans_500-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/MuseoSans-500/museosans_500-webfont.woff') format('woff'),
	     url('../fonts/MuseoSans-500/museosans_500-webfont.ttf') format('truetype'),
	     url('../fonts/MuseoSans-500/museosans_500-webfont.svg#museo_sans_500regular') format('svg');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'museo-sans';
	src: url('../fonts/MuseoSans-700/MuseoSans-700.eot');
	src: url('../fonts/MuseoSans-700/MuseoSans-700.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/MuseoSans-700/MuseoSans-700.otf')  format('opentype'),
	     url('../fonts/MuseoSans-700/MuseoSans-700.woff') format('woff'),
	     url('../fonts/MuseoSans-700/MuseoSans-700.ttf')  format('truetype'),
	     url('../fonts/MuseoSans-700/MuseoSans-700.svg#MuseoSans-700') format('svg');
	font-weight: 700;
	font-style: normal;
}
