// Theme Customizations to Forms/Webforms //

//
// selects
// --------------------------------------------------
select.form-control {
  -webkit-appearance: none;
  border-radius: 0;
  border-bottom-left-radius: 0;
}
.webform-container-inline {
  div.chosen-container {
    display: inline-block;
  }
  &.webform-component-textarea .form-textarea-wrapper {
    display: block;
  }
}

//
// entity reference form widgets
// --------------------------------------------------
.field-type-entityreference {
  .table-responsive {
    overflow: visible;
  }
}

//
// draggable tables
// --------------------------------------------------

.tabledrag-toggle-weight-wrapper {
  display: none;
}
.field-multiple-table {
  > thead > tr > th {
    border: none;
    padding: 0;

    label {
      margin: 0;
    }
  }
  > tbody > .draggable {
    background: none;

    &:nth-of-type(2n + 1),
    &:hover {
      background: none;
    }
    .tabledrag-handle {
      .handle {
        background-image: none;
        @include glyphicon-standalone($glyphicon-move, $gray-dark);
      }
    }
    td {
      padding: rem(4) 0;
      border: none;

      .form-item {
        margin: 0;

        label {
          margin: 0;
        }
        label:empty {
          display: none;
        }
      }
    }
    .field-multiple-drag {
      vertical-align: middle;
    }
  }
}

//
// files
// --------------------------------------------------
.form-managed-file input.form-file {
  padding: 0;
  line-height: 3rem;
}
.form-textarea-wrapper {
  display: block;
}

//
// user-profile-form
// --------------------------------------------------
#user-profile-form {
  .collapse {
    display: block;
    opacity: 1;
  }
}

fieldset[id^="edit-spamicide"] {
  display: none;
}

//
// Image widget
// --------------------------------------------------
.form-managed-file.image-widget {
  display: flex;

  .image-widget-data {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .focal-point-help {
      font-size: rem(13);
    }
    .form-group {
      flex-grow: 1;
    }
  }

  @media (max-width: $screen-sm) {
    display: block;

    .image-widget-data {
      display: inline;

      .form-group {
        display: flex;
        align-items: center;
      }

      .form-group + .form-group {
        margin-top: 10px;
      }
    }

    .form-control {
      padding: 8px 12px;
      width: calc(100% - 24px);
    }

    .focal-point-help {
      display: none;
    }
  }
}

//
// Horizontal Tabs by Fieldgroup
// --------------------------------------------------
div.horizontal-tabs {
  margin: 0;
  border: 0;
  ul.horizontal-tabs-list {
    background: transparent;
    display: flex;
    border: 0;
  }
  li.horizontal-tab-button {
    background: $blue;
    border: none;
    a {
      color: $white;
      &:hover {
        background: $blue-dark;
        strong {
          color: $white;
        }
      }
    }
    strong {
      font-weight: $normal;
    }
    &.selected {
      border: 1px solid $gray-light;
      border-bottom: 0;
      strong {
        color: $gray;
        font-weight: $normal;
      }
    }
  }
  .horizontal-tabs-panes {
    padding-top: $grid-gutter-width/2;
    background: $white;
    border: 1px solid $gray-light;
    margin-top: -1px;
  }
  fieldset.horizontal-tabs-pane {
    padding: $grid-gutter-width/ 2;
  }
}

.field-group-htabs-wrapper + .form-actions {
  margin-top: 0.5rem;
}

//
// Misc Tweaks
// --------------------------------------------------

.form-actions #edit-cancel {
  margin-left: 10px;
}

.form-control {
  border-color: $blue;
}

%label_item {
  transition: all 0.1s ease-in;

  position: absolute;
  top: 10px;
  left: 12px;
  cursor: text;
  font-weight: 300;
  font-size: rem(14);
  line-height: rem(24);
}

#edit-field-event-date-today-tomorrow {
}

.form-item:not(.form-type-checkbox):not(.form-type-textarea):not(.form-item-field-event-date-today-tomorrow):not(#edit-intro):not(.date-group):not(.time-group):not(.form-type-managed-file):not(.form-type-radio):not(.form-autocomplete):not(.form-type-select) {
  position: relative;
  margin-bottom: 40px;

  > label {
    @extend %label_item;
  }

  &.focus {
    > label {
      top: -18px;
      left: 2px;
      font-size: rem(12);
      line-height: rem(18);
    }
  }

  &.form-item.form-type-select {
    > label {
      @media (max-width: $screen-sm) {
        position: relative;
        top: auto;
        left: auto;
      }
    }
  }
}

.form-control {
  padding: 8px 12px;
  height: 44px;
}

.form-type-checkbox {
  position: relative;
  padding-left: 20px;

  label {
    &:before {
      position: absolute;
      left: 0;
      top: -2px;
      content: "";
      display: block;
      border: 1px solid $blue;
      width: 30px;
      height: 30px;
      background-color: white;
      font-weight: 100;
    }

    &:after {
      display: none;
      position: absolute;
      left: 0;
      top: -2px;
      content: "X";
      background-color: $blue;
      width: 30px;
      height: 30px;
      color: white;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }
  }

  input[type="checkbox"] {
    opacity: 0;
  }

  &.active label {
    &:after {
      display: block;
    }
  }
}

.form-item.form-type-managed-file.form-group.form-item-field-video-und-0 {
  > label {
    position: relative;
    top: auto;
    left: auto;
    cursor: text;
    //font-size: 1.33333rem;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
    font-weight: 500;
    color: #333333;
  }
}

.field-widget-image-image.form-group {
  .panel-title {
    margin-bottom: 0.8rem;
  }
}

table.managed-files.table-bordered {
  > tbody > tr > td {
    @media (max-width: 768px) {
      vertical-align: top;
    }
  }
}

.form-fields-wrapper legend.panel-heading {
  margin-bottom: 0.5em;
}

table.managed-files {
  tr.ajax-new-content {
    td:nth-child(3) {
      vertical-align: text-bottom;
      padding-top: 32px;
    }
  }
}

.field-name-field-hide-fullname {
  margin-bottom: 30px;
}

select.input-sm {
  @media (max-width: $screen-sm) {
    height: auto;
  }
}

.node-form .form-autocomplete label {
}

/*
.webkit-browser .form-managed-file input.form-file {
  padding-top: 0 !important;
}*/

.form-managed-file {
  .file {
    margin-top: 10px;
  }

  .file-size {
    margin-top: 10px;
  }

  .form-item-field-video-und-1-display {
    .control-label {
      position: relative;
      padding-left: 38px;

      &:before {
        left: 3px;
        top: -6px;
      }

      &:after {
        left: 3px;
        top: -6px;
      }
    }
  }

  .form-item-field-video-und-1-description {
    margin-top: 20px;
  }
}
/*
.views-exposed-form .form-item {
  &.form-type-datepicker {
    margin-top: 20px;
    //workaround ... lets see
    margin-left: 1px;
    margin-right: -2px;
  }
}*/

label[for="edit-field-www-und-0"] {
  z-index: 1;
}

.field-name-field-video {
  min-height: 20px;
}

div.addressfield-container-inline > div.form-item {
  float: none;
}

#user-login--3 {
  a[href*="password"] {
    position: relative;
    z-index: 2;
  }
}
