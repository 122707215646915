#block-vbf-community-vbf-landing-page-links {
  position: relative;
  @media (min-width: $screen-md-min) {
    min-height: 350px;
    margin-top: rem(70);
  }
  &:before {
    content: url("../images/Swirl_white25.svg");
    position: absolute;
    bottom: 0;
    height: 100%;
    right: 0%;
    width: rem(836);
    z-index: -1;
    height: auto;
    @media (min-width: $screen-md-min) {
      top: rem(-109);
      right: 7%;
      bottom: auto;
    }
    @media (min-width: $screen-lg-min) {
      right: 18%;
    }
  }
  .nav {
    li {
      a {
        display: flex;
        color: #296192;
        font-weight: $bold;
        line-height: 1.294;
        font-size: rem(17);
        align-items: center;
        transition: all 0.5s ease;
        max-width: 80%;
        @include vbf-rhythm-padding(0.4);
        @media (min-width: $screen-md-min) {
          margin-left: 15%;
        }
        &:before {
          transition: all 0.5s ease;
          content: "";
          background: url("../images/vbf_AmkA_Picto_Veranstaltungen_white.svg") no-repeat center center;
          background-color: #296192;
          height: 60px;
          width: 60px;
          border-radius: 100%;
          display: block;
          margin-right: rem(20);
          flex: 0 0 auto;
          @media (min-width: $screen-sm) {
            height: 100px;
            width: 100px;
          }
        }
        &:hover {
          color: $blue;
          &:before {
            background-color: $blue;
          }
        }
        &.communities-link:before {
          background-image: url("../images/vbf_AmkA_Picto_Pinnwand_white.svg");
        }
      }
    }
  }
}

.node-type-landing-page {
  #block-views-communities-block-1 {
    .block-title {
      display: none;
    }
  }
}
