.node-form {
  .webkit-browser & {
    .date-form-element-content .container-inline-date  input { margin-top: 0; top: 1px;  }
  }

  .field-name-body textarea {
    height: 150px;
  }

  @media only screen and (min-width: $screen-md-min)  {
    .container-inline-date {
      .input-group {
        float: left;
        width: 49%;
        &.time-group {
          margin-left: 1%;
        }
        &.date-group {
          margin-right: 1%;
        }
      }
    }
  }
  #edit-media-input {
    margin-bottom: 0;
  }

  // -- draggable tables in forms
  .table-responsive {
    border: none;
  }
  table {

    th, td {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    th {
      font-size: 1rem !important;
      border-width: 1px !important;
    }
    td .btn {
      float: right;
    }
  }
  a.panel-title {
    @include glyphicon-after($glyphicon-menu-up);
    display: flex !important;
    justify-content: space-between;
    &:hover,
    &:active {
      text-decoration: none;
    }
    &.collapsed {
      &:after {
        content: $glyphicon-menu-down;
      }
    }
  }
  .field-name-vbf-group-content-promote-opt-in,
  .bordered-container {
    @include vbf-padding-leader(0.5);
    @include vbf-padding-trailer(0.5);
    margin: 0;
    border-bottom: 1px solid $gray-medium;
  }
}

.node-event-form {
  .field-name-body{
    border-bottom: 1px solid $gray-medium;
    @include vbf-padding-trailer(1);
    @include vbf-margin-trailer(2);
  }
  .field-name-field-other-text,
  .field-name-field-phone {
    border-bottom: 1px solid $gray-medium;
    @include vbf-padding-trailer(1);
  }
}