// Theme Customizations to Navigations //

//  Secondary Nav: Notification Badges
// --------------------------------------------------

#navbar-secondary {
  margin-bottom: 0;
  border-bottom: 1px solid $blue-gray;
}

//
// Tabs Primary
// --------------------------------------------------
.tabs--primary {
  border-bottom: none;
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  &:after,
  &:before {
    display: none;
  }
  li {
    display: inline-block;
    float: none;
    margin-bottom: 0;

    a {
      background: white;
      border: 1px solid $blue;
      display: inline-block;
      float: none;
      padding: 0px 10px;
      line-height: 1.9;
      margin-right: 1rem;
      transition: all 0.15s ease-out;

      &[href*="delete2"] {
        display: none;
      }

      &[href*="comment"] {
        display: none;
      }
    }
  }
}

.region-navigation {
  display: flex;

  #block-vbf-community-vbf-community-user {
    .user-nav {
      width: auto;
    }
    .dropdown {
      position: relative;
      .dropdown-menu {
        position: absolute;
        width: auto;
        li {
          margin: 0;
          a {
            padding: 0 15px;
            &::after {
              left: 0;
              width: 100%;
            }
          }
        }
      }
    }

    a {
      color: rgba(255, 255, 255, 0.75);
    }


  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    #block-vbf-community-vbf-community-user {
      border-top: 0.06667rem solid #0e3262;
    }
  }
  @media screen and (min-width: 768px) {
    > section {
      margin: 0 calc(0.56667rem * 2);
      &:last-child {
        margin-right: 0;
        @media(min-width: $screen-lg-desktop) {
          margin-right: 15px;
        }
      }
    }
    .field-profile-image {
      display: none;
    }
  }
}

//
// OG Subpages Menu
// --------------------------------------------------
#navbar-secondary,
.block-vbf-og-menu {
  .expanded {
    &.open {
      ul {
        display: block;
      }
      a {
        background: none;
      }
    }
    ul {
      display: none;
    }
  }
}

//
// Pagination
// --------------------------------------------------
.pagination {
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;

  > li {
    display: inline-flex;
    &.pager-first,
    &.pager-last,
    &.next,
    &.prev {
      font-size: 0;
      a {
        display: flex;
      }
    }
    &.pager-first a {
      @include glyphicon($glyphicon-menu-left + $glyphicon-menu-left);
    }

    &.prev a {
      @include glyphicon($glyphicon-menu-left);
    }
    &.next a {
      @include glyphicon($glyphicon-menu-right);
    }
    &.pager-last a {
      @include glyphicon($glyphicon-menu-right + $glyphicon-menu-right);
    }

    @media (max-width: $screen-sm) {
      display: none;

      &.pager-first,
      &.prev,
      &.pager-ellipsis,
      &.active,
      &.next,
      &.pager-last,
      &:nth-child(2),
      &:nth-child(3) {
        display: block;
      }

      &.pager-first,
      &.prev,
      &.active,
      &.next,
      &.pager-last {
        a {
          padding-top: 10px;
          padding-bottom: 9px;
        }
      }
    }
  }
}

ul.pager--infinite-scroll.pager > li > a {
  background-color: $blue_dark;
  color: $white !important;
  border: none;
  border-radius: 0;
  transition: all 0.3s ease-out;
  padding: 1.5% 5%;
  &:hover {
    background-color: $blue_darker !important;
    padding: 1.5% 6%;
  }
}
/*
section#navbar li a[href="/de/stadtraumfrankfurt"] {
  position: relative;
  text-indent: -99999px;

  &:after {
    position: absolute;
    display: block;
    content: "";
    background-image: url("/profiles/vbf_community/themes/custom/vbf_community_theme/images/StadtRAUMLogo.png");
    background-color: transparent;
    background-size: contain;
    width: 117px;
    height: 85px;
    z-index: 2;
    bottom:auto;
  }
}
*/
#block-vbf-community-vbf-landing-page-links .link-line2 {
  font-weight: normal;
}
#block-vbf-community-vbf-landing-page-links .nav li a.communities-link.startraum {
  position: relative;
  background-image: none;

  &:before {
    transition: all 0.5s ease;
    content: "";
    background: url("../images/vbf_AmkA_Picto_stadtRAUMfrankfurt_white.svg") no-repeat center center;
    background-color: #296192;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    display: block;
    margin-right: rem(20);
    flex: 0 0 auto;
    @media (min-width: $screen-sm) {
      height: 100px;
      width: 100px;
    }
  }
  &:hover {
    color: $blue;
    &:before {
      background-color: $blue;
    }
  }
}
section#navbar li a {
  text-transform: none;
  font-weight: 700;
  font-size: rem(15);
}
section#navbar {
  @media screen and (max-width: 768px) {
    height: 64px;
    .container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.og_menu_trigger {
  color: $gray-dark;
  padding: rem(10) 0;
  transition: all 0.2s ease-out;
  font-size: 1.2rem;
  float: left;
  width: 100%;
  margin-bottom: 20px;

  span {
    top: 4px;
  }

  &:hover,
  &:focus {
    background: none;
    text-decoration: none;
    text-shadow: 0 0 0 rgba($black, 0.5);
  }

  @media (min-width: $screen-sm) {
    display: none;
  }
}

#block-vbf-og-menu-vbf-og-menu-single {
  &.open {
    .og_menu_trigger {
      span {
        transform: rotate(180deg);
      }
    }
  }
}
