// Theme Customizations for Region/Layout changes //

// Main
.main-container,
#page-header {
  position: relative;
  //@include vbf-margin-leader(3);

  @media only screen and (min-width: $screen-md-min) {
    @include vbf-margin-leader(1);
    @include vbf-padding-leader(2);
    @include vbf-padding-trailer(2);
  }
}

#page-header {
  margin-top: -6rem;
  padding-top: 24px;
  @media only screen and (min-width: $screen-md-min) {
    margin-top: -1.2rem;
    padding-top: 63px;
  }
}
.main-container {
  padding-top: 0;
}

.region-1-mobile-bottom-wrapper {
  clear: left;
}

.page-welcome {
  @media (max-width: $screen-xs-min - 1) {
    .main-container,
    #page-header {
      margin-top: -2rem;
      margin-bottom: 3rem;
    }

    #block-vbf-og-vbf-og-user-memberships {
      margin-top: 3rem;
    }
  }
}

body.node-type-community {
  #page-header {
    @media (max-width: $screen-sm) {
      margin-bottom: 25px;
    }
  }
}

// Regions within Main
.content-top {
  @include clearfix();
  position: relative;
  border-bottom: 1px solid $gray-medium;
}
.region-wrapper {
  &.region-content-wrapper {
    &.with-region-sidebar-first-mobile-bottom {
      float: right;
      @media (max-width: $screen-sm) {
        float: none;
      }

      &.with-region-sidebar-second {
        margin-right: 25%;
        @media (max-width: $screen-sm) {
          margin-right: 0;
        }
      }
    }
  }
  &.region-2-wrapper {
    &.with-region-sidebar-first-mobile-bottom {
      position: static;

      .region-sidebar-second {
        position: absolute;
        right: 0;
        top: 0;

        @media (max-width: $screen-sm) {
          position: static;
        }
      }
    }
  }
}

.region {
  &.region-content {
    position: relative;
  }
  &.region-sidebar-first,
  &.region-sidebar-first-mobile-bottom,
  &.region-sidebar-second {
    > section {
      @include vbf-margin-trailer(1.5);
      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 0;
      }
    }
    h2,
    h3 {
      font-size: rem(20);
      border-bottom: 1px solid $gray-lighter;
      padding: $grid-gutter-width/2 0;
    }
  }
}

.region-main-bottom {
  clear: both;
  @include make-xs-column(12);
  @include padding-gutter;
  @media (min-width: $screen-md) {
    @include make-md-column-offset(3);
    @include make-md-column(9);
  }
}

.page-403,
.page-404 {
  .main-container {
    font-size: rem(24);
    @include vbf-margin-trailer(4);
  }
}

// stream type layout
.page-node-members,
.page-node-projektraum,
.page-node-events,
.page-node-polls,
.page-node-posts,
.page-node-stream {
  .region-content {
    @include make-row;
  }
  #block-blockify-blockify-page-title {
    @include make-md-column(8);
  }
  #block-blockify-blockify-actions {
    @include make-md-column(4);
    @include pull-right;
    @media (max-width: $screen-md) {
      float: none !important;
    }
  }
  #block-system-main {
    @include make-md-column(12);
  }
  #block-blockify-blockify-page-title #page-title {
    color: $gray-dark;
    font-size: rem(30);
    line-height: rem(65);
    margin-bottom: rem(25);

    @media (min-width: $screen-md) {
    }
  }
}

.page-group-node-unsubscribe {
  #block-blockify-blockify-page-title #page-title {
    font-size: rem(20);
    line-height: rem(35);
    margin-bottom: rem(25);

    @media (min-width: $screen-md) {
      font-size: rem(30);
      line-height: rem(65);
    }
  }
}

.page-group-node-subscribe-og-user-node {
  #block-blockify-blockify-page-title #page-title {
    font-size: rem(20);
    line-height: rem(35);
    margin-bottom: rem(25);

    @media (min-width: $screen-md) {
      font-size: rem(30);
      line-height: 1.3;
    }
  }
}
