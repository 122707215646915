.field .field-label {
  @include make-bold;
  line-height: 1.4705;
}

.field-body a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  vertical-align: top;
}

.field-title-prefix {
  font-size: rem(20);
  .front & {
    font-size: rem(30px);
  }
}

.entity-profile2 .content {
  display: flex;
  .field-profile-image {
    margin-right: $grid-gutter-width /2;
    flex: 0 0 62px;
  }
  .field-first-name {
    text-transform: uppercase;
    max-width: 60%;
    line-height: 1.25;
  }
  .field-contact & {
    align-items: center;
    @include vbf-margin-trailer(1);
  }
}

// download link
.file-field-download-link {
  @include glyphicon-standalone($glyphicon-download-alt);
  display: flex;
  @include vbf-margin-trailer(.5);
  justify-content: space-between;
  align-items: baseline;
  line-height: 1.2;
  .file-size {
    flex: 1 0 auto;
    margin-left: 1em;
  }
  .file-name {
    flex: 0 1 auto;
    word-break: break-word;
  }
  &:before {
    order: 2;
    margin-left: 1em;
  }
}

.field-first-paragraph {
  font-size: rem(20);
  @include vbf-margin-trailer(1);
}