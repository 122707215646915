//
// Teasers: all
// --------------------------------------------------
// Hide the formatted Date in teasers
.node-teaser {
  header {
    // position: relative;
  }
  .submission-info {
    .time-ago {
      display: none;
    }
    &:hover {
      .date-format {
        display: none;
      }
      .time-ago {
        display: inline-block;
      }
    }
  }
}
//
// Teasers: regular teaser
// --------------------------------------------------
.node-teaser:not(.node-community):not(.node-hero-content) {
  @include make-row;
  @include vbf-margin-trailer(2);

  section.node-main {
    @include make-md-column(8);
    .content {
      background: $gray-lighter;
      padding: $grid-gutter-width / 2;
    }
    h2 {
     @include vbf-margin-trailer(1);
    }
  }
  .slick-slider {
    background: $gray;
    img {
      margin: auto;
    }
  }
  .submission-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $gray-light;
  }

  .field-body {
    p {
      font-size: 13px;
    }
  }

  footer {
    @include make-md-column(4);
    position: relative;
    .left-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      top: $grid-gutter-width;
      left: -17px;
      border-width: 16px 16px;
      border-right-color:$gray-lighter;
    }
    > .content {
      background: $gray-lighter;
      padding: $grid-gutter-width / 2;
    }
    .additional-links {
      border-top: 1px solid $gray-light;
      ul {
        margin: 0;
        li {
          padding: 0;
        }
      }

      &.has_modal {
        position: relative;
      }
    }

  }
}

//
// Node Type Community Teaser
// --------------------------------------------------
.node-community.node-teaser {
  background: $gray-lighter;
  min-height: 100%;
  h2 {
    font-size: rem(20);
    font-weight: $normal;
    text-align: center;
    a {
      color: $blue_dark;
      display: block;
      transition: all 0.2s ease;
      &:hover {
        text-decoration: none;
        text-shadow: 0px 1px 1px  rgba($blue_dark, 0.25);
        &:after {
          width: 70%;
        }
      }
      &:after {
        content: "";
        background: $blue_dark;
        transition: width 0.2s ease;
        width: 60%;
        height: 1px;
        margin: rem(30) auto;
        display: block;
      }
    }
  }
  .field-sc-header-image {
    @include vbf-margin-trailer(0.5);
  }
  .node-main {
    padding: 15px;
  }
}
