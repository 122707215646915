.view-vbf-wegweiser {
  .view-filters {
    display: none; //rendered in seperate block
  }
  .text-center {
    width: 100%;
    clear: both;
  }
}
// style radios as buttons
.vbf-radio-as-button {
  .form-item {
    display: flex;
  }
  input[type='radio'] {
    line-height: normal;
    height: auto;
    margin: 0;
    padding: 0;
    border: 0;
  }
  input[type='radio']{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border-collapse: collapse;
    display: none;
  }
  /* graceful degradation for ie8 */
  input[type='radio']  {
    width:auto;
    float:left;
    margin-right: .75em;
  }

  input[type='radio']:checked,
  input[type='radio']:focus,
  input[type='radio']:not(:checked)   {
    background: transparent;
    position: relative;
    margin:0;
    padding:0;
    @include hide-visually;
  }

  input[type='radio'] + label{
    position: relative;
    cursor: pointer;
  }
  input[type='radio']:not(:checked) + label {
    background: $white;
    color: $gray;
  }
  input[type='radio']:checked + label,
  input[type='radio']:hover + label, {
   color: $white;
   background: $blue-dark;
  }
  .radio + .radio, .checkbox + .checkbox {
    margin: 0;
  }
}

.vbf-radio-as-button {
  .form-type-radio {
    display: flex;
    flex-flow: column;
    label {
      width: 100%;
      @include vbf-margin-trailer(0.75);
      text-align: center;
      border: 2px solid $blue-dark;
      padding: 0.5rem;
    }
  }
}

#block-vbf-community-vbf-events-filters {
   .views-exposed-widget {
    width: 100%;
    padding: 0;
  }
  .chosen-container-single .chosen-single {
    border: 2px solid $blue-dark;
    box-sizing: content-box;
  }
  .form-type-select {
    @include vbf-margin-trailer(0.75);
  }
  .views-exposed-form .panel-heading {
    color: $gray-light;
    border: 0;
    @include vbf-margin-trailer(1.25);
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      margin-bottom: 10px;
    }
  }

  .form-type-datepicker {
    margin-bottom: 0 !important;
  }

  .reset-button {
    color: $blue-dark;
    background: transparent;
    &:hover {
      text-decoration: underline;
    }
  }

  #edit-date-filter-value-wrapper {
    // border-top: 1px solid $gray-light;
    // padding-top: 18px;
  }

  #edit-actions {
    display: flex;
    justify-content: space-between;
  }
  .form-item .date-group {
    width: 100%;
    @include vbf-padding-trailer(1);
  }
  .ui-datepicker {
    width: 100%;
    border-color: $blue-dark;
    border-width: 2px;
    border-radius: 0;
    td {
      padding: 0;
      a {
        padding: 0;
      }
    }
  }
  .ui-datepicker-header{
    background: $white;
    border: hidden;
    .ui-corner-all {
      border-radius: 0;
      .ui-icon {
        background: none;
        display: none;
      }
    }
    .ui-datepicker-prev {
      left: 0;
      @include glyphicon-after($glyphicon-triangle-left);
    }
    .ui-datepicker-next{
      right: 0;
      @include glyphicon-after($glyphicon-triangle-right);
    }
    .ui-datepicker-prev,
    .ui-datepicker-next {
      display: flex;
      width: 2rem;
      height: 100%;
      align-items: center;
      &:after {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        background: $blue-dark;
        color: $white;
        border: none;
        text-decoration: none;
      }
    }
  }
  .ui-datepicker-title {
    font-weight: $bold;
    font-size: rem(15);
    display: flex;
    justify-content: space-between;
    select {
      @include selectStyle;
      width: 49%;
    }
  }
  a.ui-state-default {
    background: $white ;
    border: 0;
    text-align: center;
    &.ui-state-active,
    &:hover {
      background: $blue-dark;
      color: $white;
    }
  }
  .input-group-addon.glyphicon{
    visibility: hidden;
  }
}