//
// Basics // access
// --------------------------------------------------
.node-access-settings {
  line-height: 1;
  position: relative;
  &.access-1 {
    @include glyphicon-standalone($glyphicon-globe, $gray-light, rem($font-size-large*1.2));
  }
  &.access-2 {
    @include glyphicon-standalone($glyphicon-lock, $gray-light, rem($font-size-large*1.2));
    @include opacity(.4);
  }
  &:before {
    line-height: rem(25);
    cursor: default;
  }
}
.node-teaser {
  .node-access-settings {
    &:before {
      color: $gray-light;
    }
  }
}