.field-name-field-additional-eventdate {
  .panel-title {
    display: none;
  }

  .field-label {
    label {
      font-weight: 600;
      font-size: 1.2rem;
      display: block;
      margin-bottom: 10px;
    }
  }

  .date-float {
    margin-bottom: 15px;
  }
}
