


  .event-number{

  	display: flex;
  	flex: flex;
   @include vbf-margin-trailer(5);
  	 li {
      display: flex;
      flex-flow: column;
      align-content: center;
      justify-content: center;
      background: $blue_dark;
      text-align: center;
      align-items: center;
      color: $white;
      font-size: rem(20);
      border-radius: 100%;
      line-height: 1;
      width: 262px;
      height: 262px;
      margin: 0 auto;
      @include vbf-margin-trailer(1);
      &:before {
        margin-bottom: 1rem;
        width: 40%;
      }
    
  }
}

  @media (max-device-width: 667px) {
    .event-number {
      display: block !important;
      }
    }
